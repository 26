import {

    GET_NOTIFICATION_TYPES_REQUEST,
    GET_NOTIFICATION_TYPES_SUCCESS,
    GET_NOTIFICATION_TYPES_FAILURE,

    GET_USER_NOTIFICATION_SETTINGS_REQUEST,
    GET_USER_NOTIFICATION_SETTINGS_SUCCESS,
    GET_USER_NOTIFICATION_SETTINGS_FAILURE,

    SAVE_USER_NOTIFICATION_SETTINGS_REQUEST, 
    SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS, 
    SAVE_USER_NOTIFICATION_SETTINGS_FAILURE,

    GET_USER_NOTIFICATIONS_REQUEST,
    GET_USER_NOTIFICATIONS_SUCCESS,
    GET_USER_NOTIFICATIONS_FAILURE,

    DELETE_USER_NOTIFICATION_REQUEST,
    DELETE_USER_NOTIFICATION_SUCCESS, 
    DELETE_USER_NOTIFICATION_FAILURE,

   GET_ORDER_TRANSACTION_COMMENTS_REQUEST,
   GET_ORDER_TRANSACTION_COMMENTS_SUCCESS,
   GET_ORDER_TRANSACTION_COMMENTS_FAILURE

} from '../actions/account/notificationSettings';

export default function account(state = {
    isFetching: false,
    isSaving: false,
    notificationTypes: null,
    userNotificationSettings: null,
    actionResult: null,
    userNotifications: null,
    orderTransaction: null
}, { type, payload }) {
    switch (type) {
        //NotificationType    
        case GET_NOTIFICATION_TYPES_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                notificationTypes: null,
                actionResult: { type: GET_NOTIFICATION_TYPES_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_NOTIFICATION_TYPES_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_NOTIFICATION_TYPES_REQUEST, result: GET_NOTIFICATION_TYPES_SUCCESS, token: payload.actionToken } ,
                notificationTypes: payload.notificationTypes
            });
        case GET_NOTIFICATION_TYPES_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_NOTIFICATION_TYPES_REQUEST, result: GET_NOTIFICATION_TYPES_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });    
        //UserNotificationSettings
        case GET_USER_NOTIFICATION_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                userNotificationSettings: null,
                actionResult: { type: GET_USER_NOTIFICATION_SETTINGS_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_USER_NOTIFICATION_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,                
                actionResult: { type: GET_USER_NOTIFICATION_SETTINGS_REQUEST, result: GET_USER_NOTIFICATION_SETTINGS_SUCCESS, token: payload.actionToken } ,
                userNotificationSettings: payload.userNotificationSettings
            });
        case GET_USER_NOTIFICATION_SETTINGS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_USER_NOTIFICATION_SETTINGS_REQUEST, result: GET_USER_NOTIFICATION_SETTINGS_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case SAVE_USER_NOTIFICATION_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_USER_NOTIFICATION_SETTINGS_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS:
           return Object.assign({}, state, {
            isSaving: false,
                actionResult: { type: SAVE_USER_NOTIFICATION_SETTINGS_REQUEST, result: SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS, token: payload.actionToken },
                userNotificationSettings: payload.userNotificationSettings
            });
        case SAVE_USER_NOTIFICATION_SETTINGS_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_USER_NOTIFICATION_SETTINGS_REQUEST, result: SAVE_USER_NOTIFICATION_SETTINGS_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        //UserNotification    
        case GET_USER_NOTIFICATIONS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                userNotifications: null,
                actionResult: { type: GET_USER_NOTIFICATIONS_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_USER_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,                
                actionResult: { type: GET_USER_NOTIFICATIONS_REQUEST, result: GET_USER_NOTIFICATIONS_SUCCESS, token: payload.actionToken } ,
                userNotifications: payload.userNotifications
            });
        case GET_USER_NOTIFICATIONS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_USER_NOTIFICATIONS_REQUEST, result: GET_USER_NOTIFICATIONS_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case DELETE_USER_NOTIFICATION_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: DELETE_USER_NOTIFICATION_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_USER_NOTIFICATION_SUCCESS:
            if (true) {
                let _userNotifications = [...state.userNotifications];
                let index = -1;

                if (_userNotifications.length > 0) {

                    for (let x = 0; x < _userNotifications.length; x++) {
                    
                        if (_userNotifications[x].msbId === payload.userNotification.msbId) {
                            index = x;

                            break;
                        }
                    }
                }

                _userNotifications[index] = payload.userNotification;

           return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: DELETE_USER_NOTIFICATION_REQUEST, result: DELETE_USER_NOTIFICATION_SUCCESS, token: payload.actionToken },
                userNotifications: _userNotifications                
            });
        }
        case DELETE_USER_NOTIFICATION_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: DELETE_USER_NOTIFICATION_REQUEST, result: DELETE_USER_NOTIFICATION_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });           
        default:
            return state;
    }
}
