import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { paymentsFormSchema } from './components/AdvancedSearchColumns';
import Report, { AlignRightFormatter, getContentUrl } from './components/Report'
import { DateFormatter, CurrencyFormatter, ConvenienceFeeFormatter } from '../../components/Formatters';
import { Redirect } from 'react-router-dom';
import { Routes } from "../../routes";
import { transactionDetailAction } from '../../redux/actions/payments/paymentTransactions';
import { IPaymentReportProps } from './Chargebacks';
import { OrderStatus, TransactionTypeEnum } from "../../models/Payment";
import { ReportType } from "../../models/Reports";
import { setReportColumns } from "../../redux/actions/reports/report";
import { IAppState } from "../../redux/storeTypes";

const PaymentsReport = ({ reportColumns }: IPaymentReportProps) => {
    
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const actionToken = "ReportDetails";
    const reportTitle = 'Payments Report';
    const reportType = ReportType.Payment;
    const reportRoute = Routes.PaymentsReport.path;
    const transactionType = TransactionTypeEnum.Sale;

    const viewDetailsFormatter = (cell:any,row:any) => {
        const breadCrumbDetails = { reportTitle,reportRoute }
        return(
            <span><a onClick={() =>{ dispatch(transactionDetailAction(row.msbId, actionToken, breadCrumbDetails))}}>View Details</a></span>
        )
    }

    useEffect(() => {
        if (reportColumns && !reportColumns.has(reportType)) {
            const _columns = [
                {
                    dataField: 'msbId',
                    text: 'ID',
                    editable: false,
                    sort: false,
                    hidden: true,
                    configurable: false
                }, {
                    dataField: 'clientId',
                    text: 'Client',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'departmentId',
                    text: 'Department',
                    editable: false,
                    default: true,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'paymentChannelId',
                    text: 'Payment Channel',
                    editable: false,
                    sort: true,
                    default: false,
                    configurable: true
                }, {
                    dataField: 'initiatedBy',
                    text: 'Initiated by',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'orderIdentifier',
                    text: 'Transaction ID',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'transactionType',
                    text: 'Transaction type',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'lastFourOnCard',
                    text: 'Last four on card',
                    editable: false,
                    sort: true,
                    default: false,
                    configurable: true
                }, {
                    dataField: 'createdAt',
                    text: 'Transaction date',
                    editable: false,
                    sort: true,
                    default: true,
                    formatter: DateFormatter,
                    configurable: true
                }, {
                    dataField: 'orderLines[0].itemReferenceNumber',
                    text: 'Item reference',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'orderLines[0].itemName',
                    text: 'Item name',
                    editable: false,
                    sort: true,
                    default: true,
                    configurable: true
                }, {
                    dataField: 'amount',
                    text: 'Subtotal',
                    editable: false,
                    sort: true,
                    formatter: CurrencyFormatter,
                    configurable: true
                }, {
                    dataField: 'convenienceFee',
                    text: 'Convenience fee',
                    editable: false,
                    sort: true,
                    formatter: ConvenienceFeeFormatter,
                    configurable: true
                }, {
                    dataField: 'totalAmount',
                    text: 'Total amount',
                    editable: false,
                    sort: true,
                    default: true,
                    formatter: CurrencyFormatter,
                    configurable: true
                }, {
                    dataField: 'originalAmount',
                    text: 'Original amount',
                    editable: false,
                    sort: true,
                    formatter: CurrencyFormatter,
                    configurable: true
                }, {
                    dataField: 'remainingBalance',
                    text: 'Remaining balance',
                    editable: false,
                    sort: true,
                    formatter: CurrencyFormatter,
                    configurable: true
                }, {
                    dataField: 'nameOnCard',
                    text: 'Customer name',
                    editable: false,
                    default: true,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'orderPayment.cardLogo',
                    text: 'Card type',
                    editable: false,
                    sort: true,
                    default: false,
                    configurable: true
                }, {
                    dataField: 'phone',
                    text: 'Customer phone',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'email',
                    text: 'Customer email',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'addressLine1',
                    text: 'Address line 1',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'addressLine2',
                    text: 'Address line 2',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'city',
                    text: 'City',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'state',
                    text: 'State',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: 'zip',
                    text: 'Zip code',
                    editable: false,
                    sort: true,
                    configurable: true
                }, {
                    dataField: '',
                    text: 'Actions',
                    editable: false,
                    formatter: viewDetailsFormatter,
                    configurable: false
                }
            ];

            dispatch(setReportColumns(reportType, _columns));
        }
    }, []);
    
    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <Report
                    reportTitle={reportTitle}
                    reportType={reportType} 
                    breadCrumbRoute={reportRoute}
                    transactionType={transactionType}
                    formSchema={paymentsFormSchema}
                    documentContentUrl={getContentUrl(OrderStatus.Success, transactionType)}
                    searchSummary={true}  
                />
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        reportColumns: state.reports.reportColumns
    };
};

export default connect(mapStateToProps)(PaymentsReport);
