import React,{ useEffect, useState } from "react";
import { hardwareFormSchema } from './components/AdvancedSearchColumns';
import Report from './components/Report'
import { Redirect } from 'react-router-dom';
import { Routes } from "../../routes";
import { useDispatch } from "react-redux";
import PageSectionContainer from "../../components/layout/PageSectionContainer";
import PageHeader from "../../components/layout/PageHeader";
import AdvancedSearch from "./components/AdvancedSearch";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { Crumb } from "../../models/Crumb";
import Table from "../../components/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { ContactSupportOutlined } from "@material-ui/icons";
import { ReportType } from "../../models/Reports";

const HardwareReport = () => {
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState<string>("");
    const reportTitle = 'Hardware Status Report';
    const reportType = ReportType.Hardware;
    const transactionType = 0;

    useEffect(() => {
  }, []);


  const viewDetailsFormatter = (cell:any,row:any) => {
      return(
          <span><a onClick={() => console.log("View Details Hardware Status")}>View Details</a></span>
      )
  }

  const data = [
    { "transactionId": "CH188888", "hardware": "Terminal", "paymentChannel": "New London Tax WEB", "department": "New Londong Tax","status": "Offline" },
    { "transactionId": "BH230585", "hardware": "Printer", "paymentChannel": "Ken Duncan Payment", "department": "Ken Duncan Payment Processing","status": "Error" },
    { "transactionId": "DC531675", "hardware": "Terminal", "paymentChannel": "Ken Duncan Payment", "department": "Ken Duncan Payment Processing","status": "Online" },
    { "transactionId": "FH230585", "hardware": "Terminal", "paymentChannel": "Nashville Public", "department": "Nashville Public Library","status": "Offline" },
    { "transactionId": "AX230585", "hardware": "Printer", "paymentChannel": "Fulton County", "department": "Fulton County Treasurer","status": "Error" },
    { "transactionId": "CB531675", "hardware": "Terminal", "paymentChannel": "Nashville Metro ", "department": "Nashville Metro Library","status": "Online" },
    { "transactionId": "AS230585", "hardware": "Terminal", "paymentChannel": "Edison Tax", "department": "Edison Tax","status": "Offline" },
    { "transactionId": "CD531675", "hardware": "Terminal", "paymentChannel": "Combined Locks Trea", "department": "Combined Locks Treasurer","status": "Warning" },
    { "transactionId": "CB531675", "hardware": "Printer", "paymentChannel": "Chesterfield County", "department": "Chesterfield County ELM","status": "Online" },
    { "transactionId": "VH230585", "hardware": "Terminal", "paymentChannel": "Munidex Glen Rock", "department": "Munidex Glen Rock Tax","status": "Online" },
    { "transactionId": "GC531675", "hardware": "Printer", "paymentChannel": "Munidex Glen Rock", "department": "Munidex Glen Rock Tax","status": "Offline" },

]

  const statusFormatter = (cell: any, row: any) => {

    if (cell === 'Online') {
      return (
        <div>
          <svg height="10" width="10">
            <circle cx="5" cy="5" r="4" stroke="" strokeWidth="1" fill="#52C41A"></circle>
          </svg> Online
        </div>
      )
    }
    else if (cell === 'Error') {
      return (
        <div>
          <svg height="10" width="10">
            <circle cx="5" cy="5" r="4" stroke="" strokeWidth="1" fill="#F5222D"></circle>
          </svg> Error
        </div>
      )
    }
    else if(cell === 'Warning'){
      return (
        <div>
          <svg height="10" width="10">
            <circle cx="5" cy="5" r="4" stroke="" strokeWidth="1" fill="#FA8C16"></circle>
          </svg> Warning
        </div>
      )
    }
    else{
      return (
        <div>
          <svg height="10" width="10">
            <circle cx="5" cy="5" r="4" stroke="" strokeWidth="1" fill="#D9D9D9"></circle>
          </svg> Offline
        </div>
      )
    }
  }

  const columns = [
    {
      dataField: 'msbId',
      text: 'Id',
      editable: false,
      sort: false,
      hidden: true,
      configurable: false
  },
        {
          dataField: 'department',
          text: 'Department',
          editable: false,
          hidden: false,
          configurable: true
      }, {
        dataField: 'paymentChannel',
        text: 'Transaction date',
        editable: false,
        configurable: true
      }, {
          dataField: 'transactionId',
          text: 'Transaction ID',
          editable: false,
          configurable: true
      }, {
          dataField: 'hardware',
          text: 'Hardware',
          editable: false,
          configurable: true
      }, {
          dataField: 'status',
          text: 'Status',
          editable: false,
          formatter: statusFormatter,
          configurable: true
      }, {
          dataField: '',
          text: 'Actions',
          editable: false,
          formatter: viewDetailsFormatter,
          configurable: false
      }
  ];

  const doToolbar = () => {
    if (data && data.length > 0) {
        return (
            <div className="flexContainer">
                <div>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary">
                            <FontAwesomeIcon icon={faCog} size="sm" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item >Configure report table columns</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div>
                    <Dropdown>
                        <Dropdown.Toggle className="approvalAction" variant="outline-secondary">
                            <FontAwesomeIcon icon={faArrowToBottom} size="sm" />
                            Download Summary
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                                <Dropdown.Item> PDF File </Dropdown.Item>
                                <Dropdown.Item> CSV File </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        )
    } else {
        return (<></>);
    }
}




  if (redirect != "") {
    return (<Redirect push to={redirect} />)
  } else {
    return (
        <>
          {/*
          <PageSectionContainer title={`Hardware status report under construction.`} ></PageSectionContainer>
          */}
          {/*
           <Report
              reportTitle={reportTitle}
              reportType={reportType} 
              transactionType={transactionType}
           columns={columns} formSchema={hardwareFormSchema}/>
          */}


        <PageHeader title={reportTitle} crumbs={new Array<Crumb>()}/>
            <Container fluid className="container-table-search">
                <Row>
                    <Col>
                        <PageSectionContainer>
                            <AdvancedSearch formSchema={columns} transactionType={transactionType} reportType={reportType} isFetching={false} searchReady={false} />
                        </PageSectionContainer>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col>
                        <PageSectionContainer title={`Manage ${reportTitle}`} >
                        <Table
                            keyField="msbId"
                            data={data}
                            columns={columns}

                            //rowStyle={rowStyle}
                            //defaultSorted = { defaultSorted }
                          />
                        </PageSectionContainer>
                    </Col>
                </Row>
            </Container>


        </>
    );
  }
};

export default HardwareReport;

