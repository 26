import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Container, Col, Row, Form, Button, Dropdown, ButtonToolbar, Modal } from 'react-bootstrap';
import { IAppState, IActionResult } from '../../../redux/storeTypes';
import PageHeader from '../../../components/layout/PageHeader';
import PageSectionContainer from '../../../components/layout/PageSectionContainer';
import { DateFormatter } from '../../../components/Formatters';
import { getUsersAction, cancelAction, getUserAction, deleteUserAction } from '../../../redux/actions/users';
import { Routes } from "../../../routes";
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { sendSuccessToastAction, sendErrorToastAction } from '../../../redux/actions/toast';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { User, UserTypeEnum } from "../../../models/User";
import { Client } from '../../../models/Client';
import {
    DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE
} from '../../../redux/actions/users';
import FormHeaderConfirmation from '../../../components/FormHeaderConfirmation';
import icoWarning from "../../../assets/img/icons/ico-warning-outline.svg";
import { Crumb } from "../../../models/Crumb";

interface IClientUsers {
    users: Array<User>,
    client: Client,
    isFetching: boolean,
    user: User,
    actionResult: IActionResult;
}

const ClientUsers = ({ user, users, isFetching, client, actionResult }: IClientUsers) => {

    let actionToken = "ClientUsers";
    const [redirect, setRedirect] = useState<string>("");
    const [deleteUser, setDeleteUser] = useState();
    const [showModal, setModal] = useState(false);
    const dispatch = useDispatch();
    let data: Array<any> = [];
    let page: number = 1;
    let totalRecords: number = 0;

    if (users != null) {
        data = users;
        totalRecords = users.length;
    }

    useEffect(() => {
        dispatch(getUsersAction(UserTypeEnum.Client, actionToken,client.msbId));
    }, []);

    useEffect(() => {

        if (actionResult && actionResult.result && actionResult.type === DELETE_USER_REQUEST) {
            if (actionResult.result === DELETE_USER_SUCCESS) {
                dispatch(sendSuccessToastAction("User was successfully deleted."));
            } else if (actionResult.result === DELETE_USER_FAILURE) {
                dispatch(sendErrorToastAction("User could not be deleted."));
            }
        }

    }, [actionResult]);


    const actionsOperations = (row: any, operation: any) => {
        if (operation === "Delete") {
            dispatch(deleteUserAction(row, "deleteUser"));
        }
        else if (operation === "Edit") {
            dispatch(getUserAction(row.msbId, actionToken));
            setRedirect(Routes.EditClientUser.path);
        }
    }

    const dropDownOptions = (cell: any, row: any) => {
        return (
            <Dropdown className="req-action-dropdown">
                <Dropdown.Toggle className="approvalAction" variant="link">
                    Manage
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onSelect={() => actionsOperations(row, "Edit")}>Edit User</Dropdown.Item>
                    <Dropdown.Item onSelect={() => actionsOperations(row, "Delete")}>Delete User</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

        )
    }

    const columns = [{
        dataField: 'firstName',
        text: 'First name',
        editable: false,
        sort: true
    }, {
        dataField: 'lastName',
        text: 'Last name',
        editable: false,
        sort: true
    }, {
        dataField: 'email',
        text: 'Email address',
        editable: false
    }, {
        dataField: 'updatedAt',
        text: 'Last updated',
        formatter: DateFormatter,
        editable: false
    }, {
        dataField: 'id',
        text: 'Actions',
        editable: false,
        formatter: dropDownOptions,
    }
    ];

    const addUserAction = () => {
        return (
            <ButtonToolbar>
                <Button onClick={() => { dispatch(cancelAction()); setRedirect(Routes.AddClientUser.path) }}>
                    <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add User
                </Button>
            </ButtonToolbar>
        )
    }


    const SearchFilter = (props: any) => {
        let input: any;
        const handleSubmit = (event: any) => {
            event.preventDefault();
            props.onSearch(input.value);
        };
        const handleReset = () => {
            props.onSearch(input.value = '');
        }
        return (
            <Form onSubmit={handleSubmit} className="table-search">
                <Row>
                    <Col xl={9} lg={9} md={12} sm={12}>
                        <Form.Group>
                            <Form.Label>User</Form.Label>
                            <Form.Control ref={(filterValue: any) => input = filterValue} type="text" placeholder="Enter user's name" />
                        </Form.Group>
                    </Col>
                    <Col xl={3} lg={3} md={12} sm={12}>
                        <ButtonToolbar>
                            <Button variant="outline-secondary" onClick={handleReset}>Reset</Button>
                            <Button onClick={handleSubmit}>Search</Button>
                        </ButtonToolbar>
                    </Col>
                </Row>
            </Form>
        );
    };

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Client Users", Routes.ClientUsers.path));

        return (
            <>
                <PageHeader title="Client Users" crumbs={crumbs} />

                {/* <Modal show={showModal} className="modal-confirmation">
                    <Modal.Body>
                        <FormHeaderConfirmation iconImg={icoWarning} title="Delete user" />
                        <div className="confirmation-body">
                            <p>Are you sure you want to delete this user?</p>
                            <Form.Group className="confirmation-footer">
                                <Button variant="outline-secondary" onClick={() => setModal(false)}>
                                    Cancel
                                </Button>
                                <Button onClick={(e) => deleteUserAction(e)}>
                                    Confirm
                                </Button>
                            </Form.Group>
                        </div>
                    </Modal.Body>
                </Modal> */}

                <ToolkitProvider keyField="id" data={data} columns={columns} search>
                    {
                        (props: any) => (
                            <>
                                <Container fluid className="container-table-search">
                                    <Row>
                                        <Col>
                                            <PageSectionContainer>
                                                <SearchFilter {...props.searchProps} />
                                            </PageSectionContainer>
                                        </Col>
                                    </Row>
                                </Container>

                                <Container fluid>
                                    <Row>
                                        <Col>
                                            <PageSectionContainer title="Manage Users" toolbar={addUserAction()}>
                                                <BootstrapTable
                                                    //wrapperClasses="table-responsive"
                                                    pagination={paginationFactory({
                                                        page: page,
                                                        sizePerPage: 10,
                                                        totalSize: totalRecords,
                                                        alwaysShowAllBtns: true,
                                                        withFirstAndLast: true,
                                                        hideSizePerPage: false,
                                                        firstPageText: '\u27E8\u27E8', // First
                                                        prePageText: '\u27E8', // Previous
                                                        nextPageText: '\u27E9', // Next
                                                        lastPageText: '\u27E9\u27E9', // Last
                                                        nextPageTitle: 'Next page',
                                                        prePageTitle: 'Previous page',
                                                        firstPageTitle: 'First page',
                                                        lastPageTitle: 'Last page',
                                                        showTotal: true,
                                                        // hidePageListOnlyOnePage: true,
                                                    })}                                          
                                                    {...props.baseProps}
                                                />
                                            </PageSectionContainer>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                        )
                    }
                </ToolkitProvider>

            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        user: state.users.user,
        users: state.users.users,
        client: state.clients.client,
        currentPage: state.users.currentPage,
        isFetching: state.users.isFetching,
        actionResult: state.users.actionResult
    };
};

export default connect(mapStateToProps)(ClientUsers);