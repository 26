import React, { useState } from "react";
import Switch from "react-switch";
import { Col, Row, Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setReportColumns } from "../../../redux/actions/reports/report";
import { ReportType } from "../../../models/Reports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { User } from "../../../models/User";
import { saveCurrentUserPreferencesAction } from "../../../redux/actions/auth";

interface ITableConfiguration {
    originalColumns: Array<any>,
    columns: Array<any>,
    reportType: ReportType,
    closeModal: any,
    currentUser: User,
    isSaving: boolean
}

const TableConfiguration = ({ originalColumns, columns, reportType, closeModal, currentUser, isSaving }: ITableConfiguration) => {
    const actionToken = "Report";
    const [switchColumns, setSwitchColumns] = useState<Array<any>>([]);
    const dispatch = useDispatch();
    const [configurableCount, setConfigurableCount] = useState<number>(0);

    useEffect(() => {

        const missingOriginalColumns = originalColumns.filter(originalDataFields => !columns.find(userPreferences => originalDataFields.dataField === userPreferences.dataField ));
        const displayedColumns = missingOriginalColumns.length === 0 ? columns : [...columns, ...missingOriginalColumns]
        columns = displayedColumns.sort((a, b) => {
            if (a.configurable && !b.configurable) {
                return -1;
            }

            if (!a.configurable && b.configurable) {
                return 1;
            }

            return (a.default === b.default) ? 0 : a.default ? -1 : 1;
        });
        setConfigurableCount(columns.filter(c => c.configurable === true).length);
        setSwitchColumns(columns);
    }, [columns]);

   
    const handleActiveOnChange = (event: any, column: any) => {
        column.default = event;
        setSwitchColumns(Object.assign([], switchColumns));
        updateCurrentUserColumns(switchColumns);
        dispatch(setReportColumns(reportType, switchColumns));
    }

    const handleDownOnClick = (event: any, column: any) => {
        var initialIndex = switchColumns.indexOf(column);
        var finalIndex = initialIndex + 1;

        switchColumns.splice(finalIndex, 0, switchColumns.splice(initialIndex, 1)[0]);
        setSwitchColumns(Object.assign([], switchColumns));
        updateCurrentUserColumns(switchColumns);
        dispatch(setReportColumns(reportType, switchColumns));
    }

    const handleUpOnClick = (event: any, column: any) => {
        var initialIndex = switchColumns.indexOf(column);
        var finalIndex = initialIndex - 1;

        switchColumns.splice(finalIndex, 0, switchColumns.splice(initialIndex, 1)[0]);
        setSwitchColumns(Object.assign([], switchColumns));
        updateCurrentUserColumns(switchColumns);
        dispatch(setReportColumns(reportType, switchColumns));
    }

    const updateCurrentUserColumns = (columns: Array<any>) => {
        if (!currentUser.preferences) {
            currentUser.preferences = {};
        }

        if (!currentUser.preferences.reports) {
            currentUser.preferences.reports = {};
        }

        currentUser.preferences.reports[reportType] = columns;
    }

    const handleSaveOnClick = () => {
        const { userRoles, ...currentUserRest } = currentUser;

        var _columns = JSON.parse(JSON.stringify(currentUserRest.preferences.reports[reportType]));
        _columns.forEach(function (c: any) {
            c.formatExtraData = null;
            c.formatter = null;
        });
        currentUserRest.preferences.reports[reportType] = _columns;

        dispatch(saveCurrentUserPreferencesAction(currentUserRest, actionToken));
    }

    const handleResetOnClick = () => {
        var _originalColumns = JSON.parse(JSON.stringify(originalColumns));
        //var cloneOrginalColumns = Array<any>();
        //_originalColumns.forEach(c => cloneOrginalColumns.push(Object.assign({}, c)))

        setSwitchColumns(_originalColumns);
        updateCurrentUserColumns(_originalColumns);
        dispatch(setReportColumns(reportType, _originalColumns));
    }

    const renderSpinner = (isSaving: boolean) => {
        if (isSaving) {
            return (<Spinner animation="border" style={{ marginLeft: "10px" }} />);
        } else {
            return (<></>);
        }
    }

    return (
        <>
            <Form className="table-configuration mt-0">
                <Row style={{ marginBottom: '24px' }}>
                    <h2 style={{ marginBottom: '4px' }}>Configure report table columns</h2>
                    <p>Select the options below to customize the view of your report table columns.</p>
                </Row>
                {switchColumns.map((column, index) => {
                    if (column.configurable) {
                        return (
                            <Row style={{ marginBottom: "5px", alignItems: "center" }}>
                                <Col>
                                    <div className="toggle-switch" key={index}>
                                        <Switch
                                            onChange={(event) => handleActiveOnChange(event, column)}
                                            checked={column.default}
                                            onColor={'#0057B6'}
                                            offColor={'#BEBEBE'}
                                            handleDiameter={12}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={16}
                                            width={28}
                                            activeBoxShadow={'0 0 0 1px #0057B6'}
                                        />
                                        <span className="toggle-label">{column.text}</span>
                                    </div>
                                </Col>
                                <Col style={{ display: "inline-flex", justifyContent: "flex-end" }}>
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id={"tooltip-top"}>
                                                Move column left
                                            </Tooltip>
                                        }>
                                        <Button size="sm" variant="outline-secondary" disabled={index !== 0 ? false : true} onClick={(event) => handleUpOnClick(event, column)}>
                                            <FontAwesomeIcon icon={faArrowUp} size="sm" />
                                        </Button>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id={"tooltip-top"}>
                                                Move column right
                                            </Tooltip>
                                        }>
                                        <Button size="sm" style={{ marginLeft: "5px" }} variant="outline-secondary" disabled={index < configurableCount - 1 ? false : true} onClick={(event) => handleDownOnClick(event, column)}>
                                            <FontAwesomeIcon icon={faArrowDown} size="sm" />
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        )
                    }
                })
                }

                <div className="manage-footer">
                    <Form.Group className="form-footer justify-content-between">
                        <Button type="button" onClick={handleSaveOnClick} disabled={isSaving}>
                            Save
                        </Button>
                        {/** 
						<Button type="button" onClick={handleResetOnClick} disabled={isSaving}>
							Reset
						</Button>
						**/}
                        <Button variant="link" onClick={closeModal}>
                            Cancel
                        </Button>
                    </Form.Group>
                </div>
            </Form>
        </>
    );
};

export default TableConfiguration;