import axios from 'axios';
import { NotificationType, UserNotification, UserNotificationSetting, Notification} from '../../../models/AccountSettings';
import { Dispatch } from 'react';
import store from "../../store";

export const GET_NOTIFICATION_TYPES_REQUEST = 'GET_NOTIFICATION_TYPES_REQUEST';
export const GET_NOTIFICATION_TYPES_SUCCESS = "GET_NOTIFICATION_TYPES_SUCCESS";
export const GET_NOTIFICATION_TYPES_FAILURE = 'GET_NOTIFICATION_TYPES_FAILURE';

export const GET_USER_NOTIFICATION_SETTINGS_REQUEST = 'GET_USER_NOTIFICATION_SETTINGS_REQUEST';
export const GET_USER_NOTIFICATION_SETTINGS_SUCCESS = "GET_USER_NOTIFICATION_SETTINGS_SUCCESS";
export const GET_USER_NOTIFICATION_SETTINGS_FAILURE = 'GET_USER_NOTIFICATION_SETTINGS_FAILURE';

export const SAVE_USER_NOTIFICATION_SETTINGS_REQUEST = 'SAVE_USER_NOTIFICATION_SETTINGS_REQUEST';
export const SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS = "SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS";
export const SAVE_USER_NOTIFICATION_SETTINGS_FAILURE = 'SAVE_USER_NOTIFICATION_SETTINGS_FAILURE';

export const DELETE_NOTIFICATION_SETTINGS_REQUEST = 'DELETE_NOTIFICATION_SETTINGS_REQUEST';
export const DELETE_NOTIFICATION_SETTINGS_SUCCESS = 'DELETE_NOTIFICATION_SETTINGS_SUCCESS'; 
export const DELETE_NOTIFICATION_SETTINGS_FAILURE = 'DELETE_NOTIFICATION_SETTINGS_FAILURE';

export const GET_USER_NOTIFICATIONS_REQUEST = 'GET_USER_NOTIFICATIONS_REQUEST';
export const GET_USER_NOTIFICATIONS_SUCCESS = "GET_USER_NOTIFICATIONS_SUCCESS";
export const GET_USER_NOTIFICATIONS_FAILURE = 'GET_USER_NOTIFICATIONS_FAILURE';

export const SAVE_USER_NOTIFICATION_REQUEST = 'SAVE_USER_NOTIFICATION_REQUEST';
export const SAVE_USER_NOTIFICATION_SUCCESS = "SAVE_USER_NOTIFICATION_SUCCESS";
export const SAVE_USER_NOTIFICATION_FAILURE = 'SAVE_USER_NOTIFICATION_FAILURE';

export const DELETE_USER_NOTIFICATION_REQUEST = 'DELETE_USER_NOTIFICATION_REQUEST';
export const DELETE_USER_NOTIFICATION_SUCCESS = 'DELETE_USER_NOTIFICATION_SUCCESS'; 
export const DELETE_USER_NOTIFICATION_FAILURE = 'DELETE_USER_NOTIFICATION_FAILURE';

export const CREATE_NOTIFICATION_REQUEST = "CREATE_NOTIFICATION_REQUEST";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const CREATE_NOTIFICATION_FAILURE = "CREATE_NOTIFICATION_FAILURE";

export const getNotificationTypesAction = (actionToken:string, msbId?: string) => (dispatch:Dispatch<any>) => {    
    dispatch({
        type: GET_NOTIFICATION_TYPES_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url =  "/MSB_Notification/api/v1/NotificationType";

    axios.get(_url)
        .then(function (response) {
            let notificationTypes  = response.data;
            dispatch({
                type: GET_NOTIFICATION_TYPES_SUCCESS,
                payload: {
                    notificationTypes,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_NOTIFICATION_TYPES_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const getUserNotificationSettingsAction = (actionToken:string, msbId?: string) => (dispatch:Dispatch<any>) => {    
    dispatch({
        type: GET_USER_NOTIFICATION_SETTINGS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Notification/api/v1/UserNotificationSettings/" + msbId + "/1";

    axios.get(_url)
        .then(function (response) {
            let userNotificationSettings  = response.data;
                dispatch({
                type: GET_USER_NOTIFICATION_SETTINGS_SUCCESS,
                payload: {
                    userNotificationSettings,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_USER_NOTIFICATION_SETTINGS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const saveUserNotificationSettingsAction = (userNotificationSettings : UserNotificationSetting[] | unknown, actionToken: string, isUpdate: boolean) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_USER_NOTIFICATION_SETTINGS_REQUEST,
        payload: { actionToken }
    });

    let _url = "/MSB_Notification/api/v1/UserNotificationSettings"

    if (!isUpdate) {
        axios.post(_url, userNotificationSettings)
            .then(function (response) {
                userNotificationSettings = response.data;
                dispatch({
                    type: SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS,
                    payload: {
                        userNotificationSettings,
                        actionToken
                    }
                });
            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_USER_NOTIFICATION_SETTINGS_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });

            });
    } else {
        axios.put(_url, userNotificationSettings)
            .then(function (response) {
                userNotificationSettings = response.data;
                dispatch({
                    type: SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS,
                    payload: {
                        userNotificationSettings,
                        actionToken
                    }
                });
            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_USER_NOTIFICATION_SETTINGS_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            });
    }
};

export const getUserNotificationsAction = (actionToken:string, userMSBId: string) => (dispatch:Dispatch<any>) => {    
    dispatch({
        type: GET_USER_NOTIFICATIONS_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Notification/api/v1/UserNotification/" + userMSBId;
    
    axios.get(_url)
        .then(function (response) {
            let userNotifications  = response.data;
                dispatch({
                type: GET_USER_NOTIFICATIONS_SUCCESS,
                payload: {
                    userNotifications,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_USER_NOTIFICATIONS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const archiveUserNotificationAction = (actionToken:string, userNotification : UserNotification) => (dispatch:Dispatch<any>) => {    
    dispatch({
        type: DELETE_USER_NOTIFICATION_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_Notification/api/v1/UserNotification";

    userNotification.isDeleted = true;
    axios.put(_url, userNotification)
        .then(function (response) {
            let userNotification  = response.data;
                dispatch({
                type: DELETE_USER_NOTIFICATION_SUCCESS,
                payload: {
                    userNotification,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_USER_NOTIFICATION_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const createNotificationAction = (actionToken:string, createNotification : Notification) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CREATE_NOTIFICATION_REQUEST,
        payload: { actionToken }
    });
    
    let _url = "/MSB_Notification/api/v1/Notification";

    axios.post(_url, createNotification)
        .then(function (response) {
            let notification = response.data;
            dispatch({
                type: CREATE_NOTIFICATION_SUCCESS,
                payload: {
                    notification,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: CREATE_NOTIFICATION_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });

        });
};
