import * as React from 'react';
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Table, Button, Form, Spinner, Modal, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faExclamationTriangle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { getNotificationTypesAction, getUserNotificationSettingsAction, saveUserNotificationSettingsAction } from '../../../redux/actions/account/notificationSettings';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../redux/actions/toast';
import FormHeader from '../../clients/components/forms/FormHeader';
import Switch from "react-switch";
import FormHeaderConfirmation from '../../../components/FormHeaderConfirmation';
import icoWarning from "../../../assets/img/icons/ico-warning-outline.svg";
import { User } from '../../../models/User';
import { IActionResult, IAppState } from '../../../redux/storeTypes';
import '../settings.css';
import { NotificationType, UserNotificationSetting, PriorityTypeEnum } from '../../../models/AccountSettings';
import {
    SAVE_USER_NOTIFICATION_SETTINGS_REQUEST, SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS, SAVE_USER_NOTIFICATION_SETTINGS_FAILURE,
    DELETE_NOTIFICATION_SETTINGS_REQUEST, DELETE_NOTIFICATION_SETTINGS_SUCCESS, DELETE_NOTIFICATION_SETTINGS_FAILURE
} from '../../../redux/actions/account/notificationSettings';

export interface IAccountNotificationSettings{
    isFetching: boolean,
    isSaving: boolean,
    currentUser: User,
    actionResult: IActionResult,
    notificationTypes: Array<NotificationType>,
    userNotificationSettings: Array<UserNotificationSetting>,
}

const AccountNotificationSettings = ({ isFetching, isSaving, currentUser, actionResult, notificationTypes, userNotificationSettings }: IAccountNotificationSettings) => {
    const [useNotificationTypes, setUseNotificationTypes] = useState([new NotificationType()]);
    const [useUserNotificationSettings, setUseUserNotificationSettings] = useState([new UserNotificationSetting()]);
    const [usernotificationSettingsOne, setusernotificationSettingsOne] = useState<Array<UserNotificationSetting>>([]);
    
    const dispatch = useDispatch();
    const [isFormProcessing, setIsFormProcessing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    let actionToken = "NotificationSettings";

    const [notificationDeliveryEmail_1, setNotificationDeliveryEmail_1] = useState(false);
    const [notificationDeliveryEmail_2, setNotificationDeliveryEmail_2] = useState(false);
    const [notificationDeliveryEmail_3, setNotificationDeliveryEmail_3] = useState(false);
    const [notificationDeliveryEmail_4, setNotificationDeliveryEmail_4] = useState(false);
    const [notificationDeliveryEmail_5, setNotificationDeliveryEmail_5] = useState(false);
    const [notificationDeliveryEmail_6, setNotificationDeliveryEmail_6] = useState(false);
    const [notificationDeliveryEmail_7, setNotificationDeliveryEmail_7] = useState(false);
    const [notificationDeliveryEmail_8, setNotificationDeliveryEmail_8] = useState(false);
    const [notificationDeliveryEmail_9, setNotificationDeliveryEmail_9] = useState(false);
    const [notificationDeliveryEmail_10, setNotificationDeliveryEmail_10] = useState(false);
    const [notificationDeliveryEmail_11, setNotificationDeliveryEmail_11] = useState(false);
    const [notificationDeliveryEmail_12, setNotificationDeliveryEmail_12] = useState(false);
    const [notificationDeliveryEmail_13, setNotificationDeliveryEmail_13] = useState(false);
    const [notificationDeliveryEmail_14, setNotificationDeliveryEmail_14] = useState(false);

    const [notificationDeliveryNexus_1, setNotificationDeliveryNexus_1] = useState(true);
    const [notificationDeliveryNexus_2, setNotificationDeliveryNexus_2] = useState(true);
    const [notificationDeliveryNexus_3, setNotificationDeliveryNexus_3] = useState(true);
    const [notificationDeliveryNexus_4, setNotificationDeliveryNexus_4] = useState(true);
    const [notificationDeliveryNexus_5, setNotificationDeliveryNexus_5] = useState(true);
    const [notificationDeliveryNexus_6, setNotificationDeliveryNexus_6] = useState(true);
    const [notificationDeliveryNexus_7, setNotificationDeliveryNexus_7] = useState(true);
    const [notificationDeliveryNexus_8, setNotificationDeliveryNexus_8] = useState(true);
    const [notificationDeliveryNexus_9, setNotificationDeliveryNexus_9] = useState(true);
    const [notificationDeliveryNexus_10, setNotificationDeliveryNexus_10] = useState(true);
    const [notificationDeliveryNexus_11, setNotificationDeliveryNexus_11] = useState(false);
    const [notificationDeliveryNexus_12, setNotificationDeliveryNexus_12] = useState(false);
    const [notificationDeliveryNexus_13, setNotificationDeliveryNexus_13] = useState(false);
    const [notificationDeliveryNexus_14, setNotificationDeliveryNexus_14] = useState(false);

    const [notificationPriority_1, setNotificationPriority_1] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_2, setNotificationPriority_2] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_3, setNotificationPriority_3] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_4, setNotificationPriority_4] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_5, setNotificationPriority_5] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_6, setNotificationPriority_6] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_7, setNotificationPriority_7] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_8, setNotificationPriority_8] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_9, setNotificationPriority_9] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_10, setNotificationPriority_10] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_11, setNotificationPriority_11] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_12, setNotificationPriority_12] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_13, setNotificationPriority_13] = useState(PriorityTypeEnum.Notice);
    const [notificationPriority_14, setNotificationPriority_14] = useState(PriorityTypeEnum.Notice);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === SAVE_USER_NOTIFICATION_SETTINGS_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS) {
                    dispatch(sendSuccessToastAction("Notification settings have been successfully updated."));
                } else if (actionResult.result === SAVE_USER_NOTIFICATION_SETTINGS_FAILURE) {
                    dispatch(sendErrorToastAction("Notification settings could not be updated."));
                }
            }
 
         if (actionResult.type === DELETE_NOTIFICATION_SETTINGS_REQUEST && actionResult.token === actionToken) {
             if (actionResult.result === DELETE_NOTIFICATION_SETTINGS_SUCCESS) {
                 dispatch(sendSuccessToastAction("Notification settings have been successfully deleted."));
             } else if (actionResult.result === DELETE_NOTIFICATION_SETTINGS_FAILURE) {
                 dispatch(sendErrorToastAction("Notification settings could not be deleted."));
             }
         }
        }
     }, [actionResult]); 

    useEffect(() => {
        if(notificationTypes === null){
            dispatch(getNotificationTypesAction(actionToken));        
            setUseNotificationTypes(notificationTypes);
        }
    }, []);
    
    useEffect(() => {
        if (userNotificationSettings === null) {
            dispatch(getUserNotificationSettingsAction(actionToken, currentUser.msbId));
        }
    }, []);

    useEffect(() => {
        if (userNotificationSettings != null) {
            if(userNotificationSettings.length > 0) {   
                setIsUpdate(true);
                setUseNotificationTypes(notificationTypes);
                setusernotificationSettingsOne([...userNotificationSettings]);
    
                for (let x=0;x<userNotificationSettings.length;x++) {
                    if(userNotificationSettings[x].notificationTypeEnum === 1)
                    {
                        setNotificationDeliveryEmail_1(userNotificationSettings[x].email);
                        setNotificationDeliveryNexus_1(userNotificationSettings[x].nexus);
                        setNotificationPriority_1(userNotificationSettings[x].priorityTypeEnum);

                    } else if(userNotificationSettings[x].notificationTypeEnum === 2)
                    {
                        setNotificationDeliveryEmail_2(userNotificationSettings[x].email);
                        setNotificationDeliveryNexus_2(userNotificationSettings[x].nexus);
                        setNotificationPriority_2(userNotificationSettings[x].priorityTypeEnum);

                    }else if(userNotificationSettings[x].notificationTypeEnum === 3)
                    {
                        setNotificationDeliveryEmail_3(userNotificationSettings[x].email);
                        setNotificationDeliveryNexus_3(userNotificationSettings[x].nexus);
                        setNotificationPriority_3(userNotificationSettings[x].priorityTypeEnum);

                    }else if(userNotificationSettings[x].notificationTypeEnum === 4)
                    {
                        setNotificationDeliveryEmail_4(userNotificationSettings[x].email);
                        //setNotificationDeliveryNexus_4(userNotificationSettings[x].nexus);
                        setNotificationDeliveryNexus_4(true);
                        setNotificationPriority_4(userNotificationSettings[x].priorityTypeEnum);

                    }else if(userNotificationSettings[x].notificationTypeEnum === 5)
                    {
                        setNotificationDeliveryEmail_5(userNotificationSettings[x].email);
                        //setNotificationDeliveryNexus_5(userNotificationSettings[x].nexus);
                        setNotificationDeliveryNexus_5(true);
                        setNotificationPriority_5(userNotificationSettings[x].priorityTypeEnum);

                    }else if(userNotificationSettings[x].notificationTypeEnum === 6)
                    {
                        setNotificationDeliveryEmail_6(userNotificationSettings[x].email);
                        setNotificationDeliveryNexus_6(userNotificationSettings[x].nexus);                       
                        setNotificationPriority_6(userNotificationSettings[x].priorityTypeEnum);

                    }else if(userNotificationSettings[x].notificationTypeEnum === 7)
                    {
                        setNotificationDeliveryEmail_7(userNotificationSettings[x].email);
                        setNotificationDeliveryNexus_7(userNotificationSettings[x].nexus);
                        setNotificationPriority_7(userNotificationSettings[x].priorityTypeEnum);

                    }else if(userNotificationSettings[x].notificationTypeEnum === 8)
                    {
                        setNotificationDeliveryEmail_8(userNotificationSettings[x].email);
                        setNotificationDeliveryNexus_8(userNotificationSettings[x].nexus);
                        setNotificationPriority_8(userNotificationSettings[x].priorityTypeEnum);

                    }else if(userNotificationSettings[x].notificationTypeEnum === 9)
                    {
                        setNotificationDeliveryEmail_9(userNotificationSettings[x].email);
                        setNotificationDeliveryNexus_9(userNotificationSettings[x].nexus);
                        setNotificationPriority_9(userNotificationSettings[x].priorityTypeEnum);

                    }else if(userNotificationSettings[x].notificationTypeEnum === 10)
                    {
                        setNotificationDeliveryEmail_10(userNotificationSettings[x].email);
                        setNotificationDeliveryNexus_10(userNotificationSettings[x].nexus);
                        setNotificationPriority_10(userNotificationSettings[x].priorityTypeEnum);
                    }else if(userNotificationSettings[x].notificationTypeEnum === 11)
                    {
                        setNotificationDeliveryEmail_11(userNotificationSettings[x].email);
                        setNotificationDeliveryNexus_11(userNotificationSettings[x].nexus);
                        setNotificationPriority_11(userNotificationSettings[x].priorityTypeEnum);
                    }else if(userNotificationSettings[x].notificationTypeEnum === 12)
                    {
                        setNotificationDeliveryEmail_12(userNotificationSettings[x].email);
                        setNotificationDeliveryNexus_12(userNotificationSettings[x].nexus);                        
                        setNotificationPriority_12(userNotificationSettings[x].priorityTypeEnum);
                    }else if(userNotificationSettings[x].notificationTypeEnum === 13)
                    {
                       setNotificationDeliveryEmail_13(userNotificationSettings[x].email);
                       setNotificationDeliveryNexus_13(userNotificationSettings[x].nexus);                        
                       setNotificationPriority_13(userNotificationSettings[x].priorityTypeEnum);
                    }else if(userNotificationSettings[x].notificationTypeEnum === 14)
                    {
                        setNotificationDeliveryEmail_14(userNotificationSettings[x].email);
                        setNotificationDeliveryNexus_14(userNotificationSettings[x].nexus);
                        setNotificationPriority_14(userNotificationSettings[x].priorityTypeEnum);
                    }
                }
            }
            else
            {
                //No user Settings exists - Create new user notification setings - new case!
                //Create new collection
                if( notificationTypes != null && notificationTypes.length > 0)
                {
                    for(let x=0; x<notificationTypes.length;x++)
                    {
                        let _usernotificationSettingTMP = new UserNotificationSetting();
                        _usernotificationSettingTMP.userMSBId = currentUser.msbId!;
                        _usernotificationSettingTMP.notificationTypeEnum = notificationTypes[x].notificationTypeEnum
                        _usernotificationSettingTMP.priorityTypeEnum= PriorityTypeEnum.Notice;
                        _usernotificationSettingTMP.email= false;
                        _usernotificationSettingTMP.nexus= true;
                        usernotificationSettingsOne.push(_usernotificationSettingTMP);
                    }

                    if(usernotificationSettingsOne.length > 0)
                    {
                        setusernotificationSettingsOne([...usernotificationSettingsOne]); 
                    }
                }
            }
        }    
    },[]);

    //Save User-Notification-Settings
    const handleSubmit = (event: any) => {
        event.preventDefault();

        setIsFormProcessing(true);
        setTimeout(function () {
            setIsFormProcessing(false);
        }, 1000);
        
        const form = event.currentTarget;
        
        if (form.checkValidity() !== false) {
            
            for(let x=0; x<usernotificationSettingsOne.length;x++){
                if(usernotificationSettingsOne[x].notificationTypeEnum === 1 )
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_1;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_1;
                }
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 2 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_2;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_2;
                } 
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 3 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_3;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_3;
                } 
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 4 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_4;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_4;
                } 
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 5 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_5;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_5;
                } 
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 6 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_6;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_6;
                } 
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 7 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_7;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_7;
                } 
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 8 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_8;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_8;
                } 
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 9 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_9;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_9;
                } 
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 10 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_10;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_10;
                }
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 11 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_11;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_11;
                } 
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 12 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_12;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_12;
                } 
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 13 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_13;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_13;
                } 
                else if(usernotificationSettingsOne[x].notificationTypeEnum === 14 )  
                {
                    usernotificationSettingsOne[x].email=notificationDeliveryEmail_14;
                    usernotificationSettingsOne[x].nexus=notificationDeliveryNexus_14;
                }  

                usernotificationSettingsOne[x].emailAddress = currentUser.email;
                // console.log("usernotificationSettingsOne.emailAddress: " + usernotificationSettingsOne[x].notificationTypeEnum);
                // console.log(usernotificationSettingsOne[x].emailAddress);
            }
            
            //Save-Update
            dispatch(saveUserNotificationSettingsAction(usernotificationSettingsOne, actionToken, isUpdate));
            if(usernotificationSettingsOne != null)
            {
                if(usernotificationSettingsOne.length > 0)
                {    
                    setIsUpdate(true);
                    setUseUserNotificationSettings(usernotificationSettingsOne);
                }
            }
        }
        setValidated(true);
    };

    const handleCancelConfirm = (event: any) => {
        event.preventDefault();
        setShowModal(false);
    };

    const handleDeliveryEmailOnChange = (value:boolean, notificationTypeSelected: number) => {
        if(notificationTypeSelected === 1) 
        setNotificationDeliveryEmail_1(value);

        else if(notificationTypeSelected === 2) 
        setNotificationDeliveryEmail_2(value);
        
        else if(notificationTypeSelected === 3) 
        setNotificationDeliveryEmail_3(value);
        
        else if(notificationTypeSelected === 4) 
        setNotificationDeliveryEmail_4(value);
        
        else if(notificationTypeSelected === 5) 
        setNotificationDeliveryEmail_5(value);
        
        else if(notificationTypeSelected === 6) 
        setNotificationDeliveryEmail_6(value);
        
        else if(notificationTypeSelected === 7) 
        setNotificationDeliveryEmail_7(value);
        
        else if(notificationTypeSelected === 8) 
        setNotificationDeliveryEmail_8(value);
        
        else if(notificationTypeSelected === 9) 
        setNotificationDeliveryEmail_9(value);
        
        else if(notificationTypeSelected === 10) 
        setNotificationDeliveryEmail_10(value);

        else if(notificationTypeSelected === 11) 
        setNotificationDeliveryEmail_11(value);

        else if(notificationTypeSelected === 12) 
        setNotificationDeliveryEmail_12(value);

        else if(notificationTypeSelected === 13) 
        setNotificationDeliveryEmail_13(value);

        else if(notificationTypeSelected === 14) 
        setNotificationDeliveryEmail_14(value);
    }

    const handleDeliveryNexusOnChange = (value:boolean, notificationTypeSelected: number) => {
        if(notificationTypeSelected === 1) 
        setNotificationDeliveryNexus_1(value);

        else if(notificationTypeSelected === 2) 
        setNotificationDeliveryNexus_2(value);

        else if(notificationTypeSelected === 3) 
        setNotificationDeliveryNexus_3(value);

        else if(notificationTypeSelected === 4) 
        setNotificationDeliveryNexus_4(true);

        else if(notificationTypeSelected === 5) 
        setNotificationDeliveryNexus_5(true);

        else if(notificationTypeSelected === 6) 
        setNotificationDeliveryNexus_6(value);

        else if(notificationTypeSelected === 7) 
        setNotificationDeliveryNexus_7(value);

        else if(notificationTypeSelected === 8) 
        setNotificationDeliveryNexus_8(value);

        else if(notificationTypeSelected === 9) 
        setNotificationDeliveryNexus_9(value);

        else if(notificationTypeSelected === 10) 
        setNotificationDeliveryNexus_10(value);

        else if(notificationTypeSelected === 11) 
        setNotificationDeliveryNexus_11(value);

        else if(notificationTypeSelected === 12) 
        setNotificationDeliveryNexus_12(value);

        else if(notificationTypeSelected === 13) 
        setNotificationDeliveryNexus_13(value);

        else if(notificationTypeSelected === 14) 
        setNotificationDeliveryNexus_14(value);
    }

    const handlePriorityOnChange = (e : any, selectedNotificationTypeEnum: any, priority: number) => {
        let _usernotificationSettingTMP = new UserNotificationSetting();
            _usernotificationSettingTMP.userMSBId = currentUser.msbId!;
            _usernotificationSettingTMP.notificationTypeEnum = selectedNotificationTypeEnum;
            _usernotificationSettingTMP.priorityTypeEnum= priority;
                
        if(selectedNotificationTypeEnum === 1)
            setNotificationPriority_1(priority);
         else if(selectedNotificationTypeEnum === 2)
            setNotificationPriority_2(priority);
        else if(selectedNotificationTypeEnum === 3)
            setNotificationPriority_3(priority);
        else if(selectedNotificationTypeEnum === 4)
            setNotificationPriority_4(priority);
        else if(selectedNotificationTypeEnum === 5)
            setNotificationPriority_5(priority);
        else if(selectedNotificationTypeEnum === 6)
            setNotificationPriority_6(priority);
        else if(selectedNotificationTypeEnum === 7)
            setNotificationPriority_7(priority);
        else if(selectedNotificationTypeEnum === 8)
            setNotificationPriority_8(priority);
        else if(selectedNotificationTypeEnum === 9)
            setNotificationPriority_9(priority);
        else if(selectedNotificationTypeEnum === 10)
            setNotificationPriority_10(priority);
        else if(selectedNotificationTypeEnum === 11)
            setNotificationPriority_11(priority);
        else if(selectedNotificationTypeEnum === 12)
            setNotificationPriority_12(priority);
        else if(selectedNotificationTypeEnum === 13)
            setNotificationPriority_13(priority);
        else if(selectedNotificationTypeEnum === 14)
            setNotificationPriority_14(priority);

        let findNotificationType = usernotificationSettingsOne.find(item => item.notificationTypeEnum === selectedNotificationTypeEnum);
        if(findNotificationType !== undefined)
        {
            findNotificationType.priorityTypeEnum = priority;
            findNotificationType.email = notificationDeliveryEmail_1;
            findNotificationType.nexus = notificationDeliveryNexus_1;
        }
        
        setusernotificationSettingsOne([...usernotificationSettingsOne]);        
    }

     //navigation-settings
    const navigationSettings = (userNotificationTypes: NotificationType[]) => (
      <Table responsive borderless>
          <thead>
              <tr className="notification-header">
                  <th><h3>Notification type</h3></th>
                  <th colSpan={3}><h3>Priority</h3></th>
                  <th colSpan={2}><h3>Delivery</h3></th>
              </tr>
          </thead>
          <tbody>
              <tr className="notification-priority-delivery">
                  <td></td>
                  <td>
                      <FontAwesomeIcon icon={faExclamationCircle} className="ico-critical" />
                      <strong>Critical</strong>
                  </td>
                  <td>
                      <FontAwesomeIcon icon={faExclamationTriangle} className="ico-urgent" />
                      <strong>Urgent</strong>
                  </td>
                  <td>
                      <FontAwesomeIcon icon={faCheckCircle} className="ico-notice" />
                      <strong>Notice</strong>
                  </td>
                  <td><strong>Email</strong></td>
                  <td><strong>Nexus</strong></td>
              </tr>
                  {
                  userNotificationTypes?.map((_row, Idx) => (
                  <tr key={Idx} className="notification-type-setting">
                          <td>
                              <h3 className="mb-0" id={userNotificationTypes[Idx]?.name}>{userNotificationTypes[Idx]?.name}</h3>
                              {userNotificationTypes[Idx]?.description?
                                  <p id={userNotificationTypes[Idx]?.description}>{userNotificationTypes[Idx]?.description}</p>
                                  :
                                  <></>
                              }
                          </td>
                          <td>
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 1 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_1 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 2 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_2 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 3 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_3 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 4 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_4 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 5 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_5 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 6 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_6 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 7 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_7 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 8 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_8 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 9 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_9 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 10 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_10 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 11 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_11 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 12 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_12 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 13 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_13 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 14 &&
                              <div>
                                  <Form.Check>
                                      <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 1)}
                                          defaultChecked={notificationPriority_14 === 1 }
                                      />                                       
                                  </Form.Check>
                              </div>
                              }
                          </td>
                          <td>    
                          
                          
                          {/*..2..*/}                                  
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 1 &&
                          <div>
                              <Form.Check>
                                  <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_1 === 2}
                                  />
                              </Form.Check>
                              </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 2 &&
                          <div>
                              <Form.Check>
                                  <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_2 === 2}
                                  />
                              </Form.Check>
                              </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 3 &&
                          <div>
                              <Form.Check>
                                  <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_3 === 2}
                                  />
                              </Form.Check>
                              </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 4 &&
                          <div>
                              <Form.Check>
                                  <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_4 === 2}
                                  />
                              </Form.Check>
                              </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 5 &&
                          <div>
                              <Form.Check>
                                  <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_5 === 2}
                                  />
                              </Form.Check>
                              </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 6 &&
                          <div>
                              <Form.Check>
                                  <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_6 === 2}
                                  />
                              </Form.Check>
                              </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 7 &&
                          <div>
                              <Form.Check>
                                  <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_7 === 2}
                                  />
                              </Form.Check>
                              </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 8 &&
                          <div>
                              <Form.Check>
                                  <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_8 === 2}
                                  />
                              </Form.Check>
                              </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 9 &&
                          <div>
                              <Form.Check>
                                  <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_9 === 2}
                                  />
                              </Form.Check>
                              </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 10 &&
                          <div>
                              <Form.Check>
                                  <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_10 === 2}
                                  />
                              </Form.Check>
                              </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 11 &&
                          <div>
                              <Form.Check>
                                  <input type="radio" 
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_11 === 2}
                                  />
                              </Form.Check>
                              </div>
                          } 
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 12 &&
                          <div>
                              <Form.Check>
                                  <input type="radio" 
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_12 === 2}
                                  />
                              </Form.Check>
                              </div>
                          } 
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 13 &&
                          <div>
                              <Form.Check>
                                  <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_13 === 2}
                                  />
                              </Form.Check>
                              </div>
                          } 
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 14 &&
                          <div>
                              <Form.Check>
                                  <input type="radio"
                                          disabled={isSaving}
                                          name={userNotificationTypes[Idx]?.name} 
                                          onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 2)}
                                          defaultChecked={notificationPriority_14 === 2}
                                  />
                              </Form.Check>
                              </div>
                          }    
                          </td>
                          <td>
                          
                          
                          
                          {/*..3..*/} 
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 1 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_1 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 2 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_2 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 3 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_3 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 4 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_4 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 5 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_5 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 6 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_6 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 7 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_7 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 8 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_8 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 9 &&
                          <div>
                              <Form.Check>
                              <input type="radio" 
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_9 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 10 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_10 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 11 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_11 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 12 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_12 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 13 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_13 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {userNotificationTypes[Idx]?.notificationTypeEnum === 14 &&
                          <div>
                              <Form.Check>
                              <input type="radio"
                                  disabled={isSaving}
                                  name={userNotificationTypes[Idx]?.name} 
                                  onChange={(e) => handlePriorityOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum, 3)}
                                  defaultChecked={notificationPriority_14 == 3}
                                  />
                              </Form.Check>
                          </div>
                          }
                          {/* End */}
                          

                          </td>
                          <td>
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 1 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_1} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 2 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_2} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 3 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_3} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 4 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_4} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 5 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_5} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 6 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_6} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 7 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_7} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 8 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_8} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 9 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_9} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 10 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_10} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 11 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_11} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 12 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_12} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 13 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_13} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 14 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name}
                                      onChange={(e) => handleDeliveryEmailOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked= {notificationDeliveryEmail_14} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                          </td>
                          <td>
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 1 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_1} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 2 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_2} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 3 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_3} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 4 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch                                            
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_4} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      disabled
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 5 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch                                            
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_5} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      disabled
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 6 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_6} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 7 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_7} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 8 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_8} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 9 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_9} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 10 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_10} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 11 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_11} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 12 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_12} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 13 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_13} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                              {userNotificationTypes[Idx]?.notificationTypeEnum === 14 &&
                              <div className="toggle-switch" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}>
                                  <Switch
                                      disabled={isSaving}
                                      name={userNotificationTypes[Idx]?.name} 
                                      onChange={(e) => handleDeliveryNexusOnChange(e, userNotificationTypes[Idx]?.notificationTypeEnum)}
                                      checked={notificationDeliveryNexus_14} 
                                      onColor={'#0057B6'} 
                                      offColor={'#BEBEBE'}
                                      handleDiameter={12}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      height={16}
                                      width={28}
                                      activeBoxShadow={'0 0 0 1px #0057B6'}
                                  />
                              </div>
                              }
                          </td>
                      </tr>

                      )
                  )
              }             
          </tbody>
      </Table>
    );

    if (isFetching)
        return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        );
    else
        return (
            <>
                <Modal show={showModal} className="modal-confirmation">
                    <Modal.Body>
                        <FormHeaderConfirmation iconImg={icoWarning} title="Unsaved changes" />
                        <div className="confirmation-body">
                            <p>You have unsaved changes to Notification settings. Are you sure you would like to cancel?</p>
                            <Form.Group className="confirmation-footer">
                                <Button onClick={() => setShowModal(false)} variant="outline-secondary">
                                    Cancel
                                </Button>
                                <Button onClick={(e) => handleCancelConfirm(e)}>
                                    Confirm
                                </Button>
                            </Form.Group>
                        </div>
                    </Modal.Body>
                </Modal>

                <FormHeader title="Notification settings" description="Below are the notification settings for your account. You can update your details at any time." />
                <Form id="userNotificationSettings" onSubmit={handleSubmit} className="form-notifications">
                    {userNotificationSettings != null && navigationSettings(notificationTypes)}
                    <div className="manage-footer">
                            <Form.Group controlId="saveNotificationAction" className="form-footer">
                                <Button form="userNotificationSettings" type="submit" disabled={isSaving} >Save</Button>
                                {isFormProcessing && <Spinner animation="border" style={{ marginLeft: "10px" }} />}

                                <Button form="userNotificationSettings" onClick={() => setShowModal(true)} variant="link">Cancel</Button>
                            </Form.Group>
                    </div>                   
                </Form >
                
            </>
        );
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.accountSettings.isFetching,
        isSaving: state.accountSettings.isSaving,
        currentUser: state.auth.currentUser,
        actionResult: state.accountSettings.actionResult,
        notificationTypes: state.accountSettings.notificationTypes,
        userNotificationSettings: state.accountSettings.userNotificationSettings,
    };
};

export default connect(mapStateToProps)(AccountNotificationSettings);
