import React from "react";
import { connect, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUserCircle } from "@fortawesome/pro-light-svg-icons";
import { Nav, Image, Navbar, Dropdown, Container } from 'react-bootstrap';
import { doLogOut } from '../redux/actions/auth'
import { Routes } from "../routes";
import nexusLogo from "../assets/img/nexus-logo-stacked.svg";
import { Link } from "react-router-dom";
import { User, UserTypeEnum } from '../models/User';
import { IAppState } from "../redux/storeTypes";
import { Client } from "../models/Client";
import  Avatar  from '../assets/img/Avatar.png'

interface IHeaderProps {
  currentUser: User,
  client?: Client
}
const Header = ({currentUser, client}: IHeaderProps) => {
    const dispatch = useDispatch();
    const onLogOut = () => dispatch(doLogOut());

    const imageSrc = (base64:string) => {
      if (base64) {
          if (base64.startsWith('data')) {
              return base64;
          } else {
              return `data:image/jpeg;base64, ${base64}`;
          }
      }
    };

  const renderAvatarImage = () => {
    if (currentUser.avatar) {
        return (
            <Image className="user-avatar md-avatar rounded-circle" src={imageSrc(currentUser.avatar.imageBase64String)} alt="User avatar" />
        )
    } else {
        return (
          <Image className="user-avatar md-avatar rounded-circle" src={Avatar} alt="User avatar" />
        )
    }
  };

  if (currentUser.userTypeEnum === UserTypeEnum.Navient) {
    return (
      <Navbar variant="dark" expanded className="utility-nav d-print-none">
        <Container fluid className="m-0 p-0">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <Image src={nexusLogo} className="nexus-logo" alt="Nexus Logo" />
            </div>
            <Nav className="align-items-center">
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="p-0">
                  <div className="media d-flex align-items-center">
                    {renderAvatarImage()}
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                        <span className="current-user">{currentUser.firstName} {currentUser.lastName}</span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-3">
                  <Dropdown.Item as={Link} to={Routes.AccountSettings.path}>
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item onClick={() => onLogOut()}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
  );
} else {
  return (
    <Navbar variant="dark" expanded className="utility-nav d-print-none">
      <Container fluid className="m-0 p-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Image src={nexusLogo} className="nexus-logo" alt="Nexus Logo" />
            <span className="mx-3 nv-tag">{client?.businessName}</span>
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="p-0">
                <div className="media d-flex align-items-center">
                  {renderAvatarImage()}
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="current-user">{currentUser.firstName} {currentUser.lastName}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-3">
                <Dropdown.Item as={Link} to={Routes.AccountSettings.path}>
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item onClick={() => onLogOut()}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>)
  }
} 


const mapStateToProps = (state: IAppState) => {
    return {
      currentUser: state.auth.currentUser,
      client: state.clients.client
    };
};

export default connect(mapStateToProps)(Header);