export class Client {
    id?: string;
    msbId?: string;
    businessName: string = "";
    businessEstablishedDate: string = "";
    timeZoneInfo: string = "";
    observesDaylightSavings: boolean = false;
    
    updatedAt: string = "";
    createdAt: string = "";
    isArchived: boolean = false;
    onBoardingStatus: keyof typeof ClientStatusEnum = ClientStatusEnum[ClientStatusEnum.Initialized] as keyof typeof ClientStatusEnum;

    clientAddresses: Array<Address> = new Array<Address>();
    clientContacts: Array<Contact> = new Array<Contact>();
    departments: Array<Department> = new Array<Department>();
    merchantProcessors: Array<MerchantProcessor> = new Array<MerchantProcessor>();
}

export class ClientName {
    msbId: string = "";
    businessName: string = "";
}

export class DepartmentName {
    msbId: string = "";
    name: string = "";
}

export class PaymentChannelName {
    msbId: string = "";
    name: string = "";
}

export class ClientTimezone {
    clientId: string = "";
    timeZone: string = "";
}

export class CollectForMids{
    clientName: string = "";
    departmentName: string = "";
    paymentChannelName: string = "";
    businessType:BusinessTypeEnum = BusinessTypeEnum.Unknown;
}

export enum SortDirection {
    unknown = 0,
    asc = 1,
    desc = 2,
}

export class ClientStatusRequest {
    clientMsbId: string = "";
    clientStatusEnum: keyof typeof ClientStatusEnum = ClientStatusEnum[ClientStatusEnum.Initialized] as keyof typeof ClientStatusEnum;
}

export class DepartmentStatusRequest {
    departmentMsbId: string = "";
    departmentStatusEnum: keyof typeof DepartmentStatusEnum = DepartmentStatusEnum[DepartmentStatusEnum.Initialized] as keyof typeof DepartmentStatusEnum;
}

export enum TimeZoneEnum {
    ET = "Eastern Standard Time",
    CT = "Central Standard Time",
    MT = "Mountain Standard Time",
    PT = "Pacific Standard Time"
}

export class Address {
    id?: string;
    msbId?: string;
    addressType: keyof typeof AddressTypeEnum = "Physical";
    addressLine1: string = "";
    city: string = "";
    state: string = "";
    zipCode: string = "";
    country: string = "";
    addressLine2: string = "";
    addressReferenceName: string = "";
}

export class Contact {
    id?: string;
    msbId?: string;
    contactReferenceName: string = "";
    contactType: keyof typeof ContactTypeEnum = "CustomerService";
    firstName: string = "";
    lastName: string = "";
    phoneNumber: string = "";
    emailAddress: string = "";
    faxNumber: string = "";
}

export class Agreement {
    msbId?: string;
    clientMsbId: string = "";
    annualVolumne: number = 0;
    annualTicket: number = 0;
    highestVolumne: number = 0;
    percentageCardPresent: number = 0;
    percentageCardNotPresent: number = 0;
    percentageCardSwipe: number = 0;
    percentageImprint: number = 0;
    percentageB2B: number = 0;
    percentageMoto: number = 0;
    percentageInternet: number = 0;
    percentageInternationalCards: number = 0;
    goodsSoldEnum: number = 0;
    refundPolicyEnum: number = 0;
    neverAcceptedEnum: number = 0;
    processorChangeEnum: number = 0;
    pciCompliantEnum: number = 0;
    compromisedEnum: number = 0;
    completedRemediationEnum: number = 0;
    storeCardholderDataPaperEnum: number = 0;
    storeCardholderDataElectronicEnum: number = 0;
    vendorName: string = "";
    vendorAddressLine1: string = "";
    vendorAddressLine2: string = "";
    vendorCity: string = "";
    vendorState: string = "";
    vendorZipCode: string = "";
    vendorContactFirstName: string = "";
    vendorContactLastName: string = "";
    vendorContactPhoneNumber: string = "";
    vendorContactFaxNumber: string = "";
    vendorContactEmailAddress: string = "";
    physicallyInspectedEnum: number = 0;
    npcApprovedEnum: number = 0;
    notPhysicallyInspectedEnum: number = 0;
    appearsAsRepresentedEnum: number = 0;
    businessOpenEnum: number = 0;
    inventorySufficientEnum: number = 0;
    deliveredAtTimeOfSaleEnum: number = 0;
    chargedOnCardEnum: number = 0;
    deliveredAsEnum: number = 0;
    fulfillmentHouseEnum: number = 0;
    americanExpressNumber: string = "";
    americanExpressVolumne: number = 0;
    americanExpressOptOut: number = 0;
    americanExpressOptOutMarketingMaterials: number = 0;
}

export enum ReceiptTypeEnum {
    Unknown = 0,
    CardPresent = 1,
    CardNotPresent = 2,
    eCheck = 3,
    CardNotPresentReminder = 4,
    eCheckReminder = 5,
    PaymentDeclined = 6,
    PaymentFailed = 7,
    Notification = 8,
    Authorization = 9,
    Confirmation = 10

}

export class Receipt {
    msbId?: string;
    name: string = "";
    receiptType: ReceiptTypeEnum = ReceiptTypeEnum.Unknown;
    contentId: string = "";
}

export class Department {
    id?: string;
    msbId?: string;
    clientId: string = "";
    name: string = "";
    legalBusinessName: string = "";
    businessWebsite: string = "";
    bankFundingDescriptor: string = "";
    achDebitDescriptor: string = "";
    debitFeeMethod: DebitFeeMethodEnum = DebitFeeMethodEnum.DirectDebit;
    feeBurdenMethod: FeeBurdenMethodEnum = FeeBurdenMethodEnum.ConvenienceFee;
    fundingMethod: FundingMethodEnum = FundingMethodEnum.NextDay;
    chargebackFee: number = 0;
    nsfFee: number = 0;
    isConvenienceFeeClientAbsorbed: boolean = false;
    onBoardingStatus?: keyof typeof DepartmentStatusEnum = DepartmentStatusEnum[DepartmentStatusEnum.Initialized] as keyof typeof DepartmentStatusEnum;
    clientBankAccounts?: Array<BankAccount> = new Array<BankAccount>();
    departmentAddresses?: Array<Address> = new Array<Address>();
    departmentContacts?: Array<Contact> = new Array<Contact>();
    paymentChannels?: Array<PaymentChannel> = new Array<PaymentChannel>();
    convenienceFees?: Array<ConvenienceFee> = new Array<ConvenienceFee>();
    departmentsPrinters?: Array<Printer> = new Array<Printer>();
    receipts?: Array<Receipt> = new Array<Receipt>();
}

export enum PaymentTypeEnum
{
    Unknown = 0,
    AmericanExpressCredit = 1,
    AmericanExpressDebit = 2,
    DiscoverCredit = 3,
    DiscoverDebit = 4,
    MastercardCredit = 5,
    MastercardDebit = 6,
    MastercardDebitPIN = 7,
    VisaCredit = 8,
    VisaDebit = 9,
    VisaDebitPIN = 10,
    eCheck = 11,
    PayPal = 12,
    PayPalCard = 13,
    PayPalCredit = 14,
    PayPalVenmo = 15,
    AuthorizeDotNet = 16
}

export class ConvenienceFee
{
    msbId?: string;
    paymentType: PaymentTypeEnum = PaymentTypeEnum.Unknown;
    minimumAmount: any = 0.0;
    maximumAmount: any = 0.0;
    minimumConvenienceFeeAmount: any = 0.0;
    maximumConvenienceFeeAmount: any = 0.0;
    percentage: any = 0.0;
    flatFee: any = 0.0;
    createdAt?: string;
    updatedAt?: string
}

export enum ConvenienceFeeType
{
    Percentage = 0,
    FlatFee = 1
}

export class MerchantIdsCreateRequest
{
    clientMsbId: string = "";
    departmentMsbId: string = "";
    eCommerceCCDebitDescriptor: string = "";
    retailCCDebitDescriptor: string = "";
    ownershipTypeEnum: OwnershipTypeEnum = OwnershipTypeEnum.Unknown;
    checkForDuplicates: boolean = false;
    mccCode: string = "";
    batchCloseMethodEnum: BatchCloseMethodEnum = BatchCloseMethodEnum.MerchantInitiated;
    batchCloseTime: string = "";
    signaturePromptEnum: SignaturePromptEnum = SignaturePromptEnum.Never;
    signatureThreshold: number = 0;
    useVisa: boolean = true;
    useDiscover: boolean = false;
    useMastercard: boolean = true;
    useAmericanExpress: boolean = false;
}

export class MerchantIdsCreateResponse
{
    cardPresentMerchantID: MerchantProcessor;
    cardNotPresentMerchantID: MerchantProcessor;

    constructor(cardPresentMerchantID: MerchantProcessor, cardNotPresentMerchantID: MerchantProcessor) {
        this.cardPresentMerchantID = cardPresentMerchantID;
        this.cardNotPresentMerchantID = cardNotPresentMerchantID;
    }
}

export class ErrorResponse
{
    correlationId: string = "";
    httpStatusCode: string = "";
    httpStatusMessage: string = "";
    errors: Array<InternalError> = [];
}

export class InternalError
{
    errorCode: string = "";
    errorMessage: string = "";
    target: string = "";
}

export class MerchantProcessor {
    id?: string;
    msbId?: string;
    clientId: string = "";
    //channels: [],
    fundingAccounts: Array<FundingAccount> = new Array<FundingAccount>();
    terminals: Array<Terminal> = new Array<Terminal>();
    merchantIdentifier: string = "";
    businessType: BusinessTypeEnum = BusinessTypeEnum.Unknown;
    processor: string = "";
    isValid: boolean = false;
    processorType: keyof typeof MerchantProcessorTypeEnum = "Vantiv";
    createdAt?: string;
    updatedAt?: string;
    vantivCredential?: VantivCredential = new VantivCredential();
    vantivConfiguration: VantivConfiguration = new VantivConfiguration();
    merchantAddress: Address = new Address();
    merchantContact: Contact = new Contact();
    payPalCredential?: PayPalCredentials = new PayPalCredentials();
    authorizeDotNetCredential?: AuthorizeNetCredentials = new AuthorizeNetCredentials();
}

export class ProcessorInformation {
    businessType? :string;
}

export class FundingAccount {
    vantivFundingAccountId: string = "";
    merchantProcessorId: string = "";
}

export class VantivCredential {
    msbId?: string;
    merchantProcessorId?: string;
    accountId: string = "";
    accountToken: string = "";
    acceptorId: string = "";
    id?: string;
    createdAt?: string;
    updatedAt?: string;
}

export class VantivConfiguration {
    merchantProcessorId?: string;
    federalTaxId: string = "";
    dbaName: string = "";
    legalBusinessName: string = "";
    businessWebsite?: string;
    vantivIdentifier?: string;
    ownershipType: keyof typeof OwnershipTypeEnum = "Government";
    businessType: keyof typeof BusinessTypeEnum = "Unknown";
    mccCode: string = "9399";
    creditCardDebitDescriptor: string = "";
    underWritingStatus?: keyof typeof SubMerchantUnderWritingStatusEnum;
    underWritingReason?: string;
    batchCloseMethodType: keyof typeof BatchCloseMethodEnum = "MerchantInitiated";
    batchCloseTime: string = "23:59:00";
    acceptedCardTypes: Array<SubMerchantAcceptedCardTypeEnum> = new Array<SubMerchantAcceptedCardTypeEnum>();
    isOmniTokenEnabled: boolean = false;
    subMerchantTransactionType: keyof typeof SubMerchantTransactionTypeEnum = "Unknown";
    isCommercialCardBinQueryEnabled: boolean = false;
}

export enum MerchantProcessorTypeEnum {
    Unknown = 0,
    Vantiv = 1,
    PayPal = 2,
    AuthorizeDotNet = 3
}

export enum SubMerchantUnderWritingStatusEnum {
    Unknown = 0,
    Pending = 1,
    Approved = 2,
    Rejected = 3
}

export enum PromptForSignature {
    Unknown = 0,
    TimeInitiated
}

export class SubMerchantResult {
    eCommerceMerchantID?: MerchantProcessor;
    retailMerchantID?: MerchantProcessor;
    errors?: Map<string, Array<string>>;
}

export enum SubMerchantTransactionTypeEnum {
    Unknown = 0,
    OnlineStore = 1,
    NoOnlineStore = 2
}

export class PayPalCredentials {
    id?: string;
    msbId?: string;
    createdAt?: string;
    updatedAt?: string;
    isArchived?: boolean;
    createdByMsbId?: string;
    updatedByMsbId?: string;
    allowCard: boolean = false;
    allowCredit: boolean = false;
    allowVenmo: boolean = false;
    payPalMerchantIdentifier?: string;
    navientCommissionPercentage: any = 0.00;
    permissionGranted: boolean = false;
    consentStatus: boolean = false;
    isEmailConfirmed: boolean = false;
    isPaymentReceivable: boolean = false;
}

export class AuthorizeNetCredentials {
    id?: string;
    msbId?: string;
    createdAt?: string;
    updatedAt?: string;
    isArchived?: boolean;
    createdByMsbId?: string;
    updatedByMsbId?: string;
    name: string = "";
    transactionKey: string = "";
    backFill: boolean = false;
    numberOfDaysToBackFill: number = 0;
}

export class ApprovalChecklist {
    msbId?: string;
    clientMsbId: string = "";
    merchantApplication: boolean = false;
    w9Signed: boolean = false;
    achFormSigned: boolean = false;
    verballyConfirmedACH: boolean = false;
    paymentProcessingContract: boolean = false;
    ofacSanctions: boolean = false;
    fincen: boolean = false;
    governmentLocationExists: boolean = false;
    convenienceFees: boolean = false;
    comments: string = "";
    isApprovalSubmitted: boolean = false;
}

export enum IntegrationType {
    None = 0,
    TylerEagle = 1,
    Redirect = 2,
    AuthorizeDotNet = 3
}

export class PaymentChannel {
    id: string = "";
    msbId?: string;
    createdAt?: string = "";
    updatedAt?: string = "";
    isArchived: boolean = false;
    departmentId: string = "";
    processors: Array<Processor> = new Array<Processor>();
    convenienceFees: Array<ConvenienceFee> = new Array<ConvenienceFee>();
    softBlockedAcceptedCards: Array<SubMerchantAcceptedCardTypeEnum> = new Array<SubMerchantAcceptedCardTypeEnum>();
    name: string = "";
    webApiKey: string = "";
    paymentChannelType: keyof typeof PaymentChannelTypeEnum = "Select";
    webChannelType: keyof typeof WebChannelTypeEnum = "BlindPayment";
    integrationType: keyof typeof IntegrationType = "None";
    isActive: boolean = false;
    checkForDuplicateTransactions: boolean = false;
    requiresCvv: boolean = false;
    enableAvsChecks: boolean = false;
    requiresReferenceNumber: boolean = false;
    electronicCheckPaymentsEnabled: boolean = false;
    creditCardPaymentsEnabled: boolean = false;
    manualEntryPaymentsEnabled: boolean = false;
    terminalPaymentsEnabled: boolean = false;
    paymentMethodOnFileEnabled: boolean = false;
    creditCardPMoFEnabled: boolean = false;
    eCheckPMoFEnabled: boolean = false;
    terminalPMoFEnabled: boolean = false;
    payPalPaymentsEnabled: boolean = false;
    channelSupplementalFields: Array<SupplementalField> = new Array<SupplementalField>();
    authorizationFundsHoldTimeOutDays: number = 7;
    customerFundingType: keyof typeof CustomerFundingType = "Department";
    enableAutoReceipts: boolean = false;
    autoReceiptEmailAddresses: Array<AutoReceiptEmailAddress> = new Array<AutoReceiptEmailAddress>();
    requiredFieldDefinitions?: any;
    embeddedCheckoutIsEnabled: boolean = false;
    embeddedLinksIsEnabled: boolean = false;
    tokenizationIsEnabled: boolean = false;
    independentSoftwareVendorConfiguration?: any;
    levelThreeConfiguration?: any;
    promptForSignature: SignaturePromptEnum = SignaturePromptEnum.Never;
    promptForSignatureThresholdAmount: string = "0.00";
    feesAreNavientRetained? :boolean;
}

export class SupplementalField {
    msbId?: string;
    supplementalFieldType: keyof typeof SupplementalFieldType = "Unknown";
    fieldLabel: string = "";
    fieldIdentifier: string = "";
    fieldIsRequired: boolean = false;
    fieldProperties: any;
}

export class AutoReceiptEmailAddress {
    emailAddress: string = "";
}
export enum SupplementalFieldType {
    Unknown = 0,
    TextBox = 1,
    Date = 2,
    List = 3,
    Radio = 4,
    Checkbox = 5
}

export enum SubMerchantAcceptedCardTypeEnum {
    None = 0,
    Visa = 1,
    MasterCard = 2,
    AmericanExpress = 4,
    Discover = 8,
    Debit = 16
}

export class Processor {
    paymentChannelId: string = "";
    merchantProcessorId: string = "";
    merchantProcessor?: MerchantProcessor;
}

export enum PaymentChannelTypeEnum {
    Select = 0,
    QuickPay = 1,
    TerminalPay = 2,
    IVR = 3,
    WebAPI = 4,
    Kiosk = 5,
    Web = 6,
    PointOfSale = 7,
    ThirdParty = 8
}

export enum CustomerFundingType {
    Unknown = 0,
    Department = 1,
    PaymentChannel = 2,
    Item = 3
}

export class Printer {
    msbId?: string;
    departmentMsbId: string = "";
    name: string = "";
    ipAddress: string = "";
    userName: string = "";
    password: string = "";
    statusEnum: PrinterStatusEnum = PrinterStatusEnum.Offline;
    serial: string = "";
}

export enum PrinterStatusEnum {
    Offline = 0,
    Online = 1,
    Unknown = 2
}

export class Terminal {
    id?: string;
    msbId?: string;
    createdAt?: string = "";
    updatedAt?: string = "";
    merchantProcessorId?: string;
    laneId?: number;
    name: string = "";
    status?: string = "";
    statusLastReceived?: string = "";
    contactlessEmvEnabled?: boolean = false;
    contactlessMsdEnabled?: boolean = false;
    quickChipEnabled: boolean = false;
    idleMessage: string = "";
}

export class ClientBanking {
    msbId?: string;
    clientMsbId: string = "";
    departmentMsbId: string = "";
    accountDDATypeEnum: AccountDDATypeEnum = AccountDDATypeEnum.Checking;
    bankAccountNumber:string = "";
    bankRoutingNumber:string = "";
    detailId: string = "";
    additionalDetails: string = "";
}

export enum TerminalStatusEnum {
    Offline = 0,
    Online = 1,
    Unknown = 2
}

export enum ClientStatusEnum {
    Initialized = 0,
    DetailsComplete = 1,
    AddressComplete = 2,
    ContactsComplete = 3,
    SettlementComplete = 4,
    AccountingComplete = 5,
    MerchantProcessorComplete = 6,
    ApprovalPending = 7,
    ApprovalRejected = 8,
    Complete = 9
}

export enum DepartmentStatusEnum {
    Initialized = 0,
    DetailsComplete = 1,
    AddressComplete = 2,
    ContactsComplete = 3,
    SettlementComplete = 4,
    AccountingComplete = 5,
    MerchantProcessorComplete = 6,
    ApprovalPending = 7,
    ApprovalRejected = 8,
    Complete = 9
}

export enum BankAccountToUseEnum {
    GilaDeposit = 1,
    GilaChargeback = 2
}

export enum ContactTypeEnum {
    Unknown = 0,
    CustomerService = 1,
    TerminalDeployment = 2,
    Primary = 3
}

export enum AddressTypeEnum {
    Unknown = 0,
    Physical = 1,
    Mailing = 2,
    TerminalDeployment = 3
}

export enum OwnershipTypeEnum {
    Unknown = 0,
    PublicCorporation = 1,
    PrivateCorporation = 2,
    SECRegulatedCorporation = 3,
    Government = 4,
    SoleProprietorship = 5,
    LLC = 6,
    Partnership = 7,
    NonProfit = 8,
    FinancialInstitution = 9,
    Trust = 10
}

export enum BusinessTypeEnum {
    Unknown = 0,
    AutoRental = 1,
    ECommerce = 2,
    Lodging = 3,
    MOTO = 4,
    Restaurant = 5,
    Retail = 6
}

export enum SettlementTypeEnum {
    Unknown = 0,
    Deposit = 1,
    Chargeback = 2,
}
export enum AccountDDATypeEnum {
    Checking = 1,
    Savings = 2
}

export enum AccountACHTypeEnum {
    Commercial = 1,
    Private = 2
}

export enum AccountCategoryEnum {
    Invalid = 0,
    CreditDeposits = 1,
    CreditChargebacks = 2,
    CreditProcessingFees = 3,
    DebitDeposits = 4,
    DebitChargeBacks = 5,
    DebitConvenienceFees = 6,
    CreditConvenienceFees = 7,
}

export enum BatchCloseMethodEnum {
    TimeInitiated = 1,
    MerchantInitiated = 2
}

export enum CheckDuplicatesEnum {
    Yes = 1,
    No = 2
}

export enum DebitFeeMethodEnum {
    DirectDebit = 1,
    BillOut = 2,
    Net = 3
}

export enum FeeBurdenMethodEnum {
    ConvenienceFee = 1,
    ClientAbsorbed = 2
}

export enum FundingMethodEnum {
    SameDay = 1,
    NextDay = 2,
    TwoDay = 3,
    Other = 4
}
export enum SignaturePromptEnum {
    Unknown = 0,
    UseThreshold = 1,
    Always = 2,
    Never = 3
}

export enum YesNoEnum {
    NotProvided = 0,
    Yes = 1,
    No = 2,
    NotSure = 3
}

export enum GoodsSoldEnum {
    NotProvided = 0,
    Bonds = 1
}

export enum RefundPolicyEnum {
    NotProvided = 0,
    NoRefund = 1,
    Refund30Days = 2,
    Other = 3
}

export enum ChargedOnCardEnum {
    NotProvided = 0,
    Order = 1,
    Shipment = 2
}

export enum DeliveredAsEnum {
    NotProvided = 0,
    Digitially = 1,
    Physically = 2,
    Both = 3
}

export class BankAccount 
{
    msbId?: string;
    accountDDATypeEnum: AccountDDATypeEnum = AccountDDATypeEnum.Checking;
    bankAccountNumber:string = "";
    bankRoutingNumber:string = "";
}

/*
export enum BankAccountUsageEnum
{
    Deposit = 1,
    ChargeBack = 2
}
*/

export enum WebChannelTypeEnum {
    None = 0,
    BlindPayment = 1,
    ShoppingCart = 2,
    WebIntegration = 3
}

