import axios from 'axios';

import { User, UserAuthRequest, UserClient, UserDepartment, UserTypeEnum } from '../../models/User';
import { Dispatch } from 'react';
import store from "../../redux/store";
import { PublicClientApplication } from "@azure/msal-browser";
import { Permissions as UsePermissions } from '../../components/usePermissions';

export const POST_AUTH_REQUEST = 'POST_AUTH_REQUEST';
export const POST_AUTH_SUCCESS = 'POST_AUTH_SUCCESS';
export const POST_AUTH_FAILURE = 'POST_AUTH_FAILURE';

export const SAVE_CURRENT_USER_REQUEST = 'SAVE_CURRENT_USER_REQUEST';
export const SAVE_CURRENT_USER_SUCCESS = 'SAVE_CURRENT_USER_SUCCESS';
export const SAVE_CURRENT_USER_FAILURE = 'SAVE_CURRENT_USER_FAILURE';

export const SAVE_CURRENT_USER_PREFERENCES_REQUEST = 'SAVE_CURRENT_USER_PREFERENCES_REQUEST';
export const SAVE_CURRENT_USER_PREFERENCES_SUCCESS = 'SAVE_CURRENT_USER_PREFERENCES_SUCCESS';
export const SAVE_CURRENT_USER_PREFERENCES_FAILURE = 'SAVE_CURRENT_USER_PREFERENCES_FAILURE';

export const SAVE_AVATAR_REQUEST = 'SAVE_AVATAR_REQUEST';
export const SAVE_AVATAR_SUCCESS = 'SAVE_AVATAR_SUCCESS';
export const SAVE_AVATAR_FAILURE = 'SAVE_AVATAR_FAILURE';

export const GET_AVATAR_REQUEST = 'GET_AVATAR_REQUEST';
export const GET_AVATAR_SUCCESS = 'GET_AVATAR_SUCCESS';
export const GET_AVATAR_FAILURE = 'GET_AVATAR_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const PASSWORD_RESET_EMAIL_REQUEST = 'PASSWORD_RESET_EMAIL_REQUEST';
export const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS';

export const CLEAR_AUTH_ACTION_RESULT = 'CLEAR_AUTH_ACTION_RESULT';


axios.interceptors.request.use(config => {
    config.headers.common['Authorization'] = `Bearer ${store?.getState()?.webAppSettings?.bearerToken}`
	return config;
});

export const doLogOut = () => (dispatch: Dispatch<any>) => {
    let msalInstance = store.getState().auth.msalInstance as PublicClientApplication;
    msalInstance.logoutRedirect();
};

export const signInAction = (email:string, oid: string, msalInstance: PublicClientApplication, actionToken?: string, ) => (dispatch : Dispatch<any>) => {
    dispatch({
        type: POST_AUTH_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = "/MSB_User/api/v1/Authentication/Authenticate";
    let bearerToken = store.getState().webAppSettings.bearerToken;

    axios.defaults.baseURL = baseApiUrl;
    axios.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;

    let userAuthRequest = new UserAuthRequest();
    userAuthRequest.objectId = oid;
    userAuthRequest.email =  email!;

    axios.post(_url, userAuthRequest)
    .then(function (response) {

        let token = response.data.token;
        let parsedJWT =  JSON.parse(window.atob(token.split('.')[1]));

        let userPermissions = parsedJWT['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

        let currentUser = response.data.user;
        currentUser.userTypeEnum = UserTypeEnum[parsedJWT.UserType]


        axios.defaults.headers.common['MsbBearer'] = `${token}`;

        var impersonateDepartment = localStorage.getItem("impersonateDepartment");
        localStorage.removeItem("impersonateDepartment");

        if (impersonateDepartment !== null) {
            var clientDepartment = JSON.parse(impersonateDepartment);

            currentUser.userTypeEnum = UserTypeEnum.Client;
            currentUser.userClients = [];
            currentUser.userClients.push(new UserClient(clientDepartment.clientMsbId));

            currentUser.userDepartments = [];
            currentUser.userDepartments.push(new UserDepartment(clientDepartment.departmentMsbId));

            userPermissions = [];
            userPermissions.push(UsePermissions.ProcessTransactionsExecute);
            userPermissions.push(UsePermissions.RefundAPaymentExecute);
            userPermissions.push(UsePermissions.VoidAPaymentExecute);
            userPermissions.push(UsePermissions.ManagePMoFExecute);
        }

        dispatch({
            type: POST_AUTH_SUCCESS,
            payload: {
                currentUser,
                userPermissions,
                token,
                msalInstance,
                actionToken
            }
        });

    })
    .catch(function (error) {
        dispatch({
            type: POST_AUTH_FAILURE,
            payload: {
                error,
                actionToken
            }
        });
    });
};

export const clearActionResult = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_AUTH_ACTION_RESULT,
        payload: {
        }
    });
}

export const saveCurrentUserPreferencesAction = (currentUser: User, actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_CURRENT_USER_PREFERENCES_REQUEST,
        payload: {
            actionToken
        }
    });
    
    currentUser.userRoles = undefined;

    let _url = "/MSB_User/api/v1/Users/selfUpdate/" + currentUser.msbId;

    axios.put(_url, currentUser)
        .then(function (response) {
            let currentUser = response.data;
            dispatch({
                type: SAVE_CURRENT_USER_PREFERENCES_SUCCESS,
                payload: {
                    currentUser,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_CURRENT_USER_PREFERENCES_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }
    );
};

export const saveCurrentUserAction = (currentUser: User, actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_CURRENT_USER_REQUEST,
        payload: {
            actionToken
        }
    });
    
    currentUser.userRoles = undefined;

    let _url = "/MSB_User/api/v1/Users/selfUpdate/" + currentUser.msbId;

    axios.put(_url, currentUser)
        .then(function (response) {
            let currentUser = response.data;
            dispatch({
                type: SAVE_CURRENT_USER_SUCCESS,
                payload: {
                    currentUser,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_CURRENT_USER_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }
    );
};

export const saveAvatarAction = (userMsbId: string, imageFile: Blob, actionToken:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_AVATAR_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_User/api/v1/Avatars/" + userMsbId;

    async function blobToBase64(blob:Blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      }
    
    var formData = new FormData();
    formData.append("File", imageFile);

    axios.post(_url, formData).then(async function (response) {
        let avatar = await blobToBase64(imageFile);
            dispatch({
                type: SAVE_AVATAR_SUCCESS,
                payload: {
                    avatar,
                    actionToken
                }
            });
        })
    .catch(function (error) {
            dispatch({
                type: SAVE_AVATAR_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
    .then(function () {
        });
        
};

export const getAvatarAction = (userMsbId:string, actionToken:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_AVATAR_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url = "/MSB_User/api/v1/Avatars/" + userMsbId;

    axios.get(_url)
        .then(function (response) {
            let avatar  = response.data;
            dispatch({
                type: GET_AVATAR_SUCCESS,
                payload: {
                    avatar,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_AVATAR_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};