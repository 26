import { MsbReportItem } from '../../models/Reports';
import { SET_REPORT_COLUMNS, SET_FUNDING_DETAILS_DATA, SET_RECON_DETAILS_DATA, SET_RECON_TRANSACTIONS_DATA,
        GET_DYNAMIC_QUERY_REPORT_REQUEST, GET_DYNAMIC_QUERY_REPORT_SUCCESS, GET_DYNAMIC_QUERY_REPORT_FAILURE,
        GET_ORDER_TRX_DETAILS_REQUEST, GET_ORDER_TRX_DETAILS_SUCCESS, GET_ORDER_TRX_DETAILS_FAILURE,
        RESET_REPORT_STORE_REQUEST, RESET_REPORT_STORE_SUCCESS, GET_FUNDING_REPORT_REQUEST, GET_FUNDING_REPORT_SUCCESS, GET_FUNDING_REPORT_FAILURE,
        GET_DYNAMIC_QUERY_REPORT_PAYMENTS_REQUEST, GET_DYNAMIC_QUERY_REPORT_PAYMENTS_SUCCESS, GET_DYNAMIC_QUERY_REPORT_PAYMENTS_FAILURE,
        GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_REQUEST, GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_SUCCESS, GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_FAILURE,
        GET_WORLDPAY_TRANSACTIONS_REQUEST, GET_WORLDPAY_TRANSACTIONS_SUCCESS, GET_WORLDPAY_TRANSACTIONS_FAILURE, SET_WORLDPAY_TRANSACTION_DETAILS } from '../actions/reports/report';

export default function reports(state = {
    isFetching: false,
    summaryAllTransactions: [],
    fundingReportTransactions: [],
    summaryDeclinedTransactions: [],
    reportColumns: new Map(),
    actionResult: null,
    errorMessage: '',
    fundingDetails: new MsbReportItem(),
    orderDetailsTransactions: [],
    worldpayTransactions: [],
    searchState: {},
    transactionDate: {}
}, { type, payload }) {
    switch (type) {
        case SET_REPORT_COLUMNS:
            var reportType = payload.reportType;
            var columns = payload.columns;
            var _reportColumns = new Map(state.reportColumns);
            _reportColumns.set(reportType, columns);

            return Object.assign({}, state, {
                isFetching: true,
                reportColumns: _reportColumns,
                actionResult: { type: SET_REPORT_COLUMNS, token: payload.actionToken, result: SET_REPORT_COLUMNS },
            });

        case SET_FUNDING_DETAILS_DATA:
            return Object.assign({}, state, { fundingDetails: payload.data, transactionDate: payload.transactionDate });            
        case SET_RECON_DETAILS_DATA:
            return Object.assign({}, state, { searchState: Object.assign({}, state.searchState, payload) });
        case SET_RECON_TRANSACTIONS_DATA:
            return Object.assign({}, state, { searchState: Object.assign({}, state.searchState, payload) });
            

        case GET_DYNAMIC_QUERY_REPORT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                searchState: payload.searchState,
                summaryAllTransactions: [],
                actionResult: { type: GET_DYNAMIC_QUERY_REPORT_REQUEST, token: payload.actionToken, result: null },
            });
        case GET_DYNAMIC_QUERY_REPORT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                summaryAllTransactions: payload.summaryAllTransactions,
                actionResult: { type: GET_DYNAMIC_QUERY_REPORT_REQUEST, token: payload.actionToken, result: GET_DYNAMIC_QUERY_REPORT_SUCCESS }
            });
        case GET_DYNAMIC_QUERY_REPORT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: payload.error,
                actionResult: { type: GET_DYNAMIC_QUERY_REPORT_REQUEST, token: payload.actionToken, result: GET_DYNAMIC_QUERY_REPORT_FAILURE }
            });
        //Funding Report
        case GET_FUNDING_REPORT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                fundingReportTransactions: [],
                actionResult: { type: GET_FUNDING_REPORT_REQUEST, token: payload.actionToken, result: null },
            });
        case GET_FUNDING_REPORT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                fundingReportTransactions: payload.fundingReportTransactions,
                actionResult: { type: GET_FUNDING_REPORT_REQUEST, token: payload.actionToken, result: GET_FUNDING_REPORT_SUCCESS }
            });
        case GET_FUNDING_REPORT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: payload.error,
                actionResult: { type: GET_FUNDING_REPORT_REQUEST, token: payload.actionToken, result: GET_FUNDING_REPORT_FAILURE }
            });
        //Payments    
        case GET_DYNAMIC_QUERY_REPORT_PAYMENTS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                summaryAllTransactions: [],
                actionResult: { type: GET_DYNAMIC_QUERY_REPORT_PAYMENTS_REQUEST, token: payload.actionToken, result: null },
            });
        case GET_DYNAMIC_QUERY_REPORT_PAYMENTS_SUCCESS:

            return Object.assign({}, state, {
                isFetching: false,
                summaryAllTransactions: payload.summaryAllTransactions,
                actionResult: { type: GET_DYNAMIC_QUERY_REPORT_PAYMENTS_REQUEST, token: payload.actionToken, result: GET_DYNAMIC_QUERY_REPORT_PAYMENTS_SUCCESS }
            });
        case GET_DYNAMIC_QUERY_REPORT_PAYMENTS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: payload.error,
                actionResult: { type: GET_DYNAMIC_QUERY_REPORT_PAYMENTS_REQUEST, token: payload.actionToken, result: GET_DYNAMIC_QUERY_REPORT_PAYMENTS_FAILURE }
            });
        //Declined Paymenst    
        case GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                summaryDeclinedTransactions: [],
                actionResult: { type: GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_REQUEST, token: payload.actionToken, result: null },
            });
        case GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_SUCCESS:

            return Object.assign({}, state, {
                isFetching: false,
                summaryDeclinedTransactions: payload.summaryDeclinedTransactions,
                actionResult: { type: GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_REQUEST, token: payload.actionToken, result: GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_SUCCESS }
            });
        case GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: payload.error,
                actionResult: { type: GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_REQUEST, token: payload.actionToken, result: GET_DYNAMIC_QUERY_REPORT_DECLINED_PAYMENTS_FAILURE }
            });
        // Transaction Order Details
        case GET_ORDER_TRX_DETAILS_REQUEST:
            return Object.assign({}, state, {
                orderDetailsTransactions: [],
                actionResult: { type: GET_ORDER_TRX_DETAILS_REQUEST, token: payload.actionToken, result: null },
            });
        case GET_ORDER_TRX_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                orderDetailsTransactions: payload.transactions,
                orderDetailsTransactionType: payload.transactionType,
                actionResult: { type: GET_ORDER_TRX_DETAILS_REQUEST, token: payload.actionToken, result: GET_ORDER_TRX_DETAILS_SUCCESS }
            });
        case GET_ORDER_TRX_DETAILS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: payload.error,
                actionResult: { type: GET_ORDER_TRX_DETAILS_REQUEST, token: payload.actionToken, result: GET_ORDER_TRX_DETAILS_FAILURE }
            });
        // Worldpay Transactions
        case GET_WORLDPAY_TRANSACTIONS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                worldpayTransactions: [],
                actionResult: { type: GET_WORLDPAY_TRANSACTIONS_REQUEST, token: payload.actionToken, result: null },
            });
        case GET_WORLDPAY_TRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                worldpayTransactions: payload.transactions,
                actionResult: { type: GET_WORLDPAY_TRANSACTIONS_REQUEST, token: payload.actionToken, result: GET_WORLDPAY_TRANSACTIONS_SUCCESS }
            });
        case GET_WORLDPAY_TRANSACTIONS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: payload.error,
                actionResult: { type: GET_WORLDPAY_TRANSACTIONS_REQUEST, token: payload.actionToken, result: GET_WORLDPAY_TRANSACTIONS_FAILURE }
            });
        case SET_WORLDPAY_TRANSACTION_DETAILS:
            return Object.assign({}, state, { searchState: Object.assign({}, state.searchState, payload) });
        case RESET_REPORT_STORE_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: RESET_REPORT_STORE_REQUEST, result: RESET_REPORT_STORE_SUCCESS, token: payload.actionToken },
                isFetching: false,
                summaryAllTransactions: [],
                fundingReportTransactions: [],
                summaryDeclinedTransactions: [],
                reportColumns: new Map(),
                actionResult: null,
                errorMessage: '',
                fundingDetails: new MsbReportItem(),
                orderDetailsTransactions: [],
                worldpayTransactions: [],
                searchState: {},
                transactionDate: {}
            });
            default:
            return state;

    }
}
