import * as React from "react";
import {useEffect, useState} from "react";
import { connect, useDispatch } from "react-redux";

import { IActionResult, IAppState } from '../../../redux/storeTypes';
import EditorPageHeader from './components/EditorPageHeader';
import { Redirect } from "react-router-dom";
import type { Value } from '@react-page/editor';
import Editor, { CellPlugin } from '@react-page/editor';

import slate from '@react-page/plugins-slate';
import image from '@react-page/plugins-image';
import background, { ModeEnum } from '@react-page/plugins-background';

import '@react-page/editor/lib/index.css';
import { WebApplication, WebPage, WebPageEnum } from "../../../models/PaymentChannelWebApplication";
import { clearContentAction, getContentAction, saveContentAction, SAVE_CMS_CONTENT_FAILURE, SAVE_CMS_CONTENT_REQUEST, SAVE_CMS_CONTENT_SUCCESS } from "../../../redux/actions/cms";
import { Content, Revision, StatusEnum } from "../../../models/CMS";
import { sendErrorToastAction, sendSuccessToastAction } from "../../../redux/actions/toast";
import { saveWebPageAction } from "../../../redux/actions/clients/paymentChannelWebApplications";
import { Client, IntegrationType } from "../../../models/Client";
import TylerEaglePaymentReferencePlugin from "./components/plugins/TylerEaglePaymentReferencePlugin";
import TylerEaglePOSRedirectPlugin from "./components/plugins/TylerEaglePOSRedirectPlugin";
import TylerEagleRedirectPlugin from "./components/plugins/TylerEagleRedirectPlugin";
import UserPaymentMethodsPlugin from "./components/plugins/UserPaymentMethodsPlugin";
import ItemSearchPlugin from "./components/plugins/ItemSearchPlugin";
import UserScheduledPaymentsPlugin from "./components/plugins/UserScheduledPaymentsPlugin";
import PaymentReferencePlugin from "./components/plugins/PaymentReferencePlugin";
import PaymentDetailsPlugin from "./components/plugins/PaymentDetailsPlugin";
import AccountDetailsPlugin from "./components/plugins/AccountDetailsPlugin";
import BillingDetailsPlugin from "./components/plugins/BillingDetailsPlugin";
import PaymentSummaryPlugin from "./components/plugins/PaymentSummaryPlugin";
import HeaderPlugin from "./components/plugins/HeaderPlugin";
import CardPlugin from "./components/plugins/CardPlugin";
import { Card, Form } from "react-bootstrap";
import HorizontalRulePlugin from "./components/plugins/HorizontalRulePlugin";
import PlainTextPlugin from "./components/plugins/PlainTextPlugin";
import NavigationLinkPlugin from "./components/plugins/NavigationLinkPlugin";
import NavigationMenuPlugin from "./components/plugins/NavigationMenuPlugin";
import ButtonPlugin from "./components/plugins/ButtonPlugin";
import HeroPlugin from "./components/plugins/HeroPlugin";
import PaymentLogosPlugin from "./components/plugins/PaymentLogosPlugin";
import SpacerPlugin from "./components/plugins/SpacerPlugin";
import FormInputTextboxPlugin from "./components/plugins/FormInputTextboxPlugin";
import InvoicePaymentPlugin from "./components/plugins/InvoicePaymentPlugin";
import CopyrightFooterPlugin from "./components/plugins/FooterCopyrightPlugin";
import PrivacyPolicyTermsConditionFooterPlugin from "./components/plugins/FooterPrivacyPolicyTermsConditions";

import MultiSearchPlugin from "./components/plugins/MultiSearchPlugin";
import MultiSearchResultsPlugin from "./components/plugins/MultiSearchResultsPlugin";
import NericSearchResultsPlugin from "./components/plugins/NericResultsPlugin";
import ForsythSearchResultsPlugin from "./components/plugins/ForsythResultsPlugin";
import LarimerSearchResultsPlugin from "./components/plugins/LarimerSearchResultsPlugin";
import LoganSearchResultsPlugin from "./components/plugins/LoganResultsPlugin";
import MathewsSearchResultsPlugin from "./components/plugins/MathewsResultsPlugin";
import StCharlesSearchResultsPlugin from "./components/plugins/StCharlesSearchResultsPlugin";
import PikeSearchResultsPlugin from "./components/plugins/PikeResultsPlugin";
import ItemShoppingCartPlugin from "./components/plugins/ItemShoppingCartPlugin";
import StCharlesShoppingCartPlugin from "./components/plugins/StCharlesShoppingCartPlugin";
import ForsythShoppingCartPlugin from "./components/plugins/ForsythShoppingCartPlugin";
import LoganShoppingCartPlugin from "./components/plugins/LoganShoppingCartPlugin";
import LarimerShoppingCartPlugin from "./components/plugins/LarimerShoppingCartPlugin";
import MathewsShoppingCartPlugin from "./components/plugins/MathewsShoppingCartPlugin";
import PikeShoppingCartPlugin from "./components/plugins/PikeShoppingCartPlugin";
import ModalDetailPlugin from "./components/plugins/ModalDetailPlugin";
import { Routes } from "../../../routes";
import OrderLineCartPlugin from "./components/plugins/OrderLineCartPlugin";
import DatePickerPlugin from "./components/plugins/DatePickerPlugin";
import SelectPlugin from "./components/plugins/SelectPlugin";
import CheckboxPlugin from "./components/plugins/CheckboxPlugin";
import RadioButtonPlugin from "./components/plugins/RadioButtonPlugin";
import CatalogPlugin from "./components/plugins/CatalogPlugin";
import SessionLimiterPlugin from "./components/plugins/SessionLimiterPlugin";

export interface IWebPaymentChannePageEditor {
    isFetching: boolean,
    isSaving: boolean,
    webPage : WebPage
    webApplication: WebApplication,
    actionResult: IActionResult,
    cmsActionResult: IActionResult,
    content: Content,
    client: Client
}

const WebPaymentChannePageEditor = ({ isFetching, isSaving, client, webPage, webApplication, content, actionResult, cmsActionResult }: IWebPaymentChannePageEditor) => {
    const [value, setValue] = useState<Value>();
    const [validated, setValidated] = useState<boolean>(false);
    const [editorReady, setEditorReady] = useState<boolean>(false);
    const [cellPlugins, setCellPlugins] = useState<Array<CellPlugin>>(Array<CellPlugin>());
    const [redirect, setRedirect] = useState<string>("");

    const dispatch = useDispatch();
    const actionToken = "WebPaymentChannePageEditor";

    //let shoppingCartPlugin = ShoppingCartPlugin as CellPlugin<unknown, unknown>;
    let cardPlugin = CardPlugin as CellPlugin<unknown, unknown>;
    let horizontalRulePlugin = HorizontalRulePlugin as CellPlugin<unknown, unknown>;
    let imagePlugin = image as CellPlugin<unknown, unknown>;
    let headerPlugin = HeaderPlugin as CellPlugin<unknown, unknown>;
    let plainTextPlugin = PlainTextPlugin as CellPlugin<unknown, unknown>;
    let slatePlugin = slate() as CellPlugin<unknown, unknown>;
    let buttonPlugin = ButtonPlugin as CellPlugin<unknown, unknown>;
    let heroPlugin = HeroPlugin as CellPlugin<unknown, unknown>;
    let paymentLogosPlugin = PaymentLogosPlugin as CellPlugin<unknown, unknown>;
    let spacerPlugin = SpacerPlugin as CellPlugin<unknown, unknown>;
    let copyrightFooterPlugin = CopyrightFooterPlugin as CellPlugin<unknown, unknown>;
    let privacyPolicyTermsConditionFooterPlugin = PrivacyPolicyTermsConditionFooterPlugin as CellPlugin<unknown, unknown>;
    let datePickerPlugin = DatePickerPlugin as CellPlugin<unknown, unknown>;
    let selectPlugin = SelectPlugin as CellPlugin<unknown, unknown>;
    let checkboxPlugin = CheckboxPlugin as CellPlugin<unknown, unknown>;
    let radioButtonPlugin = RadioButtonPlugin as CellPlugin<unknown, unknown>;
    let catalogPlugin = CatalogPlugin as CellPlugin<unknown, unknown>;
    let sessionLimiterPlugin = SessionLimiterPlugin as CellPlugin<unknown, unknown>;

    let backgroundPlugin = background({
        enabledModes:
          ModeEnum.COLOR_MODE_FLAG |
          ModeEnum.IMAGE_MODE_FLAG |
          ModeEnum.GRADIENT_MODE_FLAG,
    }) as CellPlugin<unknown, unknown>;

    useEffect(() => {
        if (webPage) {
            cardPlugin.cellPlugins = new Array<CellPlugin>();

            cellPlugins.push(imagePlugin);
            cellPlugins.push(headerPlugin);  
            cellPlugins.push(slatePlugin);  
            cellPlugins.push(cardPlugin);
            cellPlugins.push(horizontalRulePlugin);
            cellPlugins.push(plainTextPlugin);  
            cellPlugins.push(buttonPlugin);  
            cellPlugins.push(heroPlugin);  
            cellPlugins.push(paymentLogosPlugin);  
            cellPlugins.push(spacerPlugin);  
            cellPlugins.push(copyrightFooterPlugin);  
            cellPlugins.push(privacyPolicyTermsConditionFooterPlugin);
            cellPlugins.push(datePickerPlugin);
            cellPlugins.push(selectPlugin);
            cellPlugins.push(checkboxPlugin);
            cellPlugins.push(radioButtonPlugin);
            cellPlugins.push(catalogPlugin);
            cellPlugins.push(sessionLimiterPlugin);

            const _cellPlugins = [...cellPlugins];

            if (webPage.webPageType === WebPageEnum.QuickPay || webPage.webPageType === WebPageEnum.Payment) {
                if (webApplication.paymentChannel.integrationType === IntegrationType[IntegrationType.TylerEagle]) {
                    let tylerEaglePaymentReferencePlugin = TylerEaglePaymentReferencePlugin as CellPlugin<unknown, unknown>;
                    let tylerEaglePOSRedirectPlugin = TylerEaglePOSRedirectPlugin as CellPlugin<unknown, unknown>;
                    let tylerEagleRedirectPlugin = TylerEagleRedirectPlugin as CellPlugin<unknown, unknown>;

                    _cellPlugins.push(tylerEaglePaymentReferencePlugin);
                    _cellPlugins.push(tylerEaglePOSRedirectPlugin);
                    _cellPlugins.push(tylerEagleRedirectPlugin);
                } else {
                    let paymentReferencePlugin = PaymentReferencePlugin as CellPlugin<unknown, unknown>;
                    _cellPlugins.push(paymentReferencePlugin);
                    let invoicePaymentPlugin = InvoicePaymentPlugin as CellPlugin<unknown, unknown>;
                    _cellPlugins.push(invoicePaymentPlugin);
                }
               
                let paymentDetailsPlugin = PaymentDetailsPlugin as CellPlugin<unknown, unknown>;
                let paymentSummaryPlugin = PaymentSummaryPlugin as CellPlugin<unknown, unknown>;
                let accountDetailsPlugin = AccountDetailsPlugin as CellPlugin<unknown, unknown>;
                let billingDetailsPlugin = BillingDetailsPlugin as CellPlugin<unknown, unknown>;
                let multiSearchPlugin = MultiSearchPlugin as CellPlugin<unknown, unknown>;
                let multiSearchResultsPlugin = MultiSearchResultsPlugin as CellPlugin<unknown, unknown>;
                let nericSearchResultsPlugin = NericSearchResultsPlugin as CellPlugin<unknown, unknown>;
                let forsythSearchResultsPlugin = ForsythSearchResultsPlugin as CellPlugin<unknown, unknown>;
                let larimerSearchResultsPlugin =  LarimerSearchResultsPlugin as CellPlugin<unknown, unknown>;
                let loganSearchResultsPlugin = LoganSearchResultsPlugin as CellPlugin<unknown, unknown>;
                let mathewsSearchResultsPlugin = MathewsSearchResultsPlugin as CellPlugin<unknown, unknown>;
                let stCharlesResultsPlugin = StCharlesSearchResultsPlugin as CellPlugin<unknown, unknown>;
                let pikeSearchResultsPlugin = PikeSearchResultsPlugin as CellPlugin<unknown, unknown>;
                let itemShoppingCartPlugin = ItemShoppingCartPlugin as CellPlugin<unknown, unknown>;
                let stCharlesShoppingCartPlugin = StCharlesShoppingCartPlugin as CellPlugin<unknown, unknown>;
                let forsythShoppingCartPlugin = ForsythShoppingCartPlugin as CellPlugin<unknown, unknown>;
                let loganShoppingCartPlugin = LoganShoppingCartPlugin as CellPlugin<unknown, unknown>;
                let larimerShoppingCartPlugin = LarimerShoppingCartPlugin as CellPlugin<unknown, unknown>;
                let mathewsShoppingCartPlugin = MathewsShoppingCartPlugin as CellPlugin<unknown, unknown>;
                let pikeShoppingCartPlugin = PikeShoppingCartPlugin as CellPlugin<unknown, unknown>;
                let modalDetailPlugin = ModalDetailPlugin as CellPlugin<unknown, unknown>;
                let formInputTextboxPlugin = FormInputTextboxPlugin as CellPlugin<unknown, unknown>;
                let orderLineCartPlugin = OrderLineCartPlugin as CellPlugin<unknown, unknown>;

                _cellPlugins.push(paymentDetailsPlugin);
                _cellPlugins.push(paymentSummaryPlugin);
                _cellPlugins.push(accountDetailsPlugin);
                _cellPlugins.push(billingDetailsPlugin);
                _cellPlugins.push(formInputTextboxPlugin);
                _cellPlugins.push(multiSearchPlugin);
                _cellPlugins.push(modalDetailPlugin);
                _cellPlugins.push(multiSearchResultsPlugin);
                _cellPlugins.push(nericSearchResultsPlugin);
                _cellPlugins.push(forsythSearchResultsPlugin);
                _cellPlugins.push(larimerSearchResultsPlugin);
                _cellPlugins.push(loganSearchResultsPlugin);
                _cellPlugins.push(mathewsSearchResultsPlugin);
                _cellPlugins.push(stCharlesResultsPlugin);
                _cellPlugins.push(pikeSearchResultsPlugin);
                _cellPlugins.push(itemShoppingCartPlugin);
                _cellPlugins.push(stCharlesShoppingCartPlugin);
                _cellPlugins.push(forsythShoppingCartPlugin);
                _cellPlugins.push(loganShoppingCartPlugin);
                _cellPlugins.push(larimerShoppingCartPlugin)
                _cellPlugins.push(mathewsShoppingCartPlugin);
                _cellPlugins.push(pikeShoppingCartPlugin);
                _cellPlugins.push(orderLineCartPlugin);                
            } else if (webPage.webPageType === WebPageEnum.Landing) {
                let navigationLinkPlugin = NavigationLinkPlugin as CellPlugin<unknown, unknown>;
                
                _cellPlugins.push(navigationLinkPlugin);
            } else if (webPage.webPageType === WebPageEnum.Account) {
                let userPaymentMethodsPlugin = UserPaymentMethodsPlugin as CellPlugin<unknown, unknown>;
                let itemSearchPlugin = ItemSearchPlugin as CellPlugin<unknown, unknown>;
                let userScheduledPaymentsPlugin = UserScheduledPaymentsPlugin as CellPlugin<unknown, unknown>;
                let navigationMenuPlugin = NavigationMenuPlugin as CellPlugin<unknown, unknown>;

                _cellPlugins.push(userPaymentMethodsPlugin);
                _cellPlugins.push(itemSearchPlugin);
                _cellPlugins.push(userScheduledPaymentsPlugin);
                _cellPlugins.push(navigationMenuPlugin);
            }

            cardPlugin.cellPlugins = _cellPlugins;
            setCellPlugins(_cellPlugins);

            if (webPage.contentId !== '00000000-0000-0000-0000-000000000000') {
                dispatch(getContentAction(webPage.contentId, actionToken));
            }

            setEditorReady(true);
        }
    }, [webPage]);   

    useEffect(() => {
        if (cmsActionResult && cmsActionResult.result) {
            if (cmsActionResult.type === SAVE_CMS_CONTENT_REQUEST && cmsActionResult.token === actionToken) {
                if (cmsActionResult.result === SAVE_CMS_CONTENT_SUCCESS) {
                    if (webPage?.contentId && webPage.contentId === '00000000-0000-0000-0000-000000000000') {
                        webPage.contentId = content.msbId!;
                        dispatch(saveWebPageAction(webPage, actionToken));
                    }
                    dispatch(sendSuccessToastAction("Layout has been successfully saved."));
                } else if (cmsActionResult.result === SAVE_CMS_CONTENT_FAILURE) {
                    dispatch(sendErrorToastAction("Layout could not be updated."));
                }
            }
        }
    }, [cmsActionResult]);  

    useEffect(() => {
        if (content && webPage) {
            if (webPage.contentId === content.msbId!) {
                for (let x = 0; x < content.revisions.length;x++) {
                    if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                        let revision = content.revisions[x];
                        let value = JSON.parse(revision.value);
                        setValue(value);
                        break;
                    }
                }
            }
        }
    }, [webPage, content]);   

    const handleContentChange = (value: Value) => {
        setValue(value);
    }

    const handleSave = () => {       
        if (!content || webPage.contentId !== content.msbId) {
            content = new Content();
            content.clientId = client.msbId!;
            content.title = "webPage_" + webPage.webPageType;
        }

        let revision: Revision | null = null;

        for (let x = 0; x < content.revisions.length;x++) {
            if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                revision = content.revisions[x];
                break;
            }
        }

        if (revision === null) {
            revision = new Revision();
            revision.statusEnum = StatusEnum.Draft;
            content.revisions.push(revision);
        }

        revision.value = JSON.stringify(value);
        dispatch(saveContentAction(content, actionToken));
    } 

    const handleClose = (event: any) => {
       dispatch(clearContentAction(actionToken));
       setCellPlugins(new Array<CellPlugin>());
       setEditorReady(false);
       setRedirect(Routes.WebPaymentChannel.path);
    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() !== false) {
        }
        setValidated(true);
    };

    const renderEditor = () => {
        if (editorReady) {
            return (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Editor cellPlugins={cellPlugins} value={value} onChange={handleContentChange} />
                </Form>
            )
        } else {
            return (<></>)
        }
    }

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <EditorPageHeader onSave={handleSave} onClose={handleClose} />
                <Card style={{marginTop:"12px"}}>
                    {renderEditor()}
                </Card>
            </>
        );
      }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.paymentChannelWebApplication.isFetching,
        isSaving: state.paymentChannelWebApplication.isSaving,
        webPage: state.paymentChannelWebApplication.webPage,
        webApplication: state.paymentChannelWebApplication.webApplication,
        actionResult: state.paymentChannelWebApplication.actionResult,
        cmsActionResult: state.cms.actionResult,
        content: state.cms.content,
        client: state.clients.client
    };
};

export default connect(mapStateToProps)(WebPaymentChannePageEditor);
