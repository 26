import React, { useState } from "react";
import { userActivityFormSchema } from './components/AdvancedSearchColumns';
import Report from './components/Report'
import { Redirect } from 'react-router-dom';
import { Routes } from "../../routes";
import PageSectionContainer from "../../components/layout/PageSectionContainer";
import PageHeader from "../../components/layout/PageHeader";
import { Crumb } from "../../models/Crumb";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import AdvancedSearch from "./components/AdvancedSearch";
import Table from "../../components/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { ReportType } from "../../models/Reports";

const UserActivityReport = () => {
  const [redirect, setRedirect] = useState<string>("");
  const reportTitle = 'User Activity Report';
  const reportType = ReportType.UserActivity;
  const transactionType = 0;


  const StatusFormatter = (cell: any, row: any) => {

    if (cell === 'Active') {
      return (
        <div>
          <svg height="10" width="10">
            <circle cx="5" cy="5" r="4" stroke="" strokeWidth="1" fill="#52C41A"></circle>
          </svg> Active
        </div>
      )
    }
    else if (cell === 'Locked') {
      return (
        <div>
          <svg height="10" width="10">
            <circle cx="5" cy="5" r="4" stroke="" strokeWidth="1" fill="#F5222D"></circle>
          </svg> Locked
        </div>
      )
    }
    else {
      return (
        <div>
          <svg height="10" width="10">
            <circle cx="5" cy="5" r="4" stroke="" strokeWidth="1" fill="#D9D9D9"></circle>
          </svg> Deleted
        </div>
      )
    }

  }

  const viewDetailsFormatter = (cell: any, row: any) => {
    return (
      <span><a onClick={() => console.log("View Details User Activity")}>View Details</a></span>
    )
  }

  const data = [
    { "transactionId": "NAV188888", "firstName": "Danice", "lastName": "Doe", "paymentChannel": "New London Tax WEB", "status": "Active",  "department": "New Londong Tax" },
    { "transactionId": "CLI230585", "firstName": "Kaleigh", "lastName": "Bell", "paymentChannel": "Ken Duncan Payment Processing QP", "status": "Deleted", "department": "Ken Duncan Payment Processing" },
    { "transactionId": "NAV531675", "firstName": "Susie", "lastName": "Warren", "paymentChannel": "Ken Duncan Payment Processing QP", "status": "Locked", "department": "Ken Duncan Payment Processing" },
    { "transactionId": "CLI230585", "firstName": "David", "lastName": "Murphy", "paymentChannel": "Nashville Public Library WEB", "status": "Active", "department": "Nashville Public Library" },
    { "transactionId": "NAV230585", "firstName": "Devin", "lastName": "Cooper", "paymentChannel": "Fulton County Treasurer WEB", "status": "Deleted", "department": "Fulton County Treasurer" },
    { "transactionId": "CLI531675", "firstName": "Andrew", "lastName": "Williamson", "paymentChannel": "Nashville Metro Library QP", "status": "Locked", "department": "Nashville Metro Library" },
    { "transactionId": "NAV230585", "firstName": "Orlondo", "lastName": "Edwards", "paymentChannel": "Edison Tax", "status": "Active", "department": "Edison Tax" },
    { "transactionId": "CLI531675", "firstName": "Bradley", "lastName": "Fisher", "paymentChannel": "Combined Locks Treasurer WEB", "status": "Deleted", "department": "Combined Locks Treasurer" },
    { "transactionId": "NAV531675", "firstName": "Thor", "lastName": "Fox", "paymentChannel": "Chesterfield County ELM QP", "status": "Locked", "department": "Chesterfield County ELM" },
    { "transactionId": "NAV230585", "firstName": "Darlene", "lastName": "Robertson", "paymentChannel": "Munidex Glen Rock Tax WEB", "status": "Locked", "department": "Munidex Glen Rock Tax" },
    { "transactionId": "CLI531675", "firstName": "Jacob", "lastName": "Paul", "paymentChannel": "Munidex Glen Rock Tax WEB", "status": "Active", "department": "Munidex Glen Rock Tax" }
  ];

  const doToolbar = () => {
    if (data && data.length > 0) {
        return (
            <div className="flexContainer">
                <div>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary">
                            <FontAwesomeIcon icon={faCog} size="sm" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item >Configure report table columns</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div>
                    <Dropdown>
                        <Dropdown.Toggle className="approvalAction" variant="outline-secondary">
                            <FontAwesomeIcon icon={faArrowToBottom} size="sm" />
                            Download Summary
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                                <Dropdown.Item> PDF File </Dropdown.Item>
                                <Dropdown.Item> CSV File </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        )
    } else {
        return (<></>);
    }
}


  const columns = [
    {
      dataField: 'msbId',
      text: 'ID',
      editable: false,
      sort: false,
      hidden: true,
      configurable: false
  },
    {
      dataField: 'department',
      text: 'Department',
      editable: false,
      configurable: true
    }, {
      dataField: 'paymentChannel',
      text: 'Payment Channel',
      editable: false,
      configurable: true
    }, {
      dataField: 'transactionId',
      text: 'ID',
      editable: false,
      configurable: true
    }, {
      dataField: 'firstName',
      text: 'First name',
      editable: false,
      configurable: true
    }, {
      dataField: 'lastName',
      text: 'Last name',
      editable: false,
      configurable: true
    }, {
      dataField: 'status',
      text: 'Status',
      editable: false,
      formatter: StatusFormatter,
      configurable: true
    },
    {
      dataField: '',
      text: 'Actions',
      editable: false,
      formatter: viewDetailsFormatter,
      configurable: true
    }
  ];
  if (redirect != "") {
    return (<Redirect push to={redirect} />)
  } else {
    return (
      <>
        {/*
        <PageSectionContainer title={`User activity report under construction.`} ></PageSectionContainer>
        */}
        {/*
        <Report
            transactionType={transactionType}
            reportTitle={reportTitle}
            reportType={reportType}
            columns={columns}
            formSchema={userActivityFormSchema} />
        */}



        <PageHeader title={reportTitle} crumbs={new Array<Crumb>()}/>
            <Container fluid className="container-table-search">
                <Row>
                    <Col>
                        <PageSectionContainer>
                            <AdvancedSearch formSchema={columns} transactionType={transactionType} reportType={reportType} isFetching={false} searchReady={false} />
                        </PageSectionContainer>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col>
                        <PageSectionContainer title={`Manage ${reportTitle}`} >
                        <Table
                            keyField="msbId"
                            data={data}
                            columns={columns}
                            //rowStyle={rowStyle}
                            //defaultSorted = { defaultSorted }
                          />
                        </PageSectionContainer>
                    </Col>
                </Row>
            </Container>
      </>
    );
  }
};

export default UserActivityReport;
