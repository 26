import { useEffect, useState } from "react";
import { Col, Row, Form, Button, Container, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { Redirect } from 'react-router-dom';
import { Routes } from "../../routes";
import PageHeader from '../../components/layout/PageHeader';
import RequiredIcon from '../../components/RequiredIcon';
import { Order, OrderLine, PaymentTypeEnum, Item, PaymentCardTypeEnum, PaymentType, PaymentMethodOnFileType } from '../../models/Payment';
import { connect, useDispatch } from "react-redux";
import { IActionResult, IAppState } from '../../redux/storeTypes';
import { sendErrorToastAction, sendSuccessToastAction } from '../../redux/actions/toast';
import { getItemsAction, GET_ITEMS_FAILURE, GET_ITEMS_REQUEST, GET_ITEMS_SUCCESS } from '../../redux/actions/payments/itemManagement';
import {
    calculateOrderAction, CALCULATE_ORDER_REQUEST, CALCULATE_ORDER_SUCCESS, CALCULATE_ORDER_FAILURE,
    processOrderAction, SAVE_ORDER_REQUEST, SAVE_ORDER_SUCCESS, SAVE_ORDER_FAILURE,
} from '../../redux/actions/payments/orderManagement';
import { BusinessTypeEnum, Client, Department, IntegrationType, PaymentChannel, PaymentChannelTypeEnum, Terminal } from "../../models/Client";
import { User } from "../../models/User";
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import Table from '../../components/Table';
import { Crumb } from "../../models/Crumb";
import CurrencyInput from "../../components/currency/CurrencyInput";
import PaymentSummary from "./components/PaymentSummary";
import BillingDetails from "./components/BillingDetails";
import CreditCardPaymentManual from "./components/CreditCardPaymentManual";
import CreditCardPaymentTerminal from "./components/CreditCardPaymentTerminal";
import ECheckPayment from "./components/ECheckPayment";
import OrderConfirmationModal from "./components/OrderConfirmationModal";
import { CurrencyFormatter } from "../../components/Formatters";
import { TylerEagleRequest, TylerEagleCancel } from "../../models/TylerEagleIntegration";
import TylerEaglePaymentReference from "./components/TylerEaglePaymentReference";
import { POST_TYLER_EAGLE_CANCEL_REQUEST, POST_TYLER_EAGLE_CANCEL_SUCCESS, postTylerEagleCancel, POST_TYLER_EAGLE_CANCEL_FAILURE, setTylerEagleResponseAction } from "../../redux/actions/tylerEagleIntegration";
import { saveCurrentUserPreferencesAction } from "../../redux/actions/auth";
import { v4 as uuidv4 } from 'uuid';
import { getPaymentChannelAction, GET_PAYMENTCHANNEL_FAILURE, GET_PAYMENTCHANNEL_REQUEST, GET_PAYMENTCHANNEL_SUCCESS } from "../../redux/actions/clients/paymentChannels";
import { getDepartmentAction, GET_DEPARTMENT_FAILURE, GET_DEPARTMENT_REQUEST, GET_DEPARTMENT_SUCCESS } from "../../redux/actions/clients/departments";
import SupplementalFields from "./components/SupplementalFields";
import PMoFPayment from "./components/PMoFPayment";
import Switch from "react-switch";
import ReactPlaceholder from 'react-placeholder';
import { BankAccountType } from "../../models/Wallets";

interface IPointOfSaleProps {
    isSaving: boolean,
    items: Array<Item>,
    client: Client,
    department: Department,
    paymentChannel: PaymentChannel,
    currentUser: User,
    calculatedOrder: Order,
    errorMessage: string,
    actionResult: IActionResult,
    actionClient: IActionResult,
    actionItem: IActionResult,
    siteKey: string,

    tylerEagleRequest: TylerEagleRequest,
    tylerEagleCancel: TylerEagleCancel,
    tylerEagleActionResult: IActionResult,
}

const PointOfSale = ({ isSaving, items, actionResult, actionItem, actionClient, client, department, paymentChannel, currentUser, calculatedOrder, errorMessage, tylerEagleRequest, tylerEagleCancel, tylerEagleActionResult, siteKey }: IPointOfSaleProps) => {
    const dispatch = useDispatch();
    const actionToken = "PointOfSale";
    const [redirect, setRedirect] = useState<string>("");
    const [validated, setValidated] = useState(false);
    const [order, setOrder] = useState<Order>(new Order());
    const [isCalCulating, setIsCalCulating] = useState(false);
    const [isFetchingDepartment, setIsFetchingDepartment] = useState(false);
    const [isFetchingPaymentChannel, setIsFetchingPaymentChannel] = useState(false);
    const [isFetchingItems, setIsFetchingItems] = useState(false);
    const [isLocalSaving, setIsLocalSaving] = useState<boolean>(false);
    const [orderCalCulationError, setOrderCalculationError] = useState(false);
    const [paymentErrorMessage, setPaymentErrorMessage] = useState("");
    const [saleItems, setSaleItems] = useState<Array<Item>>(new Array<Item>());

    const [terminals, setTerminals] = useState<Array<Terminal>|undefined>();

    const [paymentType, setPaymentType] = useState<PaymentTypeEnum>(PaymentTypeEnum.Unknown);
    const [paymentCardType, setPaymentCardType] = useState<PaymentCardTypeEnum>(PaymentCardTypeEnum.Unknown);

    const [currentProductItemMsbId, setCurrentProductItemMsbId] = useState<string>('');
    const [productItemInValid, setProductItemInValid] = useState<boolean>(false);

    const [currentProductReference, setCurrentProductReference] = useState('');

    const [currentProductPrice, setCurrentProductPrice] = useState<string>();
    const [productPriceInValid, setProductPriceInValid] = useState(false);

    const [currentProductQty, setCurrentProductQty] = useState(1);
    const [productQtyInValid, setProductQtyInValid] = useState(false);

    const [removeItem, setRemoveItem] = useState("");

    const [orderLines, setOrderLines] = useState<Array<OrderLine>>([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    
    const [userPaymentChannels, setUserPaymentChannels] = useState<Array<PaymentChannel>>([]);
    const [tylerEagleFormRedirectReady, setTylerEagleFormRedirectReady] = useState<boolean>(false);
    const [tylerEagleRedirectError, setTylerEagleRedirectError] = useState<boolean>(false);

    const [paymentMethods, setPaymentMethods] = useState<Array<{ value: PaymentTypeEnum, name: string }>>([]);

    const [savePMoF, setSavePMoF] = useState<boolean>(false);
    const [referencePMoF, setReferencePMoF] = useState("");

    useEffect(() => {
        if (client) {
            var _userPaymentChannels = client.departments.reduce((allPaymentChannels, department) => {
                let posChannels: any;
                if (currentUser?.userDepartments.some(userDepartments => department.msbId === userDepartments.departmentMSBId)) {
                    posChannels = (department.paymentChannels || []).filter(channel => channel.isActive && channel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.PointOfSale]);
                } else {
                    posChannels = [];
                }
                return allPaymentChannels.concat(posChannels);
            }, new Array<PaymentChannel>());

            if (tylerEagleRequest) {
                _userPaymentChannels = _userPaymentChannels.filter(_ => _.integrationType && _.integrationType === IntegrationType[IntegrationType.TylerEagle]);
                if (_userPaymentChannels.length === 1) {
                    let _paymentChannel = _userPaymentChannels[0];
                    setIsFetchingDepartment(true);
                    setIsFetchingPaymentChannel(true);
                    dispatch(getPaymentChannelAction(_paymentChannel.msbId!, actionToken));
                    dispatch(getDepartmentAction(client.departments.filter(_ => _.id === _paymentChannel.departmentId)[0].msbId!, actionToken));                
                } else {
                    _userPaymentChannels = [];
                }

                //setUserPaymentChannels(_userPaymentChannels);
                let _orderLine = new OrderLine();
                _orderLine.itemReferenceNumber = tylerEagleRequest.transactionID
                _orderLine.itemName = tylerEagleRequest.description;
                _orderLine.unitPrice = Number(tylerEagleRequest.amount);
                _orderLine.quantity = 1;
                let _orderLines = new Array<OrderLine>();
                _orderLines.push(_orderLine);
                setOrderLines(_orderLines);

                var _order = new Order();
                _order.amount = _orderLine.unitPrice;
                _order.totalAmount = _orderLine.unitPrice;
                _order.orderLines = orderLines;
                setOrder(_order);
            } else {
                _userPaymentChannels = _userPaymentChannels.filter(_ => _.integrationType !== IntegrationType[IntegrationType.TylerEagle] as keyof typeof IntegrationType);
                if (_userPaymentChannels.length === 1) {
                    let _paymentChannel = _userPaymentChannels[0];
                    setIsFetchingDepartment(true);
                    setIsFetchingPaymentChannel(true);
                    dispatch(getPaymentChannelAction(_paymentChannel.msbId!, actionToken));
                    dispatch(getDepartmentAction(client.departments.filter(_ => _.id === _paymentChannel.departmentId)[0].msbId!, actionToken));
                }
            }
            
            setUserPaymentChannels(_userPaymentChannels);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]);

    useEffect(() => {
        if (isSaving || isCalCulating) {
            setIsLocalSaving(true);
        }
        if (!isSaving && !isCalCulating) {
            setIsLocalSaving(false);
        }
    }, [isSaving, isCalCulating])

    useEffect(() => {
        if (items) {
            if (items.length === 0) {
                let blindItem = new Item();
                blindItem.msbId = uuidv4();
                blindItem.name = "Blind Payment";
                blindItem.internalReferenceNumber = "";
                items.push(blindItem);
            }
            setSaleItems(items);
            setCurrentProductItemMsbId(items.find(i => i.isTaxed)?.msbId!);
        }
    }, [items]);

    const selectPaymentChannel = (e: any) => {
        setOrderLines(new Array<OrderLine>());
        setPaymentType(PaymentTypeEnum.Unknown);

        let selectedChannel = userPaymentChannels.find(channel => channel.msbId === e.target.value);
        if (selectedChannel) {
            setIsFetchingDepartment(true);
            setIsFetchingPaymentChannel(true);
            let _departmentId = client.departments.filter(_ => _.id === selectedChannel?.departmentId)[0].msbId!;
            dispatch(getDepartmentAction(_departmentId, actionToken));
            dispatch(getPaymentChannelAction(selectedChannel.msbId!, actionToken));
        } else {
        }        
    }

    useEffect(() => {
        let _paymentMethods = new Array<{ value: PaymentTypeEnum, name: string }>();
        if (paymentChannel?.electronicCheckPaymentsEnabled) {
            _paymentMethods.push({
                value: PaymentTypeEnum.ECheck,
                name: 'eCheck Payment'
            });
        }
        if (paymentChannel?.manualEntryPaymentsEnabled) {
            _paymentMethods.push({
                value: PaymentTypeEnum.CreditCardManual,
                name: 'Credit Card Payment - Manual'
            });
        }
        if (paymentChannel?.terminalPaymentsEnabled) {
            _paymentMethods.push({
                value: PaymentTypeEnum.CreditCardTerminal,
                name: 'Credit Card Payment - Terminal'
            });
        }
        if (paymentChannel?.paymentMethodOnFileEnabled) {
            _paymentMethods.push({
                value: PaymentTypeEnum.PMoF,
                name: 'Payment Method on File Payment'
            });
        }
        setPaymentMethods(_paymentMethods);
    }, [paymentChannel]);

    useEffect(() => {
        if (!isFetchingItems && !isFetchingDepartment && !isFetchingPaymentChannel && department && paymentChannel) {
            setIsFetchingItems(true);
            dispatch(getItemsAction(
                client.msbId!,
                department.msbId!,
                paymentChannel.requiresReferenceNumber ? paymentChannel.msbId! : "NO MATCH",
                actionToken
            ));

            let _terminals = paymentChannel.processors.find(p => p.merchantProcessor?.businessType === BusinessTypeEnum.Retail)?.merchantProcessor?.terminals;
            setTerminals(_terminals);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingDepartment, isFetchingPaymentChannel]);

    const selectPaymentType = (e: any) => {
        setPaymentCardType(PaymentCardTypeEnum.Unknown);
        setPaymentType(Number(e.target.value));
    }

    const selectPaymentCardType = (e: any) => {
        setPaymentCardType((e !== undefined) ? Number(e.target.value) : PaymentCardTypeEnum.Unknown);
    }

    const selectPaymentCardTypeByValue = (e: any) => {
        setPaymentCardType((e !== undefined) ? Number(e) : PaymentCardTypeEnum.Unknown);
    }

    useEffect(() => {
        if (paymentMethods.length === 1 && paymentChannel) {
            setPaymentType(paymentMethods[0].value);
        } else {
            setPaymentType(PaymentTypeEnum.Unknown);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentMethods, paymentChannel]);

    useEffect(() => {
        if (currentProductItemMsbId) {
            let _price = saleItems.find((item) => item.msbId === currentProductItemMsbId)!?.salePrice;
            let _reference = saleItems.find((item) => item.msbId === currentProductItemMsbId)!?.internalReferenceNumber || "";
            setCurrentProductPrice(_price.toString());
            setCurrentProductReference(_reference.toString());
        } else {
            setCurrentProductPrice("");
            setCurrentProductReference("");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProductItemMsbId]);

    const addOrderLine = () => {
        let isTypeValid = (currentProductItemMsbId);
        let isPriceValid = 0 < Number(currentProductPrice!);
        let isQtyValid = Number.isInteger(currentProductQty) && 0 < currentProductQty;

        setProductItemInValid(!isTypeValid);
        setProductPriceInValid(!isPriceValid);
        setProductQtyInValid(!isQtyValid);

        if (isTypeValid && isPriceValid && isQtyValid) {
            let _orderLine = new OrderLine();
            _orderLine.itemName = saleItems.find((item) => item.msbId === currentProductItemMsbId)!?.name;
            _orderLine.itemReferenceNumber = currentProductReference.trim();
            _orderLine.unitPrice = Number(currentProductPrice!);
            _orderLine.quantity = currentProductQty;
            _orderLine.itemId = uuidv4();

            setOrderLines(prev => [...prev, _orderLine]);

            setCurrentProductReference("");
            setCurrentProductPrice("");
            setCurrentProductQty(1);
            setCurrentProductItemMsbId("");

            setProductItemInValid(false);
            setProductPriceInValid(false);
            setProductQtyInValid(false);
        }
    }

    useEffect(() => {
        if (department && paymentChannel && paymentType) {
            let _order = new Order();
            _order.methodName = "Sale";
            _order.initiatedBy = `${currentUser.firstName} ${currentUser.lastName}`

            _order.clientMsbId = client.msbId!;
            _order.departmentMsbId = department.msbId!;
            _order.paymentChannelMsbId = paymentChannel.msbId!;

            if (paymentType === PaymentTypeEnum.ECheck) {
                _order.paymentType = PaymentType[PaymentType.ECheck] as keyof typeof PaymentType;
                _order.cardType = PaymentCardTypeEnum.ElectronicCheck;
            } else if (paymentType === PaymentTypeEnum.CreditCardManual && paymentCardType) {
                _order.paymentType = PaymentType[PaymentType.CreditCard] as keyof typeof PaymentType;
                _order.cardType = paymentCardType;
            } else if (paymentType === PaymentTypeEnum.CreditCardTerminal && paymentCardType) {
                _order.paymentType = PaymentType[PaymentType.CreditCard] as keyof typeof PaymentType;
                _order.cardType = paymentCardType;
                _order.isCardPresent = true;
            } else if (paymentType === PaymentTypeEnum.PMoF && referencePMoF) {
                const method = referencePMoF.split(":");
                if (method[1] === BankAccountType[BankAccountType.Checking] || method[1] === BankAccountType[BankAccountType.Savings]) {
                    _order.paymentType = PaymentType[PaymentType.ECheck] as keyof typeof PaymentType;
                    _order.cardType = PaymentCardTypeEnum.ElectronicCheck;
                } else {
                    _order.paymentType = PaymentType[PaymentType.CreditCard] as keyof typeof PaymentType;
                    _order.cardType = PaymentCardTypeEnum[method[1] as keyof typeof PaymentCardTypeEnum];
                }
                _order.isTokenisedPayment = true;
            } else {
                let _amount = 0;
                orderLines.forEach(orderLine => {
                    _amount = _amount + (orderLine.unitPrice * orderLine.quantity);
                });

                setOrder(Object.assign({}, order, {
                    amount: _amount,
                    convenienceFee: 0,
                    totalAmount: _amount
                }));
                return
            }

            let _amount = 0;
            orderLines.forEach(orderLine => {
                _amount = _amount + (orderLine.unitPrice * orderLine.quantity);
            });
            _order.amount = _amount;
            _order.totalAmount = _amount;
            _order.orderLines = orderLines;

            setOrder(_order);

            if (orderLines.length > 0) {
                setIsCalCulating(true);
                dispatch(calculateOrderAction(_order, actionToken));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [department, paymentChannel, paymentType, paymentCardType, orderLines, referencePMoF]);

    useEffect(() => {
        if (removeItem) {
            let _orderLines = orderLines.filter((line) => line.itemId !== removeItem);
            setOrderLines(_orderLines);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeItem])

    const orderLineActionsFormatter = (cell: string, line: any) => {
        return (
            <Button variant="outline-secondary" className="btn-outline-secondary--small" onClick={() => setRemoveItem(line.itemId)}>
                <FontAwesomeIcon icon={faTrash} />
            </Button>
        );
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setPaymentErrorMessage("");
        setIsLocalSaving(true);

        const form = event.currentTarget;
        const isFormValid = form.checkValidity();
        if (!orderCalCulationError && isFormValid && 0 < orderLines.length && ((paymentType === PaymentTypeEnum.ECheck) || (paymentType === PaymentTypeEnum.CreditCardTerminal) || (paymentType === PaymentTypeEnum.CreditCardManual && paymentCardType) || (paymentType === PaymentTypeEnum.PMoF && referencePMoF))) {
            setShowConfirmationModal(true);

            let _order = Object.assign({}, order);
            _order.type = "PointOfSale";
            _order.paymentChannelName = paymentChannel!.name;

            if (paymentType === PaymentTypeEnum.ECheck) {
                _order.nameOnCard = form.elements.eCheckAccountHolderName.value;
                _order.eCheckRoutingNumber = form.elements.eCheckRoutingNumber.value;
                _order.eCheckAccountNumber = form.elements.eCheckAccountNumber.value;
                _order.shouldTokenizeCard = savePMoF;
                if (savePMoF) _order.paymentMethodOnFileType = PaymentMethodOnFileType.BankAccount;
                _order.isImplicitAuthorization = false;
            }
            else if (paymentType === PaymentTypeEnum.CreditCardManual) {
                _order.nameOnCard = form.elements.nameOnCard.value;
                _order.creditCardNumber = form.elements.cardNumber.value;
                _order.expirationDate = form.elements.expirationDate.value;
                _order.cvv = form.elements.cvv.value;
                _order.isMailOrTelephoneOrder = true;
                _order.shouldTokenizeCard = savePMoF;
                if (savePMoF) _order.paymentMethodOnFileType = PaymentMethodOnFileType.Card;
                _order.isImplicitAuthorization = false;
            }
            else if (paymentType === PaymentTypeEnum.CreditCardTerminal) {
                const terminal = terminals?.find(terminal => terminal.msbId === form.elements.terminal.value);
                _order.terminalId = terminal!.msbId!;
                _order.laneId = terminal!.laneId!;
                _order.isCardPresent = true;
                _order.shouldTokenizeCard = savePMoF;
                if (savePMoF) _order.paymentMethodOnFileType = PaymentMethodOnFileType.Card;
                _order.isImplicitAuthorization = false;
                updateCurrentUserPreferencesTerminals(_order.terminalId);
            }
            else if (paymentType === PaymentTypeEnum.PMoF) {
                const method = referencePMoF.split(":");
                _order.paymentMethodOnFileReference = method[0];
                _order.paymentMethodOnFileType = (method[1] === BankAccountType[BankAccountType.Checking] || method[1] === BankAccountType[BankAccountType.Savings]) ? PaymentMethodOnFileType.BankAccount : PaymentMethodOnFileType.Card;
            }

            _order.addressLine1 = form.elements.addressLine1.value;
            _order.addressLine2 = form.elements.addressLine2.value;
            _order.country = form.elements.country.value;
            _order.city = form.elements.city.value;
            _order.state = form.elements?.state?.value || '';
            _order.zip = form.elements.zip.value.replace(/\s+/g, ' ');
            _order.phone = form.elements.phoneNumber.value.replace(/[^0-9]/g, '');
            _order.emailAddress = form.elements.emailAddress.value;

            //start:collect client metadata 
            let clientMetadatakKeyValues: any = {};
            var clientMetadataElements: HTMLInputElement[] = Array.prototype.slice.call(form.elements).filter(isClientMetadata);

            clientMetadataElements.forEach(x => {
                clientMetadatakKeyValues[x.id] = x.value;
            });

            if (tylerEagleRequest) {
                clientMetadatakKeyValues['tylerEagleRequest'] = tylerEagleRequest;
            }

            if (Object.keys(clientMetadatakKeyValues).length > 0) {
                _order.metadata = JSON.parse(JSON.stringify(clientMetadatakKeyValues));
            }
            //end:collect client metadata

            setOrder(_order);
            dispatch(processOrderAction(_order, actionToken));
        } else {
            setIsLocalSaving(false);
        }
        setValidated(true);
    }

    function isClientMetadata(formElement: HTMLInputElement) {
        return formElement.id.startsWith('cmd_');
    }

    const updateCurrentUserPreferencesTerminals = (id: string) => {
        if (!currentUser.preferences) currentUser.preferences = {};
        if (!currentUser.preferences.terminals) currentUser.preferences.terminals = {};
        if (!currentUser.preferences.terminals.terminalId || currentUser.preferences.terminals.terminalId != id) {
            currentUser.preferences.terminals.terminalId = id;
            dispatch(saveCurrentUserPreferencesAction(currentUser, actionToken));
        }
    }

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === CALCULATE_ORDER_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === CALCULATE_ORDER_SUCCESS) {
                    setOrder(Object.assign({}, order, {
                        amount: calculatedOrder.amount,
                        convenienceFee: calculatedOrder.convenienceFee,
                        totalAmount: calculatedOrder.totalAmount
                    }));
                    setOrderCalculationError(false);
                } else if (actionResult.result === CALCULATE_ORDER_FAILURE) {
                    setOrderCalculationError(true);
                }
                setIsCalCulating(false);
            }

            if (actionResult.type === SAVE_ORDER_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_ORDER_SUCCESS) {
                    dispatch(sendSuccessToastAction("Payment successful"));
                    if (paymentChannel.integrationType === IntegrationType[IntegrationType.TylerEagle]) {
                        dispatch(setTylerEagleResponseAction());
                    }
                    setRedirect(Routes.PointOfSaleReceipt.path);
                } else if (actionResult.result === SAVE_ORDER_FAILURE) {
                    dispatch(sendErrorToastAction("Payment failed"));
                    setPaymentErrorMessage(errorMessage);
                    if (errorMessage?.indexOf("The operation was canceled") >= 0) {
                        setTimeout(() => {
                            alert("Note:\n\nThis transaction timed out but may have been authorized.\n\nPlease review the All Transactions report and advise the customer to review their bank activity before attempting another transaction");
                        }, 5000);
                    }
                }
                setShowConfirmationModal(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    useEffect(() => {
        if (actionClient && actionClient.result) {
            if (actionClient.type === GET_DEPARTMENT_REQUEST && actionClient.token === actionToken) {
                if (actionClient.result === GET_DEPARTMENT_SUCCESS) {
                    setIsFetchingDepartment(false);
                } else if (actionClient.result === GET_DEPARTMENT_FAILURE) {
                    setIsFetchingDepartment(false);
                }
            }

            if (actionClient.type === GET_PAYMENTCHANNEL_REQUEST && actionClient.token === actionToken) {
                if (actionClient.result === GET_PAYMENTCHANNEL_SUCCESS) {
                    setIsFetchingPaymentChannel(false);
                } else if (actionClient.result === GET_PAYMENTCHANNEL_FAILURE) {
                    setIsFetchingPaymentChannel(false);
                }
            }
        }
    }, [actionClient]);

    useEffect(() => {
        if (actionItem && actionItem.result) {
            if (actionItem.type === GET_ITEMS_REQUEST && actionItem.token === actionToken) {
                if (actionItem.result === GET_ITEMS_SUCCESS) {
                    setIsFetchingItems(false);
                } else if (actionItem.result === GET_ITEMS_FAILURE) {
                    setIsFetchingItems(false);
                }
            }
        }
    }, [actionItem]);

    const handleCancelPayment = (e: any) => {
        if (tylerEagleRequest) {
            dispatch(postTylerEagleCancel(actionToken));
        } else {
            e.preventDefault();
            window.document.forms[0].reset();
            setValidated(false);
            setPaymentType(PaymentTypeEnum.Unknown);
            setPaymentCardType(PaymentCardTypeEnum.Unknown);
            setOrderLines(new Array<OrderLine>());
            setOrderCalculationError(false);
            setOrder(new Order());
            setCurrentProductPrice("");
            setCurrentProductQty(1);
            setCurrentProductItemMsbId("");
            setPaymentErrorMessage("");
        }
    }

    function tylerEagleCancelRedirect() { return {__html: `<form id="tylerEagleResponseForm"  name="tylerEagleResponseForm" action='https://apps.douglas.co.us/treasurer/financeweb/externalPaymentFailed.jsp' method="POST"><textarea style="display:none" name="ResponseXML"><?xml version=\"1.0\" encoding=\"utf-8\"?><PaymentResponse><ErrorCode>11</ErrorCode><ErrorDescription>Transaction Cancelled</ErrorDescription></PaymentResponse></textarea></form>`}; };

    useEffect(() => {
        if (tylerEagleFormRedirectReady) {
          setTimeout(function (){
            let htmlFormElement = document!.getElementById("tylerEagleResponseForm")! as HTMLFormElement;
            htmlFormElement.submit();
          }, 250);
        }
      }, [tylerEagleFormRedirectReady]);

    useEffect(() => {
        if (tylerEagleActionResult && tylerEagleActionResult.result) {
            if (tylerEagleActionResult.type === POST_TYLER_EAGLE_CANCEL_REQUEST) {
              if (tylerEagleActionResult.result === POST_TYLER_EAGLE_CANCEL_SUCCESS) {
                setTylerEagleFormRedirectReady(true);
              } else if (tylerEagleActionResult.result === POST_TYLER_EAGLE_CANCEL_FAILURE) {
                setTylerEagleRedirectError(true);
              }
            }
        }
    }, [tylerEagleActionResult]);

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Point of Sale", Routes.PointOfSale.path));

        return (
            <>
                <PageHeader title="Point of Sale" crumbs={crumbs} />

                <Container fluid className="container-payment">
                    <>
                        <OrderConfirmationModal
                            paymentType={paymentType}
                            show={showConfirmationModal}
                            setShow={setShowConfirmationModal} />
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Col xl={8} lg={8} md={12} sm={12}>
                                    <PageSectionContainer>
                                        <div className="payment-channel">
                                            <div className="transactionTitle">
                                                <h2>Payment details</h2>
                                                <p>Fill out the form below to perform a payment</p>
                                            </div>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                    <Form.Group controlId="paymentChannel">
                                                        <Form.Label><RequiredIcon />Payment Channel</Form.Label>
                                                        <Form.Control as="select" type="input" required disabled={isSaving || isFetchingDepartment || isFetchingPaymentChannel || userPaymentChannels.length === 1} onChange={selectPaymentChannel}>
                                                            {userPaymentChannels.length > 1 && <option key={"channel_default"} value="">Select Payment Channel</option>}
                                                            {
                                                                userPaymentChannels.map((channel, index) => (
                                                                    <option key={`channel_${channel.msbId}`} value={channel.msbId}>{channel.name}</option>
                                                                ))
                                                            }
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">Please select a Payment Channel.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                    <Col md={6} sm={12}>
                                                        {(isFetchingDepartment || isFetchingPaymentChannel) ?
                                                            <Spinner animation="border" size="sm" style={{ marginTop: 35 }} />
                                                            :
                                                            <Form.Group controlId="paymentType">
                                                                <Form.Label><RequiredIcon />Payment type</Form.Label>
                                                            <Form.Control as="select" type="input" required disabled={!paymentChannel || isSaving || paymentMethods.length === 1} onChange={selectPaymentType} key={paymentChannel?.msbId}>
                                                                    {(!paymentChannel || paymentMethods.length > 1) && <option value="" selected={paymentType === PaymentTypeEnum.Unknown}>Select Payment type</option>}
                                                                    {
                                                                        paymentMethods.map(method => <option value={method.value}>{method.name}</option>)
                                                                    }
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">Please select a Payment type.</Form.Control.Feedback>
                                                            </Form.Group>
                                                        }
                                                </Col>
                                            </Row>

                                            {paymentType === PaymentTypeEnum.ECheck && <ECheckPayment
                                                isFetching={false}
                                                isSaving={isSaving}
                                                isVisible={paymentType === PaymentTypeEnum.ECheck ? true : false}
                                            />}

                                            {paymentType === PaymentTypeEnum.PMoF && <PMoFPayment
                                                isFetching={false}
                                                isSaving={isSaving}
                                                setReferencePMoF={setReferencePMoF}
                                                isVisible={paymentType === PaymentTypeEnum.PMoF ? true : false}
                                            />}

                                            {paymentType === PaymentTypeEnum.CreditCardTerminal && <CreditCardPaymentTerminal
                                                isFetching={false}
                                                isSaving={isSaving}
                                                terminals={terminals}
                                                paymentCardType={paymentCardType}
                                                selectPaymentCardType={selectPaymentCardType}
                                                isVisible={paymentType === PaymentTypeEnum.CreditCardTerminal ? true : false}
                                                terminalId={currentUser?.preferences?.terminals?.terminalId}
                                            />}

                                            {paymentType === PaymentTypeEnum.CreditCardManual && <CreditCardPaymentManual
                                                key={paymentType}
                                                isFetching={false}
                                                isSaving={isSaving}
                                                paymentChannel={paymentChannel}
                                                paymentCardType={paymentCardType}
                                                selectPaymentCardType={selectPaymentCardTypeByValue}
                                                isVisible={paymentType === PaymentTypeEnum.CreditCardManual ? true : false}
                                            />}
                                        </div>
                                        {((paymentChannel?.paymentMethodOnFileEnabled) && ((paymentType === PaymentTypeEnum.CreditCardManual && paymentChannel.creditCardPMoFEnabled) || (paymentType === PaymentTypeEnum.ECheck && paymentChannel.eCheckPMoFEnabled) || (paymentType === PaymentTypeEnum.CreditCardTerminal && paymentChannel.terminalPMoFEnabled))) ?
                                            <>
                                                <div className="toggle-switch">
                                                    <ReactPlaceholder type='text' rows={1} ready={!false} delay={200} showLoadingAnimation={true}>
                                                        <Switch
                                                            onChange={e => setSavePMoF(e)}
                                                            checked={savePMoF}
                                                            onColor={'#0057B6'}
                                                            offColor={'#BEBEBE'}
                                                            handleDiameter={12}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            height={16}
                                                            width={30}
                                                            disabled={isSaving}
                                                            activeBoxShadow={'0 0 0 1px #0057B6'}
                                                        />
                                                    </ReactPlaceholder>
                                                    <span className="toggle-label">Save Payment Method on File</span>
                                                </div>
                                            </>
                                            : <></>
                                        }
                                        <div className="payment-type">
                                            <div className="transactionTitle">
                                                <h2>Order details</h2>
                                                {validated && orderLines.length < 1 && <span style={{ color: '#f5222d' }}>No items in the order.</span>}
                                            </div>

                                            {paymentChannel?.integrationType === IntegrationType[IntegrationType.TylerEagle] ?
                                                <>
                                                <TylerEaglePaymentReference />
                                                </>
                                                :
                                                <>
                                            <Row>
                                                <Col md={5} sm={7}>
                                                    <Form.Group controlId="productType">
                                                        <Form.Label><RequiredIcon />Product type</Form.Label>
                                                        <Form.Control as="select"
                                                            disabled={!paymentType || isSaving || isFetchingItems}
                                                            required={orderLines.length < 1}
                                                            value={currentProductItemMsbId}
                                                            onChange={(e) => setCurrentProductItemMsbId(e.target.value)}
                                                            isInvalid={productItemInValid}
                                                        >
                                                            <option key={0} value="">Select Product type</option>
                                                            {
                                                                saleItems.sort((a: any, b: any) => a.name > b.name ? 1 : -1).map((item, index) => (
                                                                    <option
                                                                        key={index + 1}
                                                                        value={item.msbId}
                                                                    >
                                                                        {`${item.name}${(item.description) ? ' : ' + item.description : ''}`}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">Please select a Product type.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3} sm={5}>
                                                    <Form.Group controlId="reference">
                                                        <Form.Label>Reference</Form.Label>
                                                        <Form.Control as="input" type="text"
                                                            required={orderLines.length < 1}
                                                            placeholder="Reference"
                                                            maxLength={30}
                                                            disabled={!paymentType || isSaving}
                                                            value={currentProductReference}
                                                            onChange={(e) => setCurrentProductReference(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4} sm={12} className="price-qty-add">
                                                    <Form.Group >
                                                        <Form.Label><RequiredIcon />Price</Form.Label>
                                                        <CurrencyInput
                                                            className={productPriceInValid?"form-control is-invalid":"form-control"}  
                                                            required={orderLines.length < 1}
                                                            disabled={!paymentType || isSaving}
                                                            id="price"
                                                            name="price"
                                                            placeholder="$0.00"
                                                            maxLength={10}
                                                            decimalsLimit={2}
                                                            prefix="$"
                                                            value={currentProductPrice}
                                                            onValueChange={(value, name) => setCurrentProductPrice(value)}
                                                            />
                                                        {productPriceInValid?
                                                            <div className="invalid-feedback" style={{display:"block"}}>Please enter a valid price.</div>
                                                            :
                                                            <></>
                                                        }
                                                    </Form.Group>

                                                    <Form.Group controlId="qty">
                                                        <Form.Label><RequiredIcon />Qty</Form.Label>
                                                        <Form.Control as="input" type="number"
                                                            placeholder="0"
                                                            required={orderLines.length < 1}
                                                            //defaultValue={1}
                                                            disabled={!paymentType || isSaving}
                                                            value={currentProductQty}
                                                            onChange={(e) => setCurrentProductQty(Number(e.target.value))}
                                                            isInvalid={productQtyInValid}
                                                        />
                                                        <Form.Control.Feedback type="invalid">Please enter a valid quantity.</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <div style={{ float: "right" }}>
                                                            <Button className="btn-add-item" onClick={() => addOrderLine()} disabled={!paymentType || isSaving}>
                                                                <FontAwesomeIcon icon={faPlus} size="sm" /> Add item
                                                            </Button>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {
                                                    orderLines.length > 0 && (
                                                        <Table
                                                            key={orderLines.length}
                                                            keyField="itemId"
                                                            columns={[
                                                                {
                                                                    dataField: 'itemName',
                                                                    text: 'Product type',
                                                                    editable: false
                                                                }, {
                                                                    dataField: 'itemReferenceNumber',
                                                                    text: 'Reference',
                                                                    editable: false,
                                                                },  {
                                                                    dataField: 'unitPrice',
                                                                    text: 'Price',
                                                                    editable: false,
                                                                    formatter: CurrencyFormatter
                                                                }, {
                                                                    dataField: 'quantity',
                                                                    text: 'Qty',
                                                                    editable: false
                                                                }, {
                                                                    dataField: 'itemId',
                                                                    text: 'Actions',
                                                                    editable: false,
                                                                    formatter: orderLineActionsFormatter
                                                                }
                                                            ]}
                                                            data={orderLines}
                                                        />
                                                    )
                                                }

                                            </Row>
                                            </>
                                        }
                                        </div>

                                        <SupplementalFields
                                            paymentChannel={paymentChannel}
                                        />

                                        <BillingDetails 
                                            isFetching={false}
                                            isSaving={isCalCulating}
                                            isEmailRequired={paymentChannel?.integrationType === IntegrationType[IntegrationType.TylerEagle] || savePMoF}
                                            forceZipRequired={savePMoF}
                                            overrideAvsChecks={false}
                                            paymentType={paymentType}
                                            paymentChannel={paymentChannel}
                                        />
                                    </PageSectionContainer>
                                </Col>

                                <Col xl={4} lg={4} md={12} sm={12}>
                                    <PaymentSummary
                                        isFetching = {false}
                                        isSaving = {isLocalSaving}
                                        order = {order}
                                        department = {department}
                                        orderCalculationError = {orderCalCulationError}
                                        errorMessage = {paymentErrorMessage}
                                        handleCancelPayment = {handleCancelPayment}
                                    />

                                     { tylerEagleRedirectError?
                                    <div className="alert alert-danger mb-0 mt-2 d-flex align-items-start" role="alert">
                                        <FontAwesomeIcon icon={faTimesCircle} size="sm" />System could not redirect back.
                                    </div>
                                    :
                                    <></>
                                    }

                                    {tylerEagleCancel?
                                        <div dangerouslySetInnerHTML={tylerEagleCancelRedirect()} />
                                        :<></>
                                    }
                                </Col>
                            </Row>
                            </Form>
                    </>
                </Container>
            </>
        )
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        items: state.itemManagement.items,
        actionResult: state.orderManagement.actionResult,
        actionClient: state.clients.actionResult,
        actionItem: state.itemManagement.actionResult,
        client: state.clients.client,
        department: state.clients.department,
        paymentChannel: state.clients.paymentChannel,
        isSaving: state.orderManagement.isSaving,
        currentUser: state.auth.currentUser,
        calculatedOrder: state.orderManagement.calculatedOrder,
        errorMessage: state.orderManagement.errorMessage,
        siteKey: state.webAppSettings.siteKey,

        tylerEagleRequest: state.tylerEagleIntegration.tylerEagleRequest,
        tylerEagleCancel: state.tylerEagleIntegration.tylerEagleCancel,
        tylerEagleActionResult: state.tylerEagleIntegration.actionResult,
    };
};

export default connect(mapStateToProps)(PointOfSale);