import { useEffect, useState } from "react";
import { Col, Row, Form, Container, Spinner } from 'react-bootstrap';
import PageHeader from '../../components/layout/PageHeader';
import RequiredIcon from '../../components/RequiredIcon';
import { Order, OrderLine, PaymentTypeEnum, PaymentCardTypeEnum, PaymentType, PaymentMethodOnFileType } from '../../models/Payment';
import { connect, useDispatch } from "react-redux";
import { IActionResult, IAppState } from '../../redux/storeTypes';
import { sendErrorToastAction, sendSuccessToastAction } from '../../redux/actions/toast';
import { processOrderAction, SAVE_ORDER_REQUEST, SAVE_ORDER_SUCCESS, SAVE_ORDER_FAILURE, CALCULATE_ORDER_REQUEST, CALCULATE_ORDER_SUCCESS, CALCULATE_ORDER_FAILURE, calculateOrderAction } from '../../redux/actions/payments/orderManagement';
import { BusinessTypeEnum, Client, Department, PaymentChannel, PaymentChannelTypeEnum, Terminal } from "../../models/Client";
import { User } from "../../models/User";
import { Routes } from "../../routes";
import { Redirect } from 'react-router-dom';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import { Crumb } from "../../models/Crumb";
import PaymentSummary from "./components/PaymentSummary";
import BillingDetails from "./components/BillingDetails";
import CreditCardPaymentTerminal from "./components/CreditCardPaymentTerminal";
import OrderConfirmationModal from "./components/OrderConfirmationModal";
import CurrencyInput from "../../components/currency/CurrencyInput";
import { saveCurrentUserPreferencesAction } from "../../redux/actions/auth";
import { getPaymentChannelAction, GET_PAYMENTCHANNEL_FAILURE, GET_PAYMENTCHANNEL_REQUEST, GET_PAYMENTCHANNEL_SUCCESS } from "../../redux/actions/clients/paymentChannels";
import { getDepartmentAction, GET_DEPARTMENT_FAILURE, GET_DEPARTMENT_REQUEST, GET_DEPARTMENT_SUCCESS } from "../../redux/actions/clients/departments";
import SupplementalFields from "./components/SupplementalFields";
import Switch from "react-switch";
import ReactPlaceholder from 'react-placeholder';

interface ITerminalPay {
    isSaving: boolean,
    client: Client,
    department: Department,
    paymentChannel: PaymentChannel,
    currentUser: User,
    calculatedOrder: Order,
    errorMessage: string,
    actionResult?: IActionResult,
    actionClient?: IActionResult,
    siteKey: string
}

const TerminalPay = ({ isSaving, client, department, paymentChannel, currentUser, calculatedOrder, errorMessage, actionResult, actionClient, siteKey }: ITerminalPay) => {
    const dispatch = useDispatch();
    const actionToken = "TerminalPay";
    const [redirect, setRedirect] = useState<string>("");
    const [validated, setValidated] = useState(false);
    const [order, setOrder] = useState<Order>(new Order());
    const [isCalCulating, setIsCalCulating] = useState(false);
    const [isFetchingDepartment, setIsFetchingDepartment] = useState(false);
    const [isFetchingPaymentChannel, setIsFetchingPaymentChannel] = useState(false);
    const [orderCalCulationError, setOrderCalculationError] = useState(false);
    const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
    const [terminals, setTerminals] = useState<Array<Terminal> | undefined>();
    const [paymentType, setPaymentType] = useState<PaymentTypeEnum>(PaymentTypeEnum.Unknown);
    const [paymentCardType, setPaymentCardType] = useState<PaymentCardTypeEnum>(PaymentCardTypeEnum.Unknown);
    const [amount, setAmount] = useState<number>(0);
    const [orderLine, setOrderLine] = useState(new OrderLine());
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isLocalSaving, setIsLocalSaving] = useState<boolean>(false);
    const [userPaymentChannels, setUserPaymentChannels] = useState<Array<PaymentChannel>>([]);

    const [paymentMethods, setPaymentMethods] = useState<Array<{ value: PaymentTypeEnum, name: string }>>([]);

    const [savePMoF, setSavePMoF] = useState<boolean>(false);

    useEffect(() => {
        if (client) {
            var _userPaymentChannels = client.departments.reduce((allPaymentChannels, department) => {
                let posChannels: any;
                if (currentUser?.userDepartments.some(userDepartments => department.msbId === userDepartments.departmentMSBId)) {
                    posChannels = (department.paymentChannels || []).filter(channel => channel.isActive && channel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.TerminalPay]);
                } else {
                    posChannels = [];
                }
                return allPaymentChannels.concat(posChannels);
            }, new Array<PaymentChannel>());

            if (_userPaymentChannels.length === 1) {
                let _paymentChannel = _userPaymentChannels[0];
                setIsFetchingDepartment(true);
                setIsFetchingPaymentChannel(true);
                dispatch(getPaymentChannelAction(_paymentChannel.msbId!, actionToken));
                dispatch(getDepartmentAction(client.departments.filter(_ => _.id === _paymentChannel.departmentId)[0].msbId!, actionToken));
            }

            setUserPaymentChannels(_userPaymentChannels);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client])

    useEffect(() => {
        if (isSaving || isCalCulating) {
            setIsLocalSaving(true);
        }
        if (!isSaving && !isCalCulating) {
            setIsLocalSaving(false);
        }
    }, [isSaving, isCalCulating])

    const selectPaymentChannel = (e: any) => {
        setOrderLine(new OrderLine());

        let selectedChannel = userPaymentChannels.find(channel => channel.msbId === e.target.value);
        if (selectedChannel) {
            setIsFetchingDepartment(true);
            setIsFetchingPaymentChannel(true);
            let _departmentId = client.departments.filter(_ => _.id === selectedChannel?.departmentId)[0].msbId!;
            dispatch(getDepartmentAction(_departmentId, actionToken));
            dispatch(getPaymentChannelAction(selectedChannel.msbId!, actionToken));
        } else {
        }
    }

    useEffect(() => {
        let _paymentMethods = new Array<{ value: PaymentTypeEnum, name: string }>();
        if (paymentChannel?.terminalPaymentsEnabled) {
            _paymentMethods.push({
                value: PaymentTypeEnum.CreditCardTerminal,
                name: 'Credit Card Payment - Terminal'
            });
        }
        setPaymentMethods(_paymentMethods);
    }, [paymentChannel]);

    useEffect(() => {
        if (!isFetchingDepartment && !isFetchingPaymentChannel && department && paymentChannel) {
            let _terminals = paymentChannel.processors.find(p => p.merchantProcessor?.businessType === BusinessTypeEnum.Retail)?.merchantProcessor?.terminals;
            setTerminals(_terminals);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingDepartment, isFetchingPaymentChannel]);

    const selectPaymentType = (e: any) => {
        setPaymentCardType(PaymentCardTypeEnum.Unknown)
        setPaymentType(Number(e.target.value))
    }

    const selectPaymentCardType = (e: any) => {
        setPaymentCardType((e !== undefined) ? Number(e.target.value) : PaymentCardTypeEnum.Unknown);
    }

    useEffect(() => {
        if (paymentMethods.length === 1 && paymentChannel) {
            setPaymentType(paymentMethods[0].value);
        } else {
            setPaymentType(PaymentTypeEnum.Unknown);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentMethods, paymentChannel]);

    const handleAmountOnBlur = (e: any) => {
        let _orderLine = Object.assign({}, orderLine);
        _orderLine.quantity = 1;
        _orderLine.unitPrice = amount;
        _orderLine.itemName = "Blind Payment";
        setOrderLine(_orderLine);
    }

    const handleAmountOnChange = (value: string|undefined) => {
        setAmount(0);
        if (value) {
            value = value.replace("$ ","");
            if (!isNaN(Number(value))) {
                setAmount(Number(value));
            }
        }
    }

    const handleCancelPayment = (e: any) => {
        e.preventDefault();
        window.document.forms[0].reset();
        setValidated(false);
        setPaymentCardType(PaymentCardTypeEnum.Unknown);
        setOrderLine(new OrderLine());
        setOrderCalculationError(false);
        setPaymentErrorMessage("");
    }

    useEffect(() => {
        if (department && paymentChannel && paymentType && terminals?.length) {
            let _order = new Order();
            _order.methodName = "Sale";
            _order.initiatedBy = `${currentUser.firstName} ${currentUser.lastName}`

            _order.clientMsbId = client.msbId!;
            _order.departmentMsbId = department.msbId!;
            _order.paymentChannelMsbId = paymentChannel.msbId!;

            if (paymentCardType) {
                _order.paymentType = PaymentType[PaymentType.CreditCard] as keyof typeof PaymentType;
                _order.cardType = paymentCardType;
                _order.isCardPresent = true;
            } else {
                setOrder(Object.assign({}, order, {
                    amount: orderLine.unitPrice,
                    convenienceFee: 0,
                    totalAmount: orderLine.unitPrice
                }));
                return
            }

            let _orderLine = Object.assign({}, orderLine);
            _orderLine.quantity = 1;

            _order.amount = orderLine.unitPrice;
            _order.totalAmount = orderLine.unitPrice;
            _order.orderLines = new Array<OrderLine>();
            _order.orderLines.push(_orderLine);

            setOrder(_order);
            setIsCalCulating(true);
            dispatch(calculateOrderAction(_order, actionToken));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [department, paymentChannel, paymentType, paymentCardType, orderLine, terminals]);

    const handleSubmit = (event: any) => {
        event.preventDefault();

        const form = event.currentTarget;
        setPaymentErrorMessage("");
        if (!orderCalCulationError && form.checkValidity() !== false && orderLine.unitPrice > 0 && terminals?.length) {
            setShowConfirmationModal(true);

            let _order = Object.assign({}, order);
            _order.type = "TerminalPay";
            _order.paymentChannelName = paymentChannel!.name;
            _order.paymentType = PaymentType[PaymentType.CreditCard] as keyof typeof PaymentType;

            const terminal = terminals?.find(terminal => terminal.msbId === form.elements.terminal.value);
            _order.terminalId = terminal!.msbId!;
            _order.laneId = terminal!.laneId!;
            _order.isCardPresent = true;
            _order.shouldTokenizeCard = savePMoF;
            if (savePMoF) _order.paymentMethodOnFileType = PaymentMethodOnFileType.Card;
            _order.isImplicitAuthorization = false;
            updateCurrentUserPreferencesTerminals(_order.terminalId);

            _order.addressLine1 = form.elements.addressLine1.value;
            _order.addressLine2 = form.elements.addressLine2.value;
            _order.country = form.elements.country.value;
            _order.city = form.elements.city.value;
            _order.state = form.elements?.state?.value || '';
            _order.zip = form.elements.zip.value.replace(/\s+/g, ' ');;
            _order.phone = form.elements.phoneNumber.value.replace(/[^0-9]/g, '');
            _order.emailAddress = form.elements.emailAddress.value;
            _order.orderLines[0].itemReferenceNumber = form.elements.referenceNumber.value;

            //start:collect client metadata 
            let clientMetadatakKeyValues: any = {};
            var clientMetadataElements: HTMLInputElement[] = Array.prototype.slice.call(form.elements).filter(isClientMetadata);

            clientMetadataElements.forEach(x => {
                clientMetadatakKeyValues[x.id] = x.value;
            });

            if (Object.keys(clientMetadatakKeyValues).length > 0) {
                _order.metadata = JSON.parse(JSON.stringify(clientMetadatakKeyValues));
            }
            //end:collect client metadata

            setOrder(_order);
            dispatch(processOrderAction(_order, actionToken));
        }
        setValidated(true);
    }

    function isClientMetadata(formElement: HTMLInputElement) {
        return formElement.id.startsWith('cmd_');
    }

    const updateCurrentUserPreferencesTerminals = (id: string) => {
        if (!currentUser.preferences) currentUser.preferences = {};
        if (!currentUser.preferences.terminals) currentUser.preferences.terminals = {};
        if (!currentUser.preferences.terminals.terminalId || currentUser.preferences.terminals.terminalId != id) {
            currentUser.preferences.terminals.terminalId = id;
            dispatch(saveCurrentUserPreferencesAction(currentUser, actionToken));
        }
    }

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === CALCULATE_ORDER_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === CALCULATE_ORDER_SUCCESS) {
                    setOrder(Object.assign({}, order, {
                        amount: calculatedOrder.amount,
                        convenienceFee: calculatedOrder.convenienceFee,
                        totalAmount: calculatedOrder.totalAmount
                    }));
                    setOrderCalculationError(false);
                } else if (actionResult.result === CALCULATE_ORDER_FAILURE) {
                    setOrderCalculationError(true);
                }
                setIsCalCulating(false);
            }

            if (actionResult.type === SAVE_ORDER_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_ORDER_SUCCESS) {
                    dispatch(sendSuccessToastAction("Payment successful"));
                    setRedirect(Routes.TerminalPayReceipt.path);
                } else if (actionResult.result === SAVE_ORDER_FAILURE) {
                    dispatch(sendErrorToastAction("Payment failed"));
                    setPaymentErrorMessage(errorMessage)
                    if (errorMessage?.indexOf("The operation was canceled") >= 0) {
                        setTimeout(() => {
                            alert("Note:\n\nThis transaction timed out but may have been authorized.\n\nPlease review the All Transactions report and advise the customer to review their bank activity before attempting another transaction");
                        }, 5000);
                    }   
                }
                setShowConfirmationModal(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    useEffect(() => {
        if (actionClient && actionClient.result) {
            if (actionClient.type === GET_DEPARTMENT_REQUEST && actionClient.token === actionToken) {
                if (actionClient.result === GET_DEPARTMENT_SUCCESS) {
                    setIsFetchingDepartment(false);
                } else if (actionClient.result === GET_DEPARTMENT_FAILURE) {
                    setIsFetchingDepartment(false);
                }
            }
            if (actionClient.type === GET_PAYMENTCHANNEL_REQUEST && actionClient.token === actionToken) {
                if (actionClient.result === GET_PAYMENTCHANNEL_SUCCESS) {
                    setIsFetchingPaymentChannel(false);
                } else if (actionClient.result === GET_PAYMENTCHANNEL_FAILURE) {
                    setIsFetchingPaymentChannel(false);
                }
            }
        }
    }, [actionClient]);

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Terminal Pay", Routes.QuickPay.path));

        return (
            <>
                <PageHeader title="Terminal Pay" crumbs={crumbs} />

                <Container fluid className="container-payment">
                    <OrderConfirmationModal
                        paymentType={paymentType}
                        show={showConfirmationModal}
                        setShow={setShowConfirmationModal} />
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Col xl={8} lg={8} md={12} sm={12} className="mb-4">
                                <PageSectionContainer>
                                    <div className="container-payment-form">
                                        <div className="transactionTitle">
                                            <h2>Payment details</h2>
                                            <p>Fill out the form below to perform a payment</p>
                                        </div>
                                        <div className="payment-channel">
                                            <Row>
                                                <Col md={6} sm={12}>
                                                    <Form.Group controlId="paymentChannel">
                                                        <Form.Label><RequiredIcon />Payment Channel</Form.Label>
                                                        <Form.Control as="select" type="input" required disabled={isSaving || isFetchingDepartment || isFetchingPaymentChannel || userPaymentChannels.length === 1} onChange={selectPaymentChannel}>
                                                            {userPaymentChannels.length > 1 && <option key={"channel_default"} value="">Select Payment Channel</option>}
                                                            {
                                                                userPaymentChannels.map((channel, index) => (
                                                                    <option key={`channel_${channel.msbId}`} value={channel.msbId}>{channel.name}</option>
                                                                ))
                                                            }
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">Please select a Payment Channel.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                    <Col md={6} sm={12}>
                                                        {(isFetchingDepartment || isFetchingPaymentChannel) ?
                                                            <Spinner animation="border" size="sm" style={{ marginTop: 35 }} />
                                                            :
                                                            <Form.Group controlId="paymentType">
                                                                <Form.Label><RequiredIcon />Payment type</Form.Label>
                                                                <Form.Control as="select" type="input" required disabled={!paymentChannel || isSaving || paymentMethods.length === 1} onChange={selectPaymentType} key={paymentChannel?.msbId}>
                                                                    {(!paymentChannel || paymentMethods.length > 1) && <option key={0} value="">Select Payment type</option>}
                                                                    {
                                                                        paymentMethods.map((method, index) => <option key={index + 1} value={method.value}>{method.name}</option>)
                                                                    }
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">Please select a Payment Type.</Form.Control.Feedback>
                                                            </Form.Group>
                                                        }
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="payment-type">
                                            <Row>
                                                <Col md={6} sm={12}>
                                                    <Form.Group controlId="referenceNumber">
                                                        <Form.Label>{paymentChannel?.requiresReferenceNumber && <RequiredIcon />}Reference number</Form.Label>
                                                        <Form.Control type="input" placeholder="Enter Reference number" maxLength={50} disabled={isSaving} required={paymentChannel?.requiresReferenceNumber} />
                                                        <Form.Control.Feedback type="invalid">Please enter a valid Reference number.</Form.Control.Feedback>
                                                        <Form.Text className="text-muted">Maximum of 50 characters</Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group controlId="amount">
                                                        <Form.Label><RequiredIcon />Amount</Form.Label>
                                                        <CurrencyInput
                                                            required
                                                            className="form-control"
                                                            id="amount"
                                                            name="amount"
                                                            placeholder="$ 0.00"
                                                            maxLength={10}
                                                            decimalsLimit={2}
                                                            prefix="$ "
                                                            onBlur={handleAmountOnBlur}
                                                            onValueChange={(value, name) => handleAmountOnChange(value)}
                                                            />
                                                        <Form.Control.Feedback type="invalid">Please enter an Amount.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            {paymentType === PaymentTypeEnum.CreditCardTerminal && <CreditCardPaymentTerminal
                                                isFetching={false}
                                                isSaving={isSaving}
                                                terminals={terminals}
                                                paymentCardType={paymentCardType}
                                                selectPaymentCardType={selectPaymentCardType}
                                                isVisible={paymentType === PaymentTypeEnum.CreditCardTerminal ? true : false}
                                                terminalId={currentUser?.preferences?.terminals?.terminalId}
                                            />}
                                        </div>

                                        {((paymentType === PaymentTypeEnum.CreditCardTerminal && paymentChannel.terminalPMoFEnabled)) ?
                                            <>
                                                <div className="toggle-switch">
                                                    <ReactPlaceholder type='text' rows={1} ready={!false} delay={200} showLoadingAnimation={true}>
                                                        <Switch
                                                            onChange={e => setSavePMoF(e)}
                                                            checked={savePMoF}
                                                            onColor={'#0057B6'}
                                                            offColor={'#BEBEBE'}
                                                            handleDiameter={12}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            height={16}
                                                            width={30}
                                                            disabled={isSaving}
                                                            activeBoxShadow={'0 0 0 1px #0057B6'}
                                                        />
                                                    </ReactPlaceholder>
                                                    <span className="toggle-label">Save Payment Method on File</span>
                                                </div>
                                            </>
                                            : <></>
                                        }

                                        <SupplementalFields
                                            paymentChannel={paymentChannel}
                                        />

                                        <BillingDetails 
                                            isFetching={false}
                                            isSaving={isCalCulating}
                                            isEmailRequired={savePMoF}
                                            forceZipRequired={savePMoF}
                                            overrideAvsChecks={false}
                                            paymentType={paymentType}
                                            paymentChannel={paymentChannel}
                                        />
                                    </div>
                                </PageSectionContainer>
                            </Col>
                            <Col xl={4} lg={4} md={12} sm={12}>
                                <PaymentSummary
                                    isFetching={false}
                                    isSaving={isLocalSaving}
                                    order={order}
                                    department={department}
                                    orderCalculationError={orderCalCulationError}
                                    errorMessage={paymentErrorMessage}
                                    handleCancelPayment={handleCancelPayment}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </>
        )
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.orderManagement.isSaving,
        client: state.clients.client,
        department: state.clients.department,
        paymentChannel: state.clients.paymentChannel,
        currentUser: state.auth.currentUser,
        calculatedOrder: state.orderManagement.calculatedOrder,
        errorMessage: state.orderManagement.errorMessage,
        actionResult: state.orderManagement.actionResult,
        actionClient: state.clients.actionResult,
        siteKey: state.webAppSettings.siteKey
    };
};

export default connect(mapStateToProps)(TerminalPay);