/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Button, Card, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import RequiredIcon from '../../../../../components/RequiredIcon';
import { PaymentChannelTypeEnum, PaymentChannel, Department, WebChannelTypeEnum, IntegrationType, SubMerchantAcceptedCardTypeEnum, Client, CustomerFundingType, SignaturePromptEnum } from '../../../../../models/Client';
import { addPaymentChannelAction, getPaymentChannelAction, savePaymentChannelAction } from '../../../../../redux/actions/clients/paymentChannels';
import FormActions, { IFormActionProps } from '../FormActions';
import Switch from "react-switch";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { sendErrorToastAction, sendSuccessToastAction } from "../../../../../redux/actions/toast";
import Select from 'react-select';
import _ from "lodash";
import { IActionResult, IAppState } from "../../../../../redux/storeTypes";
import { ProcessorTypeEnum } from "../../../../../models/Order";
import { CurrencyInput } from "../../../../../components/currency/CurrencyInput";
import { faTrash } from "@fortawesome/free-solid-svg-icons";


export interface IDepartmentPaymentChannelsFormProps {
    client: Client,
    department: Department,
    paymentChannel: PaymentChannel,
    paymentChannelMsbId?: string,
    actionResult: IActionResult,
    actionToken: string,
    formActionProps: IFormActionProps
}

const DepartmentPaymentChannelsForm = ({ client, department, paymentChannel, paymentChannelMsbId, actionResult, actionToken, formActionProps }: IDepartmentPaymentChannelsFormProps) => {
    class AcceptedCardTypes {
        value: SubMerchantAcceptedCardTypeEnum = SubMerchantAcceptedCardTypeEnum.None;
        label: string = "";
    }

    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);

    const [formAddAction, setFormAddAction] = useState(formActionProps?.customProps.submitButtonDisplay === 'Add');

    const [isSaving, setIsSaving] = useState(formActionProps?.showSpinner);
    const [isFetching, setIsFetching] = useState(formActionProps?.disabled);

    const [isActive, setIsActive] = useState(false);
    const [isISV, setIsISV] = useState(false);
    const [isLevelThree, setLevelThree] = useState(false);
    const [isAllowECheckPayments, setIsAllowECheckPayments] = useState(false);
    const [isAllowCreditCardPayments, setIsAllowCreditCardPayments] = useState(false);
    const [isCVVRequired, setIsCVVRequired] = useState(false);
    const [isAVSRequired, setIsAVSRequired] = useState(false);
    const [isReferenceNumberRequired, setIsReferenceNumberRequired] = useState(false);
    const [isAllowManualEntryPayments, setIsAllowManualEntryPayments] = useState(false);
    const [isAllowTerminalPayments, setIsAllowTerminalPayments] = useState(false);
    const [isAllowPayPalPayments, setIsAllowPayPalPayments] = useState(false);
    const [isCheckForDuplicateTransactions, setIsCheckForDuplicateTransactions] = useState(false);
    const [enableAutoReceipts, setEnableAutoReceipts] = useState(false);

    const [isAllowPMoFPayments, setIsAllowPMoFPayments] = useState(false);
    const [isAllowCreditCardPMoF, setIsAllowCreditCardPMoF] = useState(false);
    const [isAllowECheckPMoF, setIsAllowECheckPMoF] = useState(false);
    const [isAllowTerminalPMoF, setIsAllowTerminalPMoF] = useState(false);

    const [embeddedCheckoutIsEnabled, setEmbeddedCheckoutIsEnabled] = useState(false);
    const [embeddedLinksIsEnabled, setEmbeddedLinksIsEnabled] = useState(false);
    const [tokenizationIsEnabled, setTokenizationIsEnabled] = useState(false);

    const [paymentChannelType, setPaymentChannelType] = useState<PaymentChannelTypeEnum>(PaymentChannelTypeEnum.Select);
    const [webChannelType, setWebChannelType] = useState<WebChannelTypeEnum>(WebChannelTypeEnum.None);
    const [integrationType, setIntegrationType] = useState<IntegrationType>(IntegrationType.None);
    const [customerFundingType, setCustomerFundingType] = useState<CustomerFundingType>(CustomerFundingType.Unknown);
    const [webApiKey, setWebApiKey] = useState("");
    const [paymentChannelName, setPaymentChannelName] = useState("");

    const [showIsActive, setShowIsActive] = useState(false);
    const [showRequiredFeatures, setShowRequiredFeatures] = useState(false);
    const [showAllowECheckPayments, setShowAllowECheckPayments] = useState(false);
    const [showAllowCreditCardPayments, setShowAllowCreditCardPayments] = useState(false);
    const [showCVVRequired, setShowCVVRequired] = useState(false);
    const [showAVSRequired, setShowAVSRequired] = useState(false);
    const [showReferenceNumberRequired, setShowReferenceNumberRequired] = useState(false);
    const [showAllowManualEntryPayments, setShowAllowManualEntryPayments] = useState(false);
    const [showAllowTerminalPayments, setShowAllowTerminalPayments] = useState(false);
    const [showAllowPayPalPayments, setShowAllowPayPalPayments] = useState(false);
    const [showCheckForDuplicateTransactions, setShowCheckForDuplicateTransactions] = useState(false);
    const [showEnableAutoReceipts, setShowEnableAutoReceipts] = useState(false);

    const [showAllowPMoFPayments, setShowAllowPMoFPayments] = useState(false);
    const [showAllowCreditCardPMoF, setShowAllowCreditCardPMoF] = useState(false);
    const [showAllowECheckPMoF, setShowAllowECheckPMoF] = useState(false);
    const [showAllowTerminalPMoF, setShowAllowTerminalPMoF] = useState(false);

    const [showEmbeddedCheckoutIsEnabled, setShowEmbeddedCheckoutIsEnabled] = useState(false);
    const [showEmbeddedLinksIsEnabled, setShowEmbeddedLinksIsEnabled] = useState(false);
    const [showTokenizationIsEnabled, setShowTokenizationIsEnabled] = useState(false);

    const [showWebChannelType, setShowWebChannelType] = useState(false);
    const [showIntegrationType, setShowIntegrationType] = useState(false);
    const [showWebAPIKey, setShowWebAPIKey] = useState(false);
    const [showBlockAcceptedCards, setShowBlockAcceptedCards] = useState(false);
    const [showMerchantProcessors, setShowMerchantProcessors] = useState(false);

    const [acceptedCards, setAcceptedCards] = useState<any>([]);
    const [blockAcceptedCards, setBlockAcceptedCards] = useState<Array<AcceptedCardTypes>>([]);

    const [merchantProcessors, setMerchantProcessors] = useState<any>([]);
    const [selectedMerchantProcessors, setSelectedMerchantProcessors] = useState<any>([]);

    const [requiredFormFields, setRequiredFormFields] = useState<any>(
        [{
            "properties": [
                {
                    "propertyName": "emailAddress",
                    "isRequired": false
                },
                {
                    "propertyName": "phoneNumber",
                    "isRequired": false
                }
            ]
        }]
    )

    const [isvProps, setIsvProps] = useState<any>({msbId: '', name: '', percentage: '0', flatFee: '0', minimumAmount: '0', maximumAmount: '2147483647' });
    const [levelThreeProps, setLevelThreeProps] = useState<any>({summaryCommodityCode: '',itemCommodityCode: '' });
    const [invalid, setInvalid] = useState<any>('');
    const [feeRefundRetainment, setFeeRefundRetainment] = useState<any>(false);

    const changeRequiredFieldToggles = (propertyName: any, updatedValue: any) => {
        setRequiredFormFields((prevState: any) => prevState.map((requiredField: any) =>
        ({
            ...requiredField, properties: requiredField.properties.map((prop: any) => prop.propertyName === propertyName ?
                { ...prop, isRequired: updatedValue } : prop)
        })
        ))
    }

    const [signaturePrompt, setSignaturePrompt] = useState<SignaturePromptEnum>(SignaturePromptEnum.Unknown);

    React.useEffect(() => {
        if (!formAddAction) {
            dispatch(getPaymentChannelAction(paymentChannelMsbId!, actionToken));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    React.useEffect(() => {
        if (!formAddAction && paymentChannel?.msbId === paymentChannelMsbId) {
            setIsActive(paymentChannel.isActive);
            setIsISV(paymentChannel?.independentSoftwareVendorConfiguration);
            setLevelThree(paymentChannel?.levelThreeConfiguration);
            setIsAllowECheckPayments(paymentChannel.electronicCheckPaymentsEnabled);
            setIsAllowCreditCardPayments(paymentChannel.creditCardPaymentsEnabled);
            setIsCVVRequired(paymentChannel.requiresCvv);
            setIsAVSRequired(paymentChannel.enableAvsChecks);
            setIsReferenceNumberRequired(paymentChannel.requiresReferenceNumber);
            setIsAllowManualEntryPayments(paymentChannel.manualEntryPaymentsEnabled);
            setIsAllowTerminalPayments(paymentChannel.terminalPaymentsEnabled);
            setIsAllowPayPalPayments(paymentChannel.payPalPaymentsEnabled);
            setIsCheckForDuplicateTransactions(paymentChannel.checkForDuplicateTransactions);
            setEnableAutoReceipts(paymentChannel.enableAutoReceipts);

            setIsAllowPMoFPayments(paymentChannel.paymentMethodOnFileEnabled);
            setIsAllowCreditCardPMoF(paymentChannel.creditCardPMoFEnabled);
            setIsAllowECheckPMoF(paymentChannel.eCheckPMoFEnabled);
            setIsAllowTerminalPMoF(paymentChannel.terminalPMoFEnabled);

            setEmbeddedCheckoutIsEnabled(paymentChannel.embeddedCheckoutIsEnabled)
            setEmbeddedLinksIsEnabled(paymentChannel.embeddedLinksIsEnabled)
            setTokenizationIsEnabled(paymentChannel.tokenizationIsEnabled)

            setPaymentChannelType(PaymentChannelTypeEnum[paymentChannel.paymentChannelType as keyof typeof PaymentChannelTypeEnum]);
            setWebChannelType(WebChannelTypeEnum[paymentChannel.webChannelType as keyof typeof WebChannelTypeEnum]);
            setIntegrationType(IntegrationType[paymentChannel.integrationType as keyof typeof IntegrationType]);
            setCustomerFundingType(CustomerFundingType[paymentChannel.customerFundingType as keyof typeof CustomerFundingType]);
            setWebApiKey(paymentChannel.webApiKey);
            setPaymentChannelName(paymentChannel.name);
            setFeeRefundRetainment(paymentChannel.feesAreNavientRetained)

            setSignaturePrompt(paymentChannel?.promptForSignature);

            const _options = new Array<AcceptedCardTypes>(
                { value: SubMerchantAcceptedCardTypeEnum.Visa, label: "Visa" },
                { value: SubMerchantAcceptedCardTypeEnum.MasterCard, label: "Mastercard" },
                { value: SubMerchantAcceptedCardTypeEnum.AmericanExpress, label: "American Express" },
                { value: SubMerchantAcceptedCardTypeEnum.Discover, label: "Discover" },
            );
            setAcceptedCards(_options);

            if (paymentChannel?.softBlockedAcceptedCards) {
                const _selections = paymentChannel.softBlockedAcceptedCards.reduce((_selections: Array<AcceptedCardTypes>, item) => {
                    if (item) {
                        const _option = _options.find(v => v.value === item);
                        if (_option) _selections.push(_option);
                    }
                    return _selections
                }, []);
                setBlockAcceptedCards(_selections);
            }

            if (client?.merchantProcessors) {
                const _merchantProcessors = client?.merchantProcessors.filter(m => m?.vantivCredential).map(m => { return { value: m.id, type: m.vantivConfiguration.businessType, label: `${m.merchantIdentifier} ${m.vantivConfiguration.businessType.toUpperCase()} ${m.vantivConfiguration.dbaName.toUpperCase()}` } });
                setMerchantProcessors(_merchantProcessors);
                const _selections = paymentChannel?.processors.map(c => { return _merchantProcessors.find(v => v.value === c.merchantProcessorId) });
                setSelectedMerchantProcessors(_selections);
            }

            if (paymentChannel?.requiredFieldDefinitions) {
                const selectedFormFields = _(paymentChannel?.requiredFieldDefinitions)
                    .flatMap('properties')
                    .groupBy('propertyName')
                    .map((props, propertyName) => ({ propertyName, isRequired: props.every(prop => prop.isRequired) }))
                    .value();

                setRequiredFormFields((prevState: any) => {
                    const combinedProperties = _.unionBy(selectedFormFields, prevState[0].properties, 'propertyName');
                    const sortedProperties = _.sortBy(combinedProperties, 'propertyName');
                    return [{ properties: sortedProperties }];
                });
            }

            if (paymentChannel?.independentSoftwareVendorConfiguration) {
                setIsvProps(
                    {
                        msbId: paymentChannel?.independentSoftwareVendorConfiguration?.msbId,
                        name: paymentChannel?.independentSoftwareVendorConfiguration?.name,
                        percentage: paymentChannel?.independentSoftwareVendorConfiguration?.percentage,
                        flatFee: paymentChannel?.independentSoftwareVendorConfiguration?.flatFee,
                        minimumAmount: paymentChannel?.independentSoftwareVendorConfiguration?.minimumAmount,
                        maximumAmount: paymentChannel?.independentSoftwareVendorConfiguration?.maximumAmount,
                    })
            }
            if (paymentChannel?.levelThreeConfiguration) {
                setLevelThreeProps({
                    summaryCommodityCode: paymentChannel?.levelThreeConfiguration?.summaryCommodityCode,
                    itemCommodityCode: paymentChannel?.levelThreeConfiguration?.itemCommodityCode
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentChannel]);

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (!formAddAction && !selectedMerchantProcessors.length) {
            dispatch(sendErrorToastAction(`Must associate a merchant identifier!`));
            return;
        }
        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            let _paymentChannel: PaymentChannel = Object.assign(new PaymentChannel(), formAddAction ? {} : paymentChannel);
            const currentProcessors = _paymentChannel.processors.filter((c: any) => c.merchantProcessor.processorType !== "Vantiv").map(p => { return { merchantProcessorId: p.merchantProcessorId, paymentChannelId: p.paymentChannelId } });
            const selectedProcessors = selectedMerchantProcessors.filter((c: any) => c !== undefined).map((c: any) => { return { paymentChannelId: _paymentChannel.id, merchantProcessorId: c.value } });
            const paymentTypes = [ProcessorTypeEnum.VantivTriPos, ProcessorTypeEnum.VantivExpress, ProcessorTypeEnum.ElectronicCheck]
            const modifiedRequiredFields = [] as any;

            _.forEach([isAllowTerminalPayments, isAllowManualEntryPayments, isAllowECheckPayments,], (selectedPaymentTypes, index) => {
                if (selectedPaymentTypes) {
                    modifiedRequiredFields.push(paymentTypes[index]);
                }
            });

            _paymentChannel.paymentChannelType = PaymentChannelTypeEnum[form.elements.paymentChannelTypeEnum.value] as keyof typeof PaymentChannelTypeEnum;
            _paymentChannel.name = form.elements.name.value;
            _paymentChannel.isActive = isActive;
            _paymentChannel.requiresCvv = isCVVRequired;
            _paymentChannel.enableAvsChecks = isAVSRequired;
            _paymentChannel.creditCardPaymentsEnabled = isAllowCreditCardPayments;
            _paymentChannel.electronicCheckPaymentsEnabled = isAllowECheckPayments;
            _paymentChannel.requiresReferenceNumber = isReferenceNumberRequired;
            _paymentChannel.manualEntryPaymentsEnabled = isAllowManualEntryPayments;
            _paymentChannel.terminalPaymentsEnabled = isAllowTerminalPayments;
            _paymentChannel.paymentMethodOnFileEnabled = isAllowPMoFPayments;
            _paymentChannel.creditCardPMoFEnabled = isAllowCreditCardPMoF;
            _paymentChannel.eCheckPMoFEnabled = isAllowECheckPMoF;
            _paymentChannel.terminalPMoFEnabled = isAllowTerminalPMoF;
            _paymentChannel.payPalPaymentsEnabled = isAllowPayPalPayments;
            _paymentChannel.checkForDuplicateTransactions = isCheckForDuplicateTransactions;
            _paymentChannel.enableAutoReceipts = enableAutoReceipts;
            _paymentChannel.embeddedCheckoutIsEnabled = embeddedCheckoutIsEnabled;
            _paymentChannel.embeddedLinksIsEnabled = embeddedLinksIsEnabled;
            _paymentChannel.tokenizationIsEnabled = tokenizationIsEnabled;
            _paymentChannel.softBlockedAcceptedCards = blockAcceptedCards.map((c: any) => { return c.value });
            _paymentChannel.processors = [...selectedProcessors, ...currentProcessors];
            _paymentChannel.webChannelType = WebChannelTypeEnum[form.elements.webChannelType?.value] as keyof typeof WebChannelTypeEnum;
            _paymentChannel.integrationType = IntegrationType[form.elements.integrationType?.value] as keyof typeof IntegrationType;
            _paymentChannel.customerFundingType = CustomerFundingType[form.elements.customerFundingType?.value] as keyof typeof CustomerFundingType;
            _paymentChannel.authorizationFundsHoldTimeOutDays = form.elements.authorizationFundsHoldTimeOutDays?.value;
            _paymentChannel.promptForSignature = form.elements.signaturePromptEnum?.value as SignaturePromptEnum;
            _paymentChannel.promptForSignatureThresholdAmount = _paymentChannel.promptForSignature * 1 === SignaturePromptEnum.UseThreshold * 1 ? form.elements.signatureThreshold?.value.replace(/\$\s/g, "") : "";
            _paymentChannel.requiredFieldDefinitions = modifiedRequiredFields.map((processorType: any) =>
            ({
                processorType,
                properties: requiredFormFields[0].properties.filter((prop: any) => prop.isRequired)
            })).filter(({ properties }: any) => properties.length)

            const { msbId, ...isvSettings } = isvProps

            const modifiedIsvSettings = Object.fromEntries(
                Object.entries(isvSettings).map(([key, value]: any) => {
                    if (key === 'name') {
                        return [key, value];
                    } else {
                        return [key, parseFloat(value)];
                    }
                })
            )

            isISV ? _paymentChannel.independentSoftwareVendorConfiguration = modifiedIsvSettings : delete _paymentChannel.independentSoftwareVendorConfiguration;
            isLevelThree ? _paymentChannel.levelThreeConfiguration = levelThreeProps : delete _paymentChannel.levelThreeConfiguration;
            _paymentChannel.feesAreNavientRetained = feeRefundRetainment;

            if (formAddAction) {
                dispatch(addPaymentChannelAction(department.msbId!, _paymentChannel, actionToken));
            } else {
                dispatch(savePaymentChannelAction(_paymentChannel, actionToken));
            }
        }
        setValidated(true);
    };

    const selectPaymentChannelType = (pcType: PaymentChannelTypeEnum) => {
        setShowIsActive(false);
        setShowCVVRequired(false);
        setShowAVSRequired(false);
        setShowReferenceNumberRequired(false);
        setShowRequiredFeatures(false);
        setShowAllowECheckPayments(false);
        setShowAllowCreditCardPayments(false);
        setShowAllowManualEntryPayments(false);
        setShowAllowTerminalPayments(false);
        setShowAllowPayPalPayments(false);
        setShowCheckForDuplicateTransactions(false);
        setShowBlockAcceptedCards(false);
        setShowMerchantProcessors(false);
        setShowWebAPIKey(false);
        setShowWebChannelType(false);
        setShowIntegrationType(false);
        setShowEnableAutoReceipts(false);
        setShowAllowPMoFPayments(false);
        setShowAllowCreditCardPMoF(false);
        setShowAllowECheckPMoF(false);
        setShowAllowTerminalPMoF(false);
        setShowEmbeddedCheckoutIsEnabled(false);
        setShowEmbeddedLinksIsEnabled(false);
        setShowTokenizationIsEnabled(false);

        switch (+pcType) {
            case PaymentChannelTypeEnum.IVR:
                setShowIsActive(true);
                setShowRequiredFeatures(true);
                setShowEnableAutoReceipts(true);
                setShowBlockAcceptedCards(!formAddAction);
                setShowMerchantProcessors(!formAddAction);
                break;
            case PaymentChannelTypeEnum.Kiosk:
                setShowIsActive(true);
                setShowRequiredFeatures(true);
                setShowEnableAutoReceipts(true);
                setShowBlockAcceptedCards(!formAddAction);
                setShowMerchantProcessors(!formAddAction);
                break;
            case PaymentChannelTypeEnum.PointOfSale:
                setShowIsActive(true);
                setShowRequiredFeatures(true);
                setShowCVVRequired(true);
                setShowAVSRequired(true);
                setShowReferenceNumberRequired(true);
                setShowAllowECheckPayments(true);
                setShowAllowCreditCardPayments(true);
                setShowAllowManualEntryPayments(true);
                setShowAllowTerminalPayments(true);
                setShowCheckForDuplicateTransactions(true);
                setShowEnableAutoReceipts(true);
                setShowBlockAcceptedCards(!formAddAction);
                setShowMerchantProcessors(!formAddAction);
                setShowIntegrationType(true);
                setShowAllowPMoFPayments(true);
                setShowAllowCreditCardPMoF(true);
                setShowAllowECheckPMoF(true);
                setShowAllowTerminalPMoF(true);
                break;
            case PaymentChannelTypeEnum.QuickPay:
                setShowIsActive(true);
                setShowRequiredFeatures(true);
                setShowCVVRequired(true);
                setShowAVSRequired(true);
                setShowReferenceNumberRequired(true);
                setShowAllowECheckPayments(true);
                setShowAllowCreditCardPayments(true);
                setShowAllowManualEntryPayments(true);
                setShowCheckForDuplicateTransactions(true);
                setShowEnableAutoReceipts(true);
                setShowBlockAcceptedCards(!formAddAction);
                setShowMerchantProcessors(!formAddAction);
                break;
            case PaymentChannelTypeEnum.TerminalPay:
                setShowIsActive(true);
                setShowRequiredFeatures(true);
                setShowReferenceNumberRequired(true);
                setIsAllowTerminalPayments(true);
                setShowEnableAutoReceipts(true);
                setShowCheckForDuplicateTransactions(true);
                setShowBlockAcceptedCards(!formAddAction);
                setShowMerchantProcessors(!formAddAction);
                break;
            case PaymentChannelTypeEnum.Web:
                setShowIsActive(true);
                setShowWebChannelType(true);
                setShowRequiredFeatures(true);
                setShowAllowECheckPayments(true);
                setShowAllowManualEntryPayments(true);
                setShowAllowPayPalPayments(true);
                setShowAllowTerminalPayments(true);
                setShowIntegrationType(true);
                setShowEnableAutoReceipts(true);
                setShowCheckForDuplicateTransactions(true);
                setShowBlockAcceptedCards(!formAddAction);
                setShowMerchantProcessors(!formAddAction);
                break;
            case PaymentChannelTypeEnum.WebAPI:
                setShowIsActive(true);
                setShowWebAPIKey(true);
                setShowRequiredFeatures(true);
                setShowEnableAutoReceipts(true);
                setShowCheckForDuplicateTransactions(true);
                setShowBlockAcceptedCards(!formAddAction);
                setShowMerchantProcessors(!formAddAction);
                setShowEmbeddedCheckoutIsEnabled(true);
                setShowEmbeddedLinksIsEnabled(true);
                setShowTokenizationIsEnabled(true);
                break;
            case PaymentChannelTypeEnum.ThirdParty:
                setShowIsActive(true);
                setShowIntegrationType(true);
                setShowMerchantProcessors(!formAddAction);
                break;
            default:
                break;
        }
    }

    React.useEffect(() => {
        if (paymentChannelType !== PaymentChannelTypeEnum.Select) selectPaymentChannelType(paymentChannelType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentChannelType]);

    const handleSelectedMerchantProcessors = (selection: any) => {
        const counts = Object.values(_.countBy(selection, "type"));
        if ((counts[0] === undefined || counts[0] < 2) && (counts[1] === undefined || counts[1] < 2)) {
            setSelectedMerchantProcessors(selection);
        } else {
            dispatch(sendErrorToastAction(`Duplicate business types are not allowed!`));
        }
    }

    const handlerCopyToClipboard = (evt: any) => {
        navigator.clipboard.writeText(webApiKey);
        dispatch(sendSuccessToastAction(`Copied!`));
    };

    const toggleSettings = (label: string, stateFunction: any, localState: any) => {
        return (
            <div className="toggle-switch">
                <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                    <Switch
                        onChange={e => stateFunction(e)}
                        checked={localState}
                        onColor={'#0057B6'}
                        offColor={'#BEBEBE'}
                        handleDiameter={12}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={16}
                        width={28}
                        disabled={isSaving}
                        activeBoxShadow={'0 0 0 1px #0057B6'}
                    />
                </ReactPlaceholder>
                <span className="toggle-label">{label}</span>
            </div>
        )
    }

    const ISVFormFields = () => {
        return (
            <>
                <Form.Group>
                    <Form.Label>Minimum amount</Form.Label>
                    <CurrencyInput
                        className="form-control"
                        required
                        disabled={true}
                        placeholder="$ 0.00"
                        maxLength={10}
                        decimalsLimit={2}
                        prefix="$ "
                        defaultValue={isvProps.minimumAmount}
                        onValueChange={(value) => handleFieldChange('minimumAmount', value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Maximum amount</Form.Label>
                    <CurrencyInput
                        className={!invalid ? "form-control" : "form-control is-invalid"}
                        required
                        placeholder="$ 0.00"
                        maxLength={10}
                        decimalsLimit={2}
                        prefix="$ "
                        defaultValue={isvProps.maximumAmount}
                        onValueChange={(value) => handleFieldChange('maximumAmount', value)}
                    />
                    <Form.Control.Feedback type="invalid">{invalid}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Percentage</Form.Label>
                    <CurrencyInput
                        className="form-control"
                        required
                        placeholder="% 0.0000"
                        maxLength={7}
                        decimalsLimit={4}
                        prefix="% "
                        defaultValue={isvProps.percentage}
                        onValueChange={(value) => handleFieldChange('percentage', value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Flat Fee</Form.Label>
                    <CurrencyInput
                        className="form-control"
                        required
                        placeholder="$ 0.00"
                        maxLength={10}
                        decimalsLimit={2}
                        prefix="$ "
                        defaultValue={isvProps.flatFee}
                        onValueChange={(value) => handleFieldChange('flatFee', value)}
                    />
                </Form.Group>
            </>
        )
    }

    const handleFieldChange = (fieldName: string, value: any) => {
        setIsvProps((prevState: any) => ({
            ...prevState,
            [fieldName]: value
        }));
        setInvalid('');
        if (fieldName == 'maximumAmount' && value < isvProps?.minimumAmount) {
            return setInvalid("Maximum amount must be greater than the minimum amount.");
        }
        if (fieldName == 'maximumAmount' && value > 2147483647) {
            return setInvalid("Maximum amount cannot be greater than $2,147,483,647.");
        }

    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId="paymentChannelTypeEnum" key={paymentChannelType}>
                            <Form.Label><RequiredIcon />Payment Channel type</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required as="select" onChange={e => setPaymentChannelType(e.target.value as unknown as PaymentChannelTypeEnum)} defaultValue={paymentChannelType} disabled={!formAddAction}>
                                    <option value="">Select</option>
                                    <option value={PaymentChannelTypeEnum.PointOfSale}>Point of Sale</option>
                                    <option value={PaymentChannelTypeEnum.QuickPay}>Quick Pay</option>
                                    <option value={PaymentChannelTypeEnum.TerminalPay}>Terminal Pay</option>
                                    <option value={PaymentChannelTypeEnum.IVR}>Interactive Voice Response</option>
                                    <option value={PaymentChannelTypeEnum.Kiosk}>Kiosk</option>
                                    <option value={PaymentChannelTypeEnum.WebAPI}>Web API</option>
                                    <option value={PaymentChannelTypeEnum.Web}>Web</option>
                                    <option value={PaymentChannelTypeEnum.ThirdParty}>Third Party</option>
                                </Form.Control>
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please select a Payment Channel type.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {showWebChannelType && < Form.Group controlId="webChannelType" key={webChannelType}>
                            <Form.Label><RequiredIcon visible={showWebChannelType} />Web Channel</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required as="select" onChange={e => setShowIntegrationType(WebChannelTypeEnum.WebIntegration == e.target.value as unknown as WebChannelTypeEnum)} defaultValue={webChannelType} disabled={!formAddAction}>
                                    <option value="">Select</option>
                                    <option value={WebChannelTypeEnum.BlindPayment}>Blind Payment</option>
                                    <option value={WebChannelTypeEnum.ShoppingCart}>Shopping Cart</option>
                                    <option value={WebChannelTypeEnum.WebIntegration}>Web Integration</option>
                                </Form.Control>
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please select a Web Channel type.</Form.Control.Feedback>
                        </Form.Group>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {showIntegrationType && <Form.Group controlId="integrationType" key={integrationType}>
                            <Form.Label>Integration Type</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control as="select" defaultValue={integrationType} disabled={!formAddAction}>
                                    <option value={IntegrationType.None}>None</option>
                                    <option value={IntegrationType.TylerEagle}>Tyler Eagle</option>
                                    <option value={IntegrationType.Redirect}>Redirected Payments</option>
                                    <option value={IntegrationType.AuthorizeDotNet}>Authorize.Net</option>
                                </Form.Control>
                            </ReactPlaceholder>
                        </Form.Group>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {showWebAPIKey && <Form.Group controlId="FGwebApiKeyText" key={webApiKey}>
                            <InputGroup>
                                <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                    <Form.Control type="input" maxLength={8000} defaultValue={webApiKey} placeholder="Auto generated after the payment channel addition is saved" readOnly />
                                    <OverlayTrigger placement={'top'} overlay={<Tooltip id={""}> Copy </Tooltip>} >
                                        <Button variant="outline-secondary" id="btnCopyClipboard" onClick={(e) => handlerCopyToClipboard(e)}><FontAwesomeIcon icon={faCopy} /></Button>
                                    </OverlayTrigger>
                                </ReactPlaceholder>
                            </InputGroup>
                        </Form.Group>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="name" key={paymentChannelName}>
                            <Form.Label><RequiredIcon />Payment Channel name</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control required type="input" placeholder="Enter Payment Channel name" maxLength={4000} defaultValue={paymentChannelName} disabled={isSaving} />
                            </ReactPlaceholder>
                            <Form.Control.Feedback type="invalid">Please enter a Payment Channel name.</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="customerFundingType" key={customerFundingType}>
                            <Form.Label><RequiredIcon />Customer Funding By</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <Form.Control as="select" defaultValue={customerFundingType}>
                                    <option value={CustomerFundingType.Department}>Department</option>
                                    <option value={CustomerFundingType.PaymentChannel}>PaymentChannel</option>
                                    <option value={CustomerFundingType.Item}>Item</option>
                                </Form.Control>
                            </ReactPlaceholder>
                        </Form.Group>
                    </Col>
                    {false && <Col>
                        <Form.Group controlId="authorizationFundsHoldTimeOutDays">
                            <Form.Label><RequiredIcon />Hold Card Authorizations For</Form.Label>
                            <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                <InputGroup>
                                    <Form.Control required type="number" min={1} max={30} maxLength={2} disabled={isSaving} />
                                    <InputGroup.Text>Days</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">Enter number of days to hold card authorizations. From 1 to 20 days</Form.Control.Feedback>
                                </InputGroup>
                            </ReactPlaceholder>
                        </Form.Group>
                    </Col>}
                </Row>
                {!formAddAction ?
                    <>
                        <div style={{ display: 'flex', textAlign: 'center' }}>
                            {showIsActive && toggleSettings('Activate Payment Channel', setIsActive, isActive)}

                            {toggleSettings('ISV', setIsISV, isISV)}

                            {toggleSettings('Level 2/3', setLevelThree, isLevelThree)}
                        </div>
                        <Row>
                            <Col>
                                {isISV && <div className="required-header">
                                    <h2>Manage ISV</h2>
                                    <Form.Group controlId="isvName">
                                        <Form.Label><RequiredIcon />Select an ISV</Form.Label>
                                        <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                            <Form.Control required as="select" value={isvProps?.name} onChange={e => setIsvProps({ ...isvProps, name: e.target.value })}>
                                                <option value="">Select</option>
                                                <option value="CIVIC">CIVIC</option>
                                                <option value="DEVNET">DEVNET</option>
                                                <option value="EQUIVANT">EQUIVANT</option>
                                                <option value="GOLDSHIELD">GOLDSHIELD</option>
                                                <option value="GOV EASE">GOV EASE</option>
                                                <option value="GSS">GSS</option>
                                                <option value="MUNIDEX">MUNIDEX</option>
                                                <option value="NCAOC ISV">NCAOC ISV</option>
                                                <option value="NERIC">NERIC</option>
                                                <option value="NJ SURCHARGE">NJ SURCHARGE</option>
                                                <option value="RBA PARTNER">RBA PARTNER</option>
                                                <option value="TTECH">TTECH</option>
                                            </Form.Control>
                                        </ReactPlaceholder>
                                        <Form.Control.Feedback type="invalid">Please select an ISV.</Form.Control.Feedback>
                                    </Form.Group>
                                    {isvProps.name && (
                                        <Card className="cf-card">
                                            <Card.Header>{isvProps.name}
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr) 80px', gridGap: '16px 24px', overflowX: 'scroll' }} >
                                                            {ISVFormFields()}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    )}
                                </div>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {isLevelThree && <div className="required-header">
                                    <Card className="cf-card">
                                        <Card.Header>Manage Level 2/3
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr) 80px', gridGap: '16px 24px', overflowX: 'scroll' }} >
                                                        <Form.Group controlId="summaryCommodityCode">
                                                            <Form.Label><RequiredIcon />Summary Commodity Code</Form.Label>
                                                                <Form.Control required type="input" placeholder="Enter Summary Commodity Code" maxLength={4} value={levelThreeProps.summaryCommodityCode} onChange={e => setLevelThreeProps({ ...levelThreeProps, summaryCommodityCode: e.target.value })} disabled={isSaving} />
                                                            <Form.Control.Feedback type="invalid">Please enter summary commodity code</Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group controlId="itemCommodityCode">
                                                            <Form.Label><RequiredIcon />Item Commodity Code</Form.Label>
                                                                <Form.Control required type="input" placeholder="Enter Item Commodity Code" maxLength={12} value={levelThreeProps.itemCommodityCode} onChange={e => setLevelThreeProps({ ...levelThreeProps, itemCommodityCode: e.target.value })} disabled={isSaving} />
                                                            <Form.Control.Feedback type="invalid">Please enter item commodity code.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </div>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {showBlockAcceptedCards && <div className="required-header">
                                    <h2>Block accepted cards</h2>
                                    <p>Please select which card will be soft blocked.</p>
                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                        <Select
                                            disabled={isSaving}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            value={blockAcceptedCards}
                                            onChange={(value: any) => setBlockAcceptedCards(value)}
                                            options={acceptedCards}
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                        />
                                    </ReactPlaceholder>
                                </div>
                                }
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <h2>Signature Requirement</h2>
                            <p>Please select signature requirement for TriPOS payments.</p>
                            <Col lg={6} sm={12}>
                                <Form.Group controlId="signaturePromptEnum" key={paymentChannel?.name}>
                                    <Form.Label><RequiredIcon />Prompt</Form.Label>
                                    <Form.Control required as="select" onChange={(e: any) => setSignaturePrompt(e.target.value)} defaultValue={paymentChannel?.promptForSignature} disabled={isSaving}>
                                        <option value="">Select</option>
                                        <option value={SignaturePromptEnum.Never}>Never</option>
                                        <option value={SignaturePromptEnum.Always}>Always</option>
                                        <option value={SignaturePromptEnum.UseThreshold}>Use Threshold</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">Signature Prompt Required.</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                {signaturePrompt * 1 === SignaturePromptEnum.UseThreshold * 1 &&
                                    <Form.Group controlId="signatureThreshold">
                                        <Form.Label><RequiredIcon />Threshold</Form.Label>
                                        <CurrencyInput
                                            required={true}
                                            defaultValue={paymentChannel?.promptForSignatureThresholdAmount}
                                            className="form-control"
                                            disabled={isSaving}
                                            id="signatureThreshold"
                                            name="signatureThreshold"
                                            placeholder="$ 0.00"
                                            maxLength={10}
                                            decimalsLimit={2}
                                            prefix="$ "
                                        />
                                        <Form.Control.Feedback type="invalid">Signature threshold required.</Form.Control.Feedback>
                                    </Form.Group>}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {showMerchantProcessors && <div className="required-header">
                                    <h2>Merchant Identifiers</h2>
                                    <p>Please select merchant identifier (MID) to associate.</p>
                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                        <Select
                                            disabled={isSaving}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            value={selectedMerchantProcessors}
                                            onChange={handleSelectedMerchantProcessors}
                                            options={merchantProcessors}
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            required
                                        />
                                    </ReactPlaceholder>
                                </div>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {showRequiredFeatures && <div className="required-header">
                                    <h2>Required features</h2>
                                    <p>Please select which required features you would like to use for this payment channel.</p>
                                </div>
                                }
                                <div className="required-grid">
                                    <div className="required-group">
                                        {toggleSettings('MSB Fee Retained', setFeeRefundRetainment, feeRefundRetainment) }

                                        {showCVVRequired && toggleSettings('Require CVV', setIsCVVRequired, isCVVRequired)}

                                        {showAVSRequired && toggleSettings('Require AVS', setIsAVSRequired, isAVSRequired)}

                                        {showReferenceNumberRequired && toggleSettings
                                            (paymentChannelType === PaymentChannelTypeEnum.PointOfSale && paymentChannel?.integrationType === IntegrationType[IntegrationType.None]
                                                ? 'Require Product Catalog' : 'Require Reference number',
                                                setIsReferenceNumberRequired, isReferenceNumberRequired)
                                        }

                                        {showCheckForDuplicateTransactions && toggleSettings('Check for Duplicate Transactions', setIsCheckForDuplicateTransactions, isCheckForDuplicateTransactions)}
                                    </div>
                                    <div className="required-group">

                                        {showAllowECheckPayments && toggleSettings('Allow eCheck Payments', setIsAllowECheckPayments, isAllowECheckPayments)}

                                        {showAllowManualEntryPayments && toggleSettings('Allow Manual-entry Payments', setIsAllowManualEntryPayments, isAllowManualEntryPayments)}

                                        {showAllowTerminalPayments && toggleSettings('Allow Terminal Payments', setIsAllowTerminalPayments, isAllowTerminalPayments)}
                                    </div>
                                    <div className="required-group">
                                        {showAllowPMoFPayments && toggleSettings('Allow PMoF Payments', setIsAllowPMoFPayments, isAllowPMoFPayments)}

                                        {showAllowCreditCardPMoF && toggleSettings('Allow Credit Card PMoF', setIsAllowCreditCardPMoF, isAllowCreditCardPMoF)}

                                        {showAllowECheckPMoF && toggleSettings('Allow ECheck PMoF', setIsAllowECheckPMoF, isAllowECheckPMoF)}

                                        {showAllowTerminalPMoF && toggleSettings('Allow Terminal PMoF', setIsAllowTerminalPMoF, isAllowTerminalPMoF)}
                                    </div>
                                    <div className="required-group">
                                        {showAllowPayPalPayments && toggleSettings('Allow PayPal Payments', setIsAllowPayPalPayments, isAllowPayPalPayments)}

                                        {showEnableAutoReceipts && toggleSettings('Enable Auto Receipts', setEnableAutoReceipts, enableAutoReceipts)}
                                    </div>
                                    <div className="required-group">
                                        {showEmbeddedCheckoutIsEnabled && toggleSettings('Allow Embedded Checkout', setEmbeddedCheckoutIsEnabled, embeddedCheckoutIsEnabled)}

                                        {showEmbeddedLinksIsEnabled && toggleSettings('Allow Embedded Link', setEmbeddedLinksIsEnabled, embeddedLinksIsEnabled)}

                                        {showTokenizationIsEnabled && toggleSettings('Allow Tokenization', setTokenizationIsEnabled, tokenizationIsEnabled)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {
                                <div className="required-header">
                                    <h2>Required Form Fields</h2>
                                    <p>Please select required form fields</p>
                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200}>
                                        <div style={{ display: 'flex', columnGap: '8px' }}>
                                            <div>
                                                {Object.entries(requiredFormFields[0]?.properties).map(
                                                    ([index, property]: any) => {
                                                        return (
                                                            <>
                                                                <div className="toggle-switch">
                                                                    <ReactPlaceholder type='text' rows={1} ready={!isFetching} delay={200} showLoadingAnimation={true}>
                                                                        <Switch
                                                                            onChange={e => changeRequiredFieldToggles(property.propertyName, e)}
                                                                            checked={property.isRequired}
                                                                            onColor={'#0057B6'}
                                                                            offColor={'#BEBEBE'}
                                                                            handleDiameter={12}
                                                                            uncheckedIcon={false}
                                                                            checkedIcon={false}
                                                                            height={16}
                                                                            width={28}
                                                                            disabled={isSaving}
                                                                            activeBoxShadow={'0 0 0 1px #0057B6'}
                                                                        />
                                                                        <span className="roleType toggle-label">Require {_.startCase(property.propertyName)}</span>
                                                                    </ReactPlaceholder>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </ReactPlaceholder>
                                </div>
                            }
                        </Row>
                    </>
                    :
                    <></>
                }
                <FormActions {...formActionProps} disabled={invalid != '' && isISV}/>
            </Form>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        actionResult: state.clients.actionResult,
        paymentChannel: state.clients.paymentChannel,
    };
};

export default connect(mapStateToProps)(DepartmentPaymentChannelsForm);
