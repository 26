import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Container, Col, Row, Dropdown, Modal, Spinner } from 'react-bootstrap';
import PageHeader from '../../components/layout/PageHeader';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import Table from '../../components/Table';
import { DateFormatter, CurrencyFormatter, ClientNameFormatter, DepartmentNameFormatter, PaymentChannelFormatter } from '../../components/Formatters';
import { IPageableResults } from "../../models/PageableResults";
import { Redirect } from 'react-router-dom';
import { IAppState, IActionResult } from '../../redux/storeTypes';
import Search from './components/Search'
import { Routes } from "../../routes";
import { transactionDetailAction, clearConveninceFee, pdfDownload, csvDownload, resetActionResult } from '../../redux/actions/payments/paymentTransactions';
import { getClientAction, getClientNamesAction } from "../../redux/actions/clients/clients";
import { getDepartmentNamesAction } from "../../redux/actions/clients/departments";
import { PaymentTransactions, TransactionTypeEnum } from '../../models/Payment';
import { ClientName, DepartmentName, PaymentChannelName } from "../../models/Client";
import Summary from './components/Summary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultSorted } from './RefundPayment'
import { faArrowToBottom } from "@fortawesome/pro-regular-svg-icons";
import { Crumb } from "../../models/Crumb";
import moment from "moment";
import { sendErrorToastAction } from "../../redux/actions/toast";
import { centralOffset } from "../../utils/time";
import { getAllPaymentChannelNamesAction } from "../../redux/actions/clients/paymentChannels";
import _ from "lodash";

interface IChargebackReversals {
    currentPage: IPageableResults<PaymentTransactions>,
    clientNames: Array<ClientName>,
    departmentNames: Array<DepartmentName>,
    paymentChannelNames: Array<PaymentChannelName>,
    isFetching: boolean,
    transactionDetail: PaymentTransactions,
    downloadUrl: string,
    actionResult?: IActionResult,
}

export const chargebackReversalRules = (transaction: any, transactionType: string) => {
    if (transactionType === 'Chargeback Reversal') {
        let sortedArray = _.orderBy(transaction?.orderSummary?.orderTransactions, transactions => new Date(transactions.createdAt), 'desc');
        const isChargebackReversal = sortedArray[0].transactionType === "ChargebackReversal";
        return !isChargebackReversal
    }
}

const ChargebackReversals = ({ transactionDetail, currentPage, downloadUrl, isFetching, clientNames, departmentNames, paymentChannelNames }: IChargebackReversals) => {
    const dispatch = useDispatch();
    const actionToken = "Chargeback Reversal";
    const clientReceipt = "ChargebackReversalClientReceipt"
    const [redirect, setRedirect] = useState<string>("");
    const [showModal, setModal] = useState(false);
    const [data, setData] = useState<Array<any>>(Array<any>());
    const [searchReady, setSearchReady] = useState<boolean>(false);
    const [tableReady, setTableReady] = useState<boolean>(false);

    const [startDate, setStartDate] = useState<string>(moment().startOf('day').subtract(7, "day").format('YYYY-MM-DD HH:mm:ss'));
    const [endDate, setEndDate] = useState<string>(moment().endOf('day').subtract(1, "day").format('YYYY-MM-DD HH:mm:ss'));

    const validateStartDate = (startDateString: string) => {
        const allowedDate = moment().startOf('day').subtract(180, "days").format('YYYY-MM-DD');
        if (Date.parse(startDateString) < Date.parse(allowedDate)) {
            dispatch(sendErrorToastAction(`Start date must be on or after ${moment(allowedDate).format('MM/DD/YYYY')}`));
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (!clientNames) {
            dispatch(getClientNamesAction(actionToken));
        }
    }, [clientNames]);

    useEffect(() => {
        if (!departmentNames) {
            dispatch(getDepartmentNamesAction(actionToken));
        }
    }, [departmentNames]);

    useEffect(() => {
        if (!paymentChannelNames) {
            dispatch(getAllPaymentChannelNamesAction(actionToken));
        }
    }, [paymentChannelNames]);

    useEffect(() => {
        if (clientNames && departmentNames && paymentChannelNames) {
            setSearchReady(true);
        }
    }, [clientNames, departmentNames, paymentChannelNames]);

    useEffect(() => {
        setTableReady(false);
        if (currentPage) {
            setData(currentPage.data.filter((t: any) => t.transactionType !== "Authorization"));
            setTableReady(true)
        }
    }, [currentPage]);

    const dropDownOptions = (cell: any, row: any) => {

        return (
            <Dropdown className="req-action-dropdown">
                <Dropdown.Toggle className="approvalAction" variant="link">
                    Manage
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {
                        dispatch(resetActionResult());
                        dispatch(transactionDetailAction(row.msbId, actionToken));
                        dispatch(getClientAction(row.clientId, clientReceipt));
                        setRedirect(Routes.ChargebackReversalDetails.path)
                    }}>View Details
                    </Dropdown.Item>
                    {row?.orderPayment?.processorType !== 'PayPal' && chargebackReversalRules(row, actionToken) &&
                        <Dropdown.Item onClick={() => {
                            dispatch(resetActionResult());
                            dispatch(getClientAction(row.clientId, clientReceipt));
                            dispatch(transactionDetailAction(row.msbId, actionToken));
                            setModal(true)
                        }}>Chargeback Reversal</Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>

        )
    }

    const columns = [
        {
            dataField: 'clientId',
            text: 'Client',
            editable: false,
            formatter: (cell: string) => {
                return ClientNameFormatter(cell, clientNames)
            },
            sort: true
        }, {
            dataField: 'departmentId',
            text: 'Department',
            formatter: (cell: string) => {
                return DepartmentNameFormatter(cell, departmentNames)
            },
            editable: false,
            sort: true
        }, {
            dataField: 'paymentChannelId',
            text: 'Payment Channel',
            formatter: (cell: string) => {
                return PaymentChannelFormatter(cell, paymentChannelNames)
            },
            editable: false,
            sort: true,
        }, {
            dataField: 'msbId',
            text: 'Id',
            sort: false,
            hidden: true,
            editable: false
        }, {
            dataField: 'orderIdentifier',
            text: 'Transaction ID',
            editable: false,
            sort: true
        }, {
            dataField: 'createdAt',
            text: 'Transaction date',
            formatter: DateFormatter,
            sort: true,
            editable: false
        }, {
            dataField: 'orderLines[0].itemReferenceNumber',
            text: 'Item reference',
            editable: false,
            sort: true
        }, {
            dataField: 'orderLines[0].itemName',
            text: 'Item name',
            editable: false,
            sort: true
        }, {
            dataField: 'nameOnCard',
            text: 'Customer name',
            editable: false,
            sort: true
        }, {
            dataField: 'originalAmount',
            text: 'Original amount',
            sort: true,
            formatter: CurrencyFormatter,
            editable: false
        }, {
            dataField: 'remainingBalance',
            text: 'Remaining balance',
            sort: true,
            formatter: CurrencyFormatter,
            editable: false
        }, {
            dataField: '',
            text: 'Actions',
            editable: false,
            formatter: dropDownOptions,
        }
    ];

    const doToolbar = (data: any) => {
        return (
            <Dropdown>
                <Dropdown.Toggle className="approvalAction" variant="outline-secondary">
                    <FontAwesomeIcon icon={faArrowToBottom} size="sm" />
                    Download Summary
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => dispatch(pdfDownload(downloadUrl))}>PDF File</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(csvDownload(downloadUrl))}>CSV File</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const renderTable = () => {
        if (searchReady) {
            if (!data || isFetching || !searchReady) {
                return (<Spinner animation="border" />);
            } else if (!isFetching && data && data.length === 0){
                return ( 
                    <>
                    <h2 className="fw-bold">No records were found</h2>
                    <span>Please do another search to find the record you are looking for.</span>
                    </>
                  );
            } else if(tableReady) {
                return (
                    <Table
                        keyField="msbId"
                        data={data}
                        columns={columns}
                        defaultSorted={defaultSorted}
                />);
            }
        }
        return(<></>);
    };

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Chargeback Reversals", Routes.ChargebackReversals.path));

        return (
            <>
                {transactionDetail &&
                    <Modal show={showModal} onHide={() => setModal(false)} backdrop="static">
                        <Modal.Header closeButton onHide={() => dispatch(clearConveninceFee())} />
                        <Modal.Body>
                            <Summary details={transactionDetail} closeModal={() => setModal(false)} action={actionToken} />
                        </Modal.Body>
                    </Modal>
                }
                <PageHeader title="Chargeback Reversals" crumbs={crumbs} />

                <Container fluid className="container-table-search">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <Search
                                    defaultStartDate={startDate}
                                    defaultEndDate={endDate}
                                    searchReady={searchReady}
                                    validateStartDate={validateStartDate}
                                    transactionType={TransactionTypeEnum.ChargebackReversal}
                                    />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>

                <Container fluid>
                    <Row>
                        <Col>
                            <PageSectionContainer title="Manage Chargeback Reversals" toolbar={doToolbar({ data })}>
                               {renderTable()}
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        transactionDetail: state.paymentTransactions.transactionDetail,
        downloadUrl: state.paymentTransactions.downloadUrl,
        currentPage: state.paymentTransactions.currentPage,
        isFetching: state.paymentTransactions.isFetching,
        clientNames: state.clients.clientNames,
        departmentNames: state.clients.departmentNames,
        paymentChannelNames: state.clients.paymentChannelNames
    };
};

export default connect(mapStateToProps)(ChargebackReversals);